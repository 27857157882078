import React, { useEffect, useState, useMemo, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { Card, Container, Row, Col, Alert, Spinner, Modal, Button, Form, ProgressBar } from 'react-bootstrap';
import { FaCheckCircle, FaExclamationCircle, FaSearch, FaChevronDown, FaChevronUp, FaTimes, FaTable, FaShieldAlt, FaSpinner, FaPlayCircle, FaExclamationTriangle, FaChevronRight, FaExpandAlt, FaCompressAlt, FaClock, FaList,FaCircle } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import './AttackScenarios.scss';
import attackMatrix from '../data/attackMatrix';

const LazyChart = lazy(() => import('./ChartComponent'));

const extractTablesFromQuery = (query, allTables) => {
    const tables = new Set();
    const queryLower = query.toLowerCase();
    
    const keywords = ['from', 'join', 'union', 'datatable', 'let'];
    const tableRegex = new RegExp(`\\b(${keywords.join('|')})\\s+(\\w+)`, 'gi');
    
    let match;
    while ((match = tableRegex.exec(query)) !== null) {
        const potentialTable = match[2];
        if (allTables.some(table => table.toLowerCase() === potentialTable.toLowerCase())) {
            tables.add(potentialTable);
        }
    }

    allTables.forEach(table => {
        const tableRegex = new RegExp(`\\b${table}\\b`, 'i');
        if (tableRegex.test(queryLower)) {
            tables.add(table);
        }
    });

    return Array.from(tables);
};

const fetchTableSchema = async (tableName, integrationSettings) => {
    try {
        const token = localStorage.getItem('token');

        if (!integrationSettings) {
            throw new Error('Integration settings not found.');
        }

        const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

        const response = await axios.post('/api/table_schema', {
            tenantId,
            clientId,
            clientSecret,
            workspaceId,
            tableName
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            timeout: 300000 // 5 minutes timeout
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Failed to fetch table schema');
            return null;
        }
    } catch (error) {
        console.error('Error fetching table schema:', error);
        return null;
    }
};

const TableSchemaDropdown = ({ table, schema }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="table-schema-dropdown">
            <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                <FaTable /> {table}
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {isOpen && schema && (
                <div className="dropdown-content">
                    <h4>Schema for {table}</h4>
                    <ul>
                        {schema.columns.map((column, idx) => (
                            <li key={idx}>{column.name} ({column.type})</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

const FullScreenSimulation = ({ rule, aiAnalysisResults, onClose, integrationSettings }) => {
    const [selectedStep, setSelectedStep] = useState(null);
    const [ingestionMethod, setIngestionMethod] = useState('agent');
    const [dceUri, setDceUri] = useState('');
    const [dcrId, setDcrId] = useState('');
    const [dcrImmutableId, setDcrImmutableId] = useState('');
    const [streams, setStreams] = useState([]);
    const [selectedStream, setSelectedStream] = useState('');
    const [dcrs, setDcrs] = useState([]);
    const [sharedKey, setSharedKey] = useState('');
    const [pushingLogs, setPushingLogs] = useState(false);
    const [pushProgress, setPushProgress] = useState(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    useEffect(() => {
        fetchDataCollectionRules();
    }, []);

    useEffect(() => {
        if (dcrId) {
            const selectedDcr = dcrs.find(dcr => dcr.name === dcrId);
            if (selectedDcr && selectedDcr.properties && selectedDcr.properties.immutableId) {
                setDcrImmutableId(selectedDcr.properties.immutableId);
                fetchStreams();
            }
        }
    }, [dcrId]);

    useEffect(() => {
        console.log('DCE URI updated:', dceUri);
    }, [dceUri]);

    const fetchDataCollectionRules = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/get_dcrs', {
                ...integrationSettings
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDcrs(response.data.dcrs);
        } catch (error) {
            console.error('Error fetching DCRs:', error);
            setFeedbackMessage('Failed to fetch DCRs. Please try again.');
        }
    };

    const fetchStreams = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/get_streams', { dcrName: dcrId }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Streams response:', response.data);
            setStreams(response.data.streams);
        } catch (error) {
            console.error('Error fetching streams:', error);
            setFeedbackMessage(`Failed to fetch streams: ${error.message}`);
        }
    };

    const handlePushLogs = async () => {
        setPushingLogs(true);
        setFeedbackMessage('');
        setPushProgress(0);

        console.log('Selected Stream:', selectedStream);
        console.log('DCE URI:', dceUri);
        console.log('DCR Immutable ID:', dcrImmutableId);

        if (!integrationSettings) {
            setFeedbackMessage('Integration settings not found. Please configure your integration in the Integrations page.');
            setPushingLogs(false);
            return;
        }

        const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

        if (!tenantId || !clientId || !clientSecret || !workspaceId) {
            setFeedbackMessage('Incomplete integration settings. Please reconfigure your integration.');
            setPushingLogs(false);
            return;
        }

        if (ingestionMethod === 'agent' && (!selectedStream || !dceUri || !dcrImmutableId)) {
            setFeedbackMessage('Please fill in all required fields for Agent Log ingestion.');
            setPushingLogs(false);
            return;
        }

        if (ingestionMethod === 'http' && !sharedKey) {
            setFeedbackMessage('Please enter the shared key for HTTP Data Collector API.');
            setPushingLogs(false);
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const totalTables = Object.keys(aiAnalysisResults).length;
            let processedTables = 0;
            let totalSuccessfulLogs = 0;
            let totalFailedLogs = 0;

            if (ingestionMethod === 'agent') {
                for (const [tableName, logs] of Object.entries(aiAnalysisResults)) {
                    try {
                        const response = await axios.post('/api/push_log', {
                            logs,
                            tableName,
                            dceUri,
                            dcrImmutableId,
                            tenantId,
                            clientId,
                            clientSecret,
                            selectedStream
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        });

                        if (response.status === 200) {
                            processedTables++;
                            totalSuccessfulLogs += response.data.successfulLogs || 0;
                            totalFailedLogs += response.data.failedLogsCount || 0;
                            setPushProgress((processedTables / totalTables) * 100);
                            setFeedbackMessage(prevMessage => 
                                `${prevMessage}\nIngested ${response.data.successfulLogs} logs for table ${tableName} (${response.data.failedLogsCount} failed) in ${response.data.durationSeconds.toFixed(2)} seconds.`
                            );
                        }
                    } catch (error) {
                        console.error(`Error ingesting logs for table ${tableName}:`, error);
                        setFeedbackMessage(prevMessage => 
                            `${prevMessage}\nError ingesting logs for table ${tableName}: ${error.response?.data?.message || error.message}`
                        );
                        totalFailedLogs += logs.length;
                    }
                }
            } else {
                // HTTP Data Collector API ingestion
                try {
                    const response = await axios.post('/api/push_log_http', {
                        logs: aiAnalysisResults,
                        customerId: workspaceId,
                        sharedKey
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    if (response.status === 200) {
                        totalSuccessfulLogs = response.data.successfulLogs;
                        totalFailedLogs = response.data.failedLogs;
                        setPushProgress(100);
                        setFeedbackMessage(
                            `Successfully ingested logs for all tables.\n` +
                            `Total successful logs: ${totalSuccessfulLogs}\n` +
                            `Total failed logs: ${totalFailedLogs}\n` +
                            `Duration: ${response.data.durationSeconds.toFixed(2)} seconds\n` +
                            `Rate: ${response.data.logsPerSecond} logs/sec`
                        );
                    }
                } catch (error) {
                    console.error('Error in HTTP Data Collector API ingestion:', error);
                    setFeedbackMessage(`Error in HTTP Data Collector API ingestion: ${error.response?.data?.message || error.message}`);
                    totalFailedLogs = Object.values(aiAnalysisResults).reduce((sum, logs) => sum + logs.length, 0);
                }
            }

            const totalLogs = totalSuccessfulLogs + totalFailedLogs;
            const successRate = totalLogs > 0 ? (totalSuccessfulLogs / totalLogs * 100).toFixed(2) : 0;

            setFeedbackMessage(prevMessage => 
                `${prevMessage}\n\nIngestion Summary:\n` +
                `Total logs processed: ${totalLogs}\n` +
                `Successful logs: ${totalSuccessfulLogs}\n` +
                `Failed logs: ${totalFailedLogs}\n` +
                `Success rate: ${successRate}%`
            );

        } catch (error) {
            console.error('Error in log ingestion process:', error);
            setFeedbackMessage(`Error in log ingestion process: ${error.message}`);
        } finally {
            setPushingLogs(false);
        }
    };

    const renderStepByStepAttack = () => {
        const allLogs = Object.values(aiAnalysisResults).flat();
        const sortedLogs = allLogs.sort((a, b) => new Date(a.TimeGenerated) - new Date(b.TimeGenerated));
    
        return (
            <div className="step-by-step-attack">
                <h3>Attack Flow</h3>
                <div className="timeline">
                    {sortedLogs.map((log, index) => (
                        <div key={index} className={`attack-step ${selectedStep === index ? 'selected' : ''}`}>
                            <div className="step-content">
                                <div className="step-icon">
                                    <FaCircle />
                                    <span className="step-number">{index + 1}</span>
                                </div>
                                <div className="step-details">
                                    <div className="step-summary">
                                        {log.OperationName || 'Unknown Operation'}
                                    </div>
                                    <button 
                                        className="details-button"
                                        onClick={() => setSelectedStep(selectedStep === index ? null : index)}
                                    >
                                        {selectedStep === index ? 'Hide Details' : 'View Details'}
                                    </button>
                                </div>
                            </div>
                            {index < sortedLogs.length - 1 && <div className="step-connector" />}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="full-screen-simulation">
            <button className="close-button" onClick={onClose}>
                <FaTimes />
            </button>
            <div className="simulation-content">
                <h2>Simulation Details</h2>
                <p><strong>Technique:</strong> {rule.techniqueName} ({rule.techniqueId})</p>
                <p><strong>Rule:</strong> {rule.ruleName}</p>
                <p><strong>Description:</strong> {rule.ruleDescription}</p>
                
                <h3>Generated Logs</h3>
                <pre className="generated-logs">{JSON.stringify(aiAnalysisResults, null, 2)}</pre>
                
                {renderStepByStepAttack()}

                <h3>Log Ingestion</h3>
                <Form>
                    <Form.Group>
                        <Form.Label>Ingestion Method</Form.Label>
                        <Form.Control
                            as="select"
                            value={ingestionMethod}
                            onChange={(e) => setIngestionMethod(e.target.value)}
                        >
                            <option value="agent">Agent Logs</option>
                            <option value="http">HTTP Data Collector API</option>
                        </Form.Control>
                    </Form.Group>

                    {ingestionMethod === 'agent' ? (
                        <>
                            <Form.Group>
                                <Form.Label>Data Collection Endpoint URI</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={dceUri}
                                    onChange={(e) => setDceUri(e.target.value)}
                                    placeholder="https://example.ingest.monitor.azure.com"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Select Data Collection Rule</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={dcrId}
                                    onChange={(e) => setDcrId(e.target.value)}
                                >
                                    <option value="">Select a DCR</option>
                                    {dcrs.map((dcr, index) => (
                                        <option key={index} value={dcr.name}>{dcr.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Select Stream</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedStream}
                                    onChange={(e) => setSelectedStream(e.target.value)}
                                >
                                    <option value="">Select a stream</option>
                                    {streams.map((stream, index) => (
                                        <option key={index} value={stream}>{stream}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group>
                            <Form.Label>Shared Key</Form.Label>
                            <Form.Control
                                type="password"
                                value={sharedKey}
                                onChange={(e) => setSharedKey(e.target.value)}
                                placeholder="Enter shared key"
                            />
                        </Form.Group>
                    )}

                    <Button
                        className="push-logs-button"
                        onClick={handlePushLogs}
                        disabled={pushingLogs}
                    >
                        {pushingLogs ? <FaSpinner className="fa-spin" /> : <FaPlayCircle />}
                        {pushingLogs ? 'Pushing Logs...' : 'Push Logs'}
                    </Button>
                </Form>

                {pushingLogs && (
                    <ProgressBar now={pushProgress} label={`${Math.round(pushProgress)}%`} className="mt-3" />
                )}

                {feedbackMessage && (
                    <div className="feedback-message mt-3">
                        <pre>{feedbackMessage}</pre>
                    </div>
                )}
            </div>

            <Modal show={selectedStep !== null} onHide={() => setSelectedStep(null)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Step {selectedStep !== null ? selectedStep + 1 : ''} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedStep !== null && (
                        <pre>{JSON.stringify(Object.values(aiAnalysisResults).flat()[selectedStep], null, 2)}</pre>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

const SIEMRule = ({ rule, allTables, isExpanded, integrationSettings, showDetails, setShowDetails }) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const [tableSchemas, setTableSchemas] = useState({});
    const [aiAnalysisStatus, setAIAnalysisStatus] = useState(null);
    const [aiAnalysisResults, setAIAnalysisResults] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isAIAnalysisExpanded, setIsAIAnalysisExpanded] = useState(false);
    const [inputType, setInputType] = useState('structured');
    const [scenarioDescription, setScenarioDescription] = useState('');
    const [kqlQuery, setKqlQuery] = useState('');
    const [showFullScreenSimulation, setShowFullScreenSimulation] = useState(false);

    const detectedTables = useMemo(() => {
        return extractTablesFromQuery(rule.kqlQuery, allTables);
    }, [rule.kqlQuery, allTables]);

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (expanded && detectedTables.length > 0) {
            detectedTables.forEach(async (table) => {
                if (!tableSchemas[table]) {
                    const schema = await fetchTableSchema(table, integrationSettings);
                    if (schema) {
                        setTableSchemas(prev => ({ ...prev, [table]: schema }));
                    }
                }
            });
        }
    }, [expanded, detectedTables, tableSchemas, integrationSettings]);

    const handleGenerateAIAnalysis = async () => {
        setAIAnalysisStatus('generating');
        setAIAnalysisResults(null);
        setErrorMessage(null);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found. Please log in again.');
            }

            if (detectedTables.length === 0) {
                throw new Error('No tables detected for this rule');
            }

            const tables = detectedTables.map(tableName => ({
                name: tableName,
                schema: tableSchemas[tableName]
            }));

            const response = await axios.post('/api/generate_ai_analysis', {
                inputType,
                ruleName: rule.ruleName,
                ruleDescription: rule.ruleDescription,
                tables: tables,
                technique: {
                    name: rule.techniqueName,
                    id: rule.techniqueId,
                    description: rule.techniqueDescription
                },
                scenarioDescription,
                kqlQuery: inputType === 'kql' ? kqlQuery : rule.kqlQuery
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                timeout: 300000 // 5 minutes timeout
            });

            setAIAnalysisResults(response.data.aiResponse);
            setAIAnalysisStatus('complete');
            setShowFullScreenSimulation(true);
        } catch (error) {
            console.error('AI analysis generation failed:', error);
            setAIAnalysisStatus('failed');
            if (error.code === 'ECONNABORTED') {
                setErrorMessage('The request timed out. Please try again or contact support if the issue persists.');
            } else {
                setErrorMessage(error.response?.data?.error || error.message || 'An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="siem-rule">
            <div className="rule-header" onClick={() => setExpanded(!expanded)}>
                <div className="rule-header-left">
                    <FaShieldAlt className="rule-icon" />
                    <span className="rule-name">{rule.ruleName}</span>
                    <span className={`severity ${rule.severity.toLowerCase()}`}>{rule.severity}</span>
                </div>
                <div className="rule-header-right">
                    {expanded ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>
            {expanded && (
                <div className="rule-details">
                    <p><strong>Description:</strong> {rule.ruleDescription}</p>
                    <div className="detected-tables">
                        <strong>Detected Tables:</strong>
                        {detectedTables.length > 0 ? (
                            <div className="table-schemas">
                                {detectedTables.map((table, index) => (
                                    <TableSchemaDropdown
                                        key={index}
                                        table={table}
                                        schema={tableSchemas[table]}
                                    />
                                ))}
                            </div>
                        ) : (
                            <p>No tables detected</p>
                        )}
                    </div>
                    <div className="query-container">
                        <strong>Query:</strong>
                        <pre>{rule.kqlQuery}</pre>
                    </div>
                    <div className="ai-analysis-section">
                        <Form.Group className="mb-3">
                            <Form.Label>Input Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={inputType}
                                onChange={(e) => setInputType(e.target.value)}
                            >
                                <option value="structured">Structured</option>
                                <option value="natural">Natural Language</option>
                                <option value="kql">KQL Query</option>
                            </Form.Control>
                        </Form.Group>
                        {inputType === 'natural' && (
                            <Form.Group className="mb-3">
                                <Form.Label>Scenario Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={scenarioDescription}
                                    onChange={(e) => setScenarioDescription(e.target.value)}
                                    placeholder="Describe the attack scenario..."
                                />
                            </Form.Group>
                        )}
                        {inputType === 'kql' && (
                            <Form.Group className="mb-3">
                                <Form.Label>KQL Query</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={kqlQuery}
                                    onChange={(e) => setKqlQuery(e.target.value)}
                                    placeholder="Enter KQL query..."
                                />
                            </Form.Group>
                        )}
                        <button
                            onClick={handleGenerateAIAnalysis}
                            className={`generate-ai-button ${aiAnalysisStatus === 'generating' ? 'generating' : ''}`}
                            disabled={aiAnalysisStatus === 'generating'}
                        >
                            {aiAnalysisStatus === 'generating' ? <FaSpinner className="fa-spin" /> : <FaPlayCircle />}
                            {aiAnalysisStatus === 'generating' ? 'Generating AI Simulation...' : 'Generate AI Simulation'}
                        </button>
                        {errorMessage && (
                            <div className="ai-analysis-error">
                                <FaExclamationTriangle />
                                <p>{errorMessage}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {showFullScreenSimulation && (
                <FullScreenSimulation
                    rule={rule}
                    aiAnalysisResults={aiAnalysisResults}
                    onClose={() => setShowFullScreenSimulation(false)}
                    integrationSettings={integrationSettings}
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                />
            )}
        </div>
    );
};

const TechniqueDetailsCard = ({ technique, matchingRules, onClose, allTables, expandedRule, expandedTechnique, integrationSettings, showDetails, setShowDetails }) => {
    return (
        <motion.div
            className="technique-details-card full-screen"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <button className="close-button" onClick={onClose}>
                <FaTimes />
            </button>
            <div className="card-content">
                <div className="technique-header">
                    <h2>{technique.name}</h2>
                    <span className="technique-id">{technique.id}</span>
                </div>
                <div className="technique-description">
                    <p>{technique.description || "No description available."}</p>
                </div>
                {technique.subTechniques && technique.subTechniques.length > 0 && (
                    <div className="sub-techniques">
                        <h3>Sub-techniques</h3>
                        <ul>
                            {technique.subTechniques.map((subTech, index) => (
                                <li key={index}>{subTech}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="siem-rules">
                    <h3>Matching SIEM Rules</h3>
                    {matchingRules.length > 0 ? (
                        matchingRules.map((rule, index) => (
                            <SIEMRule
                                key={index}
                                rule={rule}
                                allTables={allTables}
                                isExpanded={rule.ruleName === expandedRule && technique.id === expandedTechnique}
                                integrationSettings={integrationSettings}
                                showDetails={showDetails} // Pass state
                                setShowDetails={setShowDetails} // Pass setter
                            />
                        ))
                    ) : (
                        <div className="no-rules">
                            <FaExclamationTriangle />
                            <p>No matching SIEM rules found.</p>
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

const AttackScenarios = () => {
    const [detectionRules, setDetectionRules] = useState([]);
    const [coverage, setCoverage] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTechnique, setSelectedTechnique] = useState(null);
    const [allTables, setAllTables] = useState([]);
    const [expandedTechnique, setExpandedTechnique] = useState(null);
    const [expandedRule, setExpandedRule] = useState(null);
    const [integrationSettings, setIntegrationSettings] = useState(null);
    const [showDetails, setShowDetails] = useState(null);  // Add this state
    const location = useLocation();

    useEffect(() => {
        const savedSettings = localStorage.getItem('integrationSettings');
        if (savedSettings) {
            setIntegrationSettings(JSON.parse(savedSettings));
        }
    }, []);

    const fetchDetectionRules = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, subscriptionId, resourceGroup, workspaceName } = integrationSettings;

            const response = await axios.post('/api/detection_rules', {
                tenantId,
                clientId,
                clientSecret,
                subscriptionId,
                resourceGroup,
                workspaceName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                timeout: 300000 // 5 minutes timeout
            });

            setDetectionRules(response.data);
            calculateCoverage(response.data);
        } catch (error) {
            console.error('Error fetching detection rules:', error);
        }
    }, [integrationSettings]);

    const fetchAllTables = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

            const response = await axios.post('/api/client_tables', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                timeout: 300000 // 5 minutes timeout
            });

            if (response.status === 200) {
                setAllTables(response.data.map(table => table[0]));
            } else {
                console.error('Failed to fetch client tables');
            }
        } catch (error) {
            console.error('Error fetching client tables:', error);
        }
    }, [integrationSettings]);

    useEffect(() => {
        if (integrationSettings) {
            fetchDetectionRules();
            fetchAllTables();
        }
    }, [fetchDetectionRules, fetchAllTables, integrationSettings]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const technique = queryParams.get('technique');
        const rule = queryParams.get('rule');

        if (technique) {
            const techObject = attackMatrix.flatMap(category => category.techniques).find(tech => tech.id === technique);
            if (techObject) {
                setSelectedTechnique(techObject);
                setExpandedTechnique(technique);
            }
        }

        if (rule) {
            setExpandedRule(rule);
        }
    }, [location]);

    const calculateCoverage = useCallback((rules) => {
        const coverageMap = rules.reduce((acc, rule) => {
            rule.techniques.forEach(technique => {
                acc[technique] = true;
            });
            return acc;
        }, {});
        setCoverage(coverageMap);
    }, []);

    const handleTechniqueClick = useCallback((technique) => {
        setSelectedTechnique(technique);
        setExpandedTechnique(technique.id);
        setExpandedRule(null);
    }, []);

    const handleSearchChange = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, []);

    const closeTechniqueDetails = useCallback(() => {
        setSelectedTechnique(null);
        setExpandedTechnique(null);
        setExpandedRule(null);
    }, []);

    const filteredMatrix = useMemo(() => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        return attackMatrix.map(category => ({
            ...category,
            techniques: category.techniques.filter(technique =>
                technique.name.toLowerCase().includes(lowerSearchTerm) ||
                (technique.subTechniques && technique.subTechniques.some(subTech => subTech.toLowerCase().includes(lowerSearchTerm)))
            ),
        }));
    }, [searchTerm]);

    const matchingRules = useMemo(() => {
        if (!selectedTechnique) return [];
        return detectionRules.filter(rule =>
            rule.techniques.includes(selectedTechnique.id) ||
            (selectedTechnique.subTechniques && selectedTechnique.subTechniques.some(subTech => rule.techniques.includes(`${selectedTechnique.id}.${subTech}`)))
        );
    }, [selectedTechnique, detectionRules]);

    return (
        <div className="attack-scenarios">
            <header className="inventory-header">
                <div className="header-content">
                    <h1>Attack Scenarios Coverage</h1>
                    <p>View and manage your detection rules</p>
                    <div className="search-bar">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search Techniques"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </header>
            <div className="matrix">
                {filteredMatrix.map((category, categoryIndex) => (
                    <div key={categoryIndex} className="tactic">
                        <h2>{category.category}</h2>
                        <div className="techniques">
                            {category.techniques.map((technique, techniqueIndex) => (
                                <div
                                    key={techniqueIndex}
                                    className={`technique ${coverage[technique.id] ? 'covered' : 'not-covered'}`}
                                    onClick={() => handleTechniqueClick(technique)}
                                >
                                    <div className="technique-header">
                                        <span>{technique.name}</span>
                                        {technique.subTechniques && technique.subTechniques.length > 0 && (
                                            <span className="chevron-icon">
                                                <FaChevronRight />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="legend">
                <div className="legend-item">
                    <div className="color-box covered-box"></div>
                    <span>Covered</span>
                </div>
                <div className="legend-item">
                    <div className="color-box not-covered-box"></div>
                    <span>Not Covered</span>
                </div>
            </div>
            <AnimatePresence>
                {selectedTechnique && (
                    <motion.div
                        className="technique-details-overlay"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <TechniqueDetailsCard
                            technique={selectedTechnique}
                            matchingRules={matchingRules}
                            onClose={closeTechniqueDetails}
                            allTables={allTables}
                            expandedRule={expandedRule}
                            expandedTechnique={expandedTechnique}
                            integrationSettings={integrationSettings}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AttackScenarios;
