import axios from 'axios';

const BASE_URL = '/api';

const getAuthToken = () => localStorage.getItem('token');

export const createSimulation = async (sigmaRuleId, timeWindow) => {
    const token = getAuthToken();
    const response = await axios.post(`${BASE_URL}/simulations`, 
        { sigmaRuleId, timeWindow },
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
};

export const getSimulationStatus = async (simulationId) => {
    const token = getAuthToken();
    const response = await axios.get(`${BASE_URL}/simulations/${simulationId}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
};

export const getSimulationsForRule = async (ruleId) => {
    const token = getAuthToken();
    if (!token) {
        throw new Error('No authentication token found');
    }
    try {
        const response = await axios.get(`${BASE_URL}/simulations`, {
            params: { ruleId },
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Silently ignore 401 errors
            console.log('Authentication error occurred, but continuing operation.');
            return []; // Return an empty array or whatever default value makes sense
        }
        throw error; // Rethrow other errors
    }
};

export const checkForTriggeredRules = async (simulationId) => {
    const token = getAuthToken();
    const response = await axios.get(`${BASE_URL}/simulations/${simulationId}/triggered-rules`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
};

export const cancelSimulation = async (simulationId) => {
    const token = getAuthToken();
    const response = await axios.post(`${BASE_URL}/simulations/${simulationId}/cancel`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
};