// src/components/Login.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import './Login.scss';
import logo from '../static/intruz_logo.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const sanitizedUsername = DOMPurify.sanitize(username);
            const sanitizedPassword = DOMPurify.sanitize(password);

            const response = await axios.post('/api/login', { username: sanitizedUsername, password: sanitizedPassword });
            localStorage.setItem('token', response.data.access_token);
            navigate('/overview'); // Redirect to overview or any other default page
        } catch (error) {
            console.error("Login failed:", error);
            alert('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className="login-page-container">
            <div className="login-page">
                <div className="login-left">
                    <img src={logo} alt="Intruz Logo" className="logo" />
                    <p className="lead">Expose your monitoring gaps <span className="highlight">.</span></p>
                </div>
                <div className="login-right">
                    <div className="login-form">
                        <form onSubmit={handleLogin}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-danger btn-block">Log in</button>
                        </form>
                        <div className="additional-links">
                            <a href="/signup/">Create account</a>
                            <a href="/forgot/">Forgot password</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
