import React from 'react';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} className="desc">{`${entry.name} : ${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
