import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faChartPie, 
    faBoxes, 
    faCrosshairs, 
    faFileAlt, 
    faPlug, 
    faCog, 
    faChevronLeft, 
    faChevronRight,
    faBook,
    faSignOutAlt, 
    faUser,
    faChevronDown,
    faChevronUp,
    faExclamationTriangle,
    faEye

} from '@fortawesome/free-solid-svg-icons';
import './MainLayout.scss';
import logo from '../static/intruz_logo1.png';
import Settings from './Settings';
import axios from 'axios';

const MainLayout = () => {
    const [showSettings, setShowSettings] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [sidebarExpanded, setSidebarExpanded] = useState(true);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [integrationConfigured, setIntegrationConfigured] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchUser = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('/api/protected', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCurrentUser(response.data.logged_in_as);
        } catch (error) {
            console.error('Failed to fetch user:', error);
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        fetchUser();
        
        const integrationSettings = localStorage.getItem('integrationSettings');
        setIntegrationConfigured(!!integrationSettings);
    }, [fetchUser]);

    const toggleSettings = useCallback(() => {
        setShowSettings(prev => !prev);
    }, []);

    const toggleSidebar = useCallback(() => {
        setSidebarExpanded(prev => !prev);
    }, []);

    const toggleUserMenu = useCallback(() => {
        setShowUserMenu(prev => !prev);
    }, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('integrationSettings');
        navigate('/login');
    }, [navigate]);

    const navLinks = useMemo(() => [
        { to: "overview", icon: faChartPie, text: "Overview" },
        { to: "inventory", icon: faBoxes, text: "Inventory" },
        { to: "attack-scenarios", icon: faCrosshairs, text: "Attack Scenarios" },
        { to: "breach-catalog", icon: faBook, text: "Breach Catalog" },
        { to: "reports", icon: faFileAlt, text: "Reports" },
        { to: "log-vision", icon: faEye, text: "Logs Vision" },
        { to: "integrations", icon: faPlug, text: "Integrations" },
    ], []);

    return (
        <div className={`main-layout ${sidebarExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
            <div className="sidebar">
                <button className="toggle-button" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={sidebarExpanded ? faChevronLeft : faChevronRight} />
                </button>
                <div className="logo-container">
                    <img src={logo} alt="Intruz Logo" className="logo" />
                </div>
                <nav>
                    <ul>
                        {navLinks.map(({ to, icon, text }) => (
                            <li key={to}>
                                <NavLink to={to} className={({ isActive }) => isActive ? 'active' : ''}>
                                    <FontAwesomeIcon icon={icon} className="menu-icon" />
                                    <span className="menu-text">{text}</span>
                                    <span className="tooltip">{text}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className="settings">
                    <FontAwesomeIcon icon={faCog} className="gear-icon" onClick={toggleSettings} />
                </div>
            </div>
            <div className="content">
                <div className="top-bar">
                    <div className="user-info">
                        <button onClick={toggleUserMenu} className="user-menu-button">
                            <FontAwesomeIcon icon={faUser} className="user-icon" />
                            <span className="user-name">{currentUser}</span>
                            <FontAwesomeIcon 
                                icon={showUserMenu ? faChevronUp : faChevronDown} 
                                className="dropdown-icon" 
                            />
                        </button>
                        {showUserMenu && (
                            <div className="user-dropdown">
                                <button onClick={handleLogout} className="logout-button">
                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                    <span>Log out</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {!integrationConfigured && location.pathname !== '/integrations' && (
                    <div className="integration-warning">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <span>Please configure your integration settings. </span>
                        <NavLink to="/integrations" className="configure-link">
                            Configure Integration
                        </NavLink>
                    </div>
                )}
                <Outlet />
            </div>
            {showSettings && <Settings onClose={toggleSettings} />}
        </div>
    );
};

export default React.memo(MainLayout);
