import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Collapse, Modal, Button, Alert } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import './SiemInventory.scss';

const SiemInventory = () => {
    const [detectionRules, setDetectionRules] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rulesPerPage, setRulesPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [severityFilter, setSeverityFilter] = useState('');
    const [mitreTTPFilter, setMitreTTPFilter] = useState('');
    const [showQueryModal, setShowQueryModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [mitreTTPs, setMitreTTPs] = useState([]);
    const [expandedRule, setExpandedRule] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const fetchDetectionRules = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, subscriptionId, resourceGroup, workspaceName, workspaceId } = integrationSettings;

            const response = await axios.post('/api/detection_rules', {
                tenantId,
                clientId,
                clientSecret,
                subscriptionId,
                resourceGroup,
                workspaceName,
                workspaceId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const rules = response.data.map(rule => ({
                ruleName: rule.ruleName,
                severity: rule.severity,
                mitreTTP: rule.mitreTTP || [],
                techniques: rule.techniques || [],
                ruleDescription: rule.ruleDescription,
                kqlQuery: rule.kqlQuery,
            }));

            setDetectionRules(rules);
            extractMitreTTPs(rules);
        } catch (error) {
            console.error('Error fetching detection rules:', error);
            setErrorMessage('Failed to fetch detection rules. Please try again.');
            setShowErrorModal(true);
        }
    }, []);

    useEffect(() => {
        fetchDetectionRules();
    }, [fetchDetectionRules]);

    const extractMitreTTPs = useCallback((rules) => {
        const ttpSet = new Set(rules.flatMap(rule => rule.mitreTTP));
        setMitreTTPs(Array.from(ttpSet));
    }, []);

    const debouncedSetSearchTerm = useMemo(
        () => debounce((value) => setSearchTerm(value), 300),
        []
    );

    const handleSearchChange = (e) => {
        debouncedSetSearchTerm(e.target.value);
    };

    const filteredRules = useMemo(() => {
        return detectionRules.filter(rule =>
            rule.ruleName.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (severityFilter === '' || rule.severity === severityFilter) &&
            (mitreTTPFilter === '' || rule.mitreTTP.includes(mitreTTPFilter))
        );
    }, [detectionRules, searchTerm, severityFilter, mitreTTPFilter]);

    const currentRules = useMemo(() => {
        const indexOfLastRule = currentPage * rulesPerPage;
        const indexOfFirstRule = indexOfLastRule - rulesPerPage;
        return filteredRules.slice(indexOfFirstRule, indexOfLastRule);
    }, [currentPage, rulesPerPage, filteredRules]);

    const paginate = useCallback(pageNumber => setCurrentPage(pageNumber), []);

    const handleQueryClick = useCallback((query) => {
        setSelectedQuery(query);
        setShowQueryModal(true);
    }, []);

    const handleDescriptionClick = useCallback((description) => {
        setSelectedDescription(description);
        setShowDescriptionModal(true);
    }, []);

    const handleCloseQueryModal = useCallback(() => {
        setShowQueryModal(false);
        setSelectedQuery('');
    }, []);

    const handleCloseDescriptionModal = useCallback(() => {
        setShowDescriptionModal(false);
        setSelectedDescription('');
    }, []);

    const handleCloseErrorModal = useCallback(() => {
        setShowErrorModal(false);
        setErrorMessage('');
    }, []);

    const handleRulesPerPageChange = useCallback((e) => {
        setRulesPerPage(Number(e.target.value));
        setCurrentPage(1);
    }, []);

    const toggleExpandRule = useCallback((index) => {
        setExpandedRule(prevIndex => prevIndex === index ? null : index);
    }, []);

    const handleTechniqueClick = useCallback((technique, ruleName) => {
        navigate(`/attack-scenarios?technique=${encodeURIComponent(technique)}&rule=${encodeURIComponent(ruleName)}`);
    }, [navigate]);

    return (
        <div className="siem-inventory">
            <header className="inventory-header">
                <div className="header-content">
                    <h1>SIEM Inventory</h1>
                    <p>View and manage your detection rules</p>
                    <div className="filters">
                        <div className="filter">
                            <label htmlFor="severity">Severity</label>
                            <select
                                id="severity"
                                value={severityFilter}
                                onChange={(e) => setSeverityFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                            </select>
                        </div>
                        <div className="filter">
                            <label htmlFor="mitreTTP">MITRE TTP</label>
                            <select
                                id="mitreTTP"
                                value={mitreTTPFilter}
                                onChange={(e) => setMitreTTPFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                {mitreTTPs.map((ttp, index) => (
                                    <option key={index} value={ttp}>{ttp}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter">
                            <label htmlFor="searchTerm">Search</label>
                            <input
                                type="text"
                                id="searchTerm"
                                placeholder="Rule name"
                                onChange={handleSearchChange}
                                className="search-bar"
                            />
                        </div>
                        <div className="filter">
                            <label htmlFor="rulesPerPage">Rules per page</label>
                            <select
                                id="rulesPerPage"
                                value={rulesPerPage}
                                onChange={handleRulesPerPageChange}
                            >
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </header>
            <div className="inventory-content">
                <div className="inventory-grid">
                    {currentRules.map((rule, index) => (
                        <RuleCard
                            key={index}
                            rule={rule}
                            index={index}
                            expandedRule={expandedRule}
                            toggleExpandRule={toggleExpandRule}
                            handleDescriptionClick={handleDescriptionClick}
                            handleQueryClick={handleQueryClick}
                            handleTechniqueClick={handleTechniqueClick}
                            setErrorMessage={setErrorMessage}
                            setShowErrorModal={setShowErrorModal}
                        />
                    ))}
                </div>
                <Pagination
                    rulesPerPage={rulesPerPage}
                    totalRules={filteredRules.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>
            <Modal show={showQueryModal} onHide={handleCloseQueryModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Query Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SyntaxHighlighter language="kusto" style={okaidia}>
                        {selectedQuery}
                    </SyntaxHighlighter>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseQueryModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDescriptionModal} onHide={handleCloseDescriptionModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Rule Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{selectedDescription}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDescriptionModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={handleCloseErrorModal} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">{errorMessage}</Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const RuleCard = React.memo(({ rule, index, expandedRule, toggleExpandRule, handleDescriptionClick, handleQueryClick, handleTechniqueClick, setErrorMessage, setShowErrorModal }) => {
    const [timeframe, setTimeframe] = useState(7);
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchIncidents = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, subscriptionId, resourceGroup, workspaceName, workspaceId } = integrationSettings;

            const response = await axios.post('/api/incidents', {
                tenantId,
                clientId,
                clientSecret,
                subscriptionId,
                resourceGroup,
                workspaceName,
                workspaceId,
                ruleName: rule.ruleName,
                timeframe
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setIncidents(response.data);
        } catch (error) {
            console.error('Error fetching incidents:', error);
            setErrorMessage(`Error fetching incidents: ${error.message}`);
            setShowErrorModal(true);
        }
        setLoading(false);
    }, [timeframe, rule.ruleName, setErrorMessage, setShowErrorModal]);

    useEffect(() => {
        if (expandedRule === index) {
            fetchIncidents();
        }
    }, [expandedRule, index, fetchIncidents]);

    const handleTimeframeChange = (e) => {
        setTimeframe(Number(e.target.value));
    };

    useEffect(() => {
        if (expandedRule === index) {
            fetchIncidents();
        }
    }, [timeframe, expandedRule, index, fetchIncidents]);

    const chartData = useMemo(() => {
        const labels = Array.from(new Set(incidents.map(incident => new Date(incident[0]).toLocaleDateString())));
        const data = labels.map(label => incidents.filter(incident => new Date(incident[0]).toLocaleDateString() === label).length);

        return {
            labels,
            datasets: [{
                label: 'Incidents',
                data,
                backgroundColor: 'rgba(99, 93, 255, 0.5)',
                borderColor: '#635dff',
                borderWidth: 1
            }]
        };
    }, [incidents]);

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                    precision: 0
                }
            },
            x: {
                ticks: {
                    autoSkip: false
                }
            }
        }
    };

    return (
        <div className={`inventory-card ${expandedRule === index ? 'expanded' : ''}`}>
            <div className="card-header" onClick={() => toggleExpandRule(index)}>
                <div className="card-header-left">
                    <h2 className="rule-name">{rule.ruleName}</h2>
                    <span className={`severity ${rule.severity.toLowerCase()}`}>{rule.severity}</span>
                    <span className="tactic">{rule.mitreTTP.join(', ')}</span>
                </div>
                <div className="card-header-right">
                    {expandedRule === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
            </div>
            <Collapse in={expandedRule === index}>
                <div className="card-body">
                    <div className="rule-details">
                        <div className="mitre-ttp">
                            <strong>MITRE TTP:</strong>
                            <div className="mitre-map">
                                {rule.mitreTTP.map((ttp, ttpIndex) => (
                                    <span key={ttpIndex} className="ttp-item">{ttp}</span>
                                ))}
                            </div>
                        </div>
                        <div className="mitre-techniques">
                            <strong>MITRE Techniques:</strong>
                            <div className="techniques-map">
                                {rule.techniques.map((tech, techIndex) => (
                                    <span 
                                        key={techIndex} 
                                        className="tech-item clickable"
                                        onClick={() => handleTechniqueClick(tech, rule.ruleName)}
                                    >
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <button className="btn-modern" onClick={() => handleDescriptionClick(rule.ruleDescription)}>View Description</button>
                        <div className="query-container" onClick={() => handleQueryClick(rule.kqlQuery)}>
                            <SyntaxHighlighter language="kusto" style={okaidia}>
                                {rule.kqlQuery}
                            </SyntaxHighlighter>
                        </div>
                        <div className="incident-details">
                            <div className="timeframe-selector">
                                <label htmlFor="timeframe">Timeframe:</label>
                                <select id="timeframe" value={timeframe} onChange={handleTimeframeChange}>
                                    <option value={1}>1 day</option>
                                    <option value={7}>7 days</option>
                                    <option value={14}>14 days</option>
                                    <option value={30}>30 days</option>
                                </select>
                            </div>
                            {loading ? (
                                <p>Loading incidents...</p>
                            ) : (
                                <>
                                    <div className="incident-chart" style={{ height: '200px' }}>
                                        <Line data={chartData} options={chartOptions} />
                                    </div>
                                    <div className="incident-table">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {incidents.map((incident, index) => (
                                                    <tr key={index}>
                                                        <td>{new Date(incident[0]).toLocaleDateString()}</td>
                                                        <td>{incident[1]}</td>
                                                        <td>
                                                            <a href={incident[2]} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                                                View Incident
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
});

const Pagination = React.memo(({ rulesPerPage, totalRules, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalRules / rulesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className="pagination-nav">
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
});

export default SiemInventory;
