import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { RuleProvider } from './RuleContext';
import MainLayout from './components/MainLayout';
import Overview from './components/Overview';
import SiemInventory from './components/SiemInventory';
import AttackScenarios from './components/AttackScenarios';
import Reports from './components/Reports';
import LogVision from './components/LogVision';
import Integrations from './components/Integrations';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import BreachCatalog from './components/BreachCatalog';

function App() {
    return (
        <RuleProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<Navigate to="/overview" replace />} />
                            <Route path="overview" element={<Overview />} />
                            <Route path="inventory" element={<SiemInventory />} />
                            <Route path="attack-scenarios" element={<AttackScenarios />} />
                            <Route path="breach-catalog" element={<BreachCatalog />} />
                            <Route path="reports" element={<Reports />} />
                            <Route path="log-vision" element={<LogVision />} />
                            <Route path="integrations" element={<Integrations />} />
                            <Route path="*" element={<Navigate to="/overview" replace />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </RuleProvider>
    );
}

export default App;