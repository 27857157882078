import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import { Form, Button, Card, Badge, Spinner, Alert, InputGroup, Accordion } from 'react-bootstrap';
import { FaFilter, FaSearch, FaTimes, FaExternalLinkAlt, FaChevronDown, FaChevronUp, FaTrash, FaChevronLeft, FaChevronRight, FaShieldAlt, FaPlay, FaUpload, FaTable, FaSync } from 'react-icons/fa';
import './BreachCatalog.scss';
import { createSimulation, getSimulationStatus, getSimulationsForRule, checkForTriggeredRules, cancelSimulation } from '../services/simulationService';
import SimulationFeedback from './SimulationFeedback';
import { useRuleContext } from '../RuleContext';

const API_KEY = 'zX9fK2pL7mN4qR8tU3wY6vB1cE5gH0jA';
const API_BASE_URL = 'https://relic-app.azurewebsites.net';

const BreachCatalog = () => {
    const { ruleStates, updateRuleState, fetchSimulations, refreshSimulation, addSimulation } = useRuleContext();
    const [rulesData, setRulesData] = useState({
        items: [],
        total: 0,
        page: 1,
        size: 50,
        pages: 0
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        mitreCategories: [],
        mitreTechniques: [],
        products: []
    });
    const [selectedFilters, setSelectedFilters] = useState({
        mitreCategories: [],
        mitreTechniques: [],
        products: []
    });
    const [filterSearchTerms, setFilterSearchTerms] = useState({
        mitreCategories: '',
        mitreTechniques: '',
        products: ''
    });
    const [showSidebar, setShowSidebar] = useState(true);
    const [selectedRule, setSelectedRule] = useState(null);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [clientTables, setClientTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [tableSchema, setTableSchema] = useState(null);

    useEffect(() => {
        fetchFilters();
        fetchClientTables();
    }, []);

    const fetchFilters = async () => {
        try {
            console.log('Fetching filters...');
            const [categoriesRes, techniquesRes, productsRes] = await Promise.all([
                axios.get(`${API_BASE_URL}/api/v1/mitre-categories`, { headers: { 'X-API-Key': API_KEY } }),
                axios.get(`${API_BASE_URL}/api/v1/mitre-techniques`, { headers: { 'X-API-Key': API_KEY } }),
                axios.get(`${API_BASE_URL}/api/v1/products`, { headers: { 'X-API-Key': API_KEY } })
            ]);

            console.log('Filters fetched successfully');
            setFilters({
                mitreCategories: categoriesRes.data || [],
                mitreTechniques: techniquesRes.data || [],
                products: productsRes.data || []
            });
        } catch (err) {
            console.error('Error fetching filters:', err);
            setError('Failed to fetch filters. Please try again later.');
        }
    };

    const fetchClientTables = async () => {
        try {
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

            const response = await axios.post('/api/client_tables', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200 && Array.isArray(response.data)) {
                setClientTables(response.data.map(table => table[0]));
            } else {
                console.error('Failed to fetch client tables');
                setError('Failed to fetch client tables. Unexpected response format.');
            }
        } catch (error) {
            console.error('Error fetching client tables:', error);
            setError('Failed to fetch client tables. Please check your integration settings.');
        }
    };

    const fetchTableSchema = async (tableName) => {
        try {
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

            const response = await axios.post('/api/table_schema', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId,
                tableName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setTableSchema(response.data);
            } else {
                console.error('Failed to fetch table schema');
                setError('Failed to fetch table schema. Unexpected response.');
            }
        } catch (error) {
            console.error('Error fetching table schema:', error);
            setError('Failed to fetch table schema. Please try again.');
        }
    };

    const handleFilterChange = (filterType, value) => {
        setSelectedFilters(prev => ({
            ...prev,
            [filterType]: prev[filterType].includes(value)
                ? prev[filterType].filter(item => item !== value)
                : [...prev[filterType], value]
        }));
    };

    const clearAllFilters = () => {
        setSelectedFilters({
            mitreCategories: [],
            mitreTechniques: [],
            products: []
        });
        setFilterSearchTerms({
            mitreCategories: '',
            mitreTechniques: '',
            products: ''
        });
    };

    const applyFilters = async () => {
        try {
            setLoading(true);
            console.log('Applying filters:', selectedFilters);

            const tags = [...selectedFilters.mitreCategories, ...selectedFilters.mitreTechniques].join(',');
            const products = selectedFilters.products.join(',');

            console.log('Sending request with params:', { tags, logsource_product: products });

            const response = await axios.get(`${API_BASE_URL}/api/v1/rules`, {
                headers: { 'X-API-Key': API_KEY },
                params: {
                    tags: tags || undefined,
                    logsource_product: products || undefined,
                    page: 1,
                    size: 50
                }
            });

            console.log('Received response:', response.data);
            setRulesData(response.data);
            setLoading(false);
            setFiltersApplied(true);
        } catch (err) {
            console.error('Error applying filters:', err);
            setError('Failed to apply filters. Please try again later.');
            setLoading(false);
        }
    };

    const debouncedSetSearchTerm = useMemo(
        () => debounce((value) => setSearchTerm(value), 300),
        []
    );

    const handleSearchChange = (e) => {
        debouncedSetSearchTerm(e.target.value);
    };

    const filteredRules = useMemo(() => {
        return (rulesData.items || []).filter(rule =>
            rule.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            rule.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [rulesData.items, searchTerm]);

    const handlePageChange = async (newPage) => {
        try {
            setLoading(true);
            const tags = [...selectedFilters.mitreCategories, ...selectedFilters.mitreTechniques].join(',');
            const products = selectedFilters.products.join(',');

            const response = await axios.get(`${API_BASE_URL}/api/v1/rules`, {
                headers: { 'X-API-Key': API_KEY },
                params: {
                    tags: tags || undefined,
                    logsource_product: products || undefined,
                    page: newPage,
                    size: rulesData.size
                }
            });

            setRulesData(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error changing page:', err);
            setError('Failed to load more rules. Please try again later.');
            setLoading(false);
        }
    };

    const handleTableChange = (e) => {
        const selectedTableName = e.target.value;
        setSelectedTable(selectedTableName);
        if (selectedTableName) {
            fetchTableSchema(selectedTableName);
        } else {
            setTableSchema(null);
        }
    };

    return (
        <div className="breach-catalog">
            <div className={`catalog-sidebar ${showSidebar ? 'show' : 'hide'}`}>
                <div className="sidebar-header">
                    <h3>Filters</h3>
                    <Button variant="outline-danger" size="sm" onClick={clearAllFilters}>
                        <FaTrash /> Clear All
                    </Button>
                </div>
                <div className="sidebar-content">
                    <FilterSection
                        title="MITRE Categories"
                        items={filters.mitreCategories}
                        selectedItems={selectedFilters.mitreCategories}
                        onChange={(value) => handleFilterChange('mitreCategories', value)}
                        searchTerm={filterSearchTerms.mitreCategories}
                        onSearchChange={(value) => setFilterSearchTerms(prev => ({ ...prev, mitreCategories: value }))}
                    />
                    <FilterSection
                        title="MITRE Techniques"
                        items={filters.mitreTechniques}
                        selectedItems={selectedFilters.mitreTechniques}
                        onChange={(value) => handleFilterChange('mitreTechniques', value)}
                        searchTerm={filterSearchTerms.mitreTechniques}
                        onSearchChange={(value) => setFilterSearchTerms(prev => ({ ...prev, mitreTechniques: value }))}
                    />
                    <FilterSection
                        title="Products"
                        items={filters.products}
                        selectedItems={selectedFilters.products}
                        onChange={(value) => handleFilterChange('products', value)}
                        searchTerm={filterSearchTerms.products}
                        onSearchChange={(value) => setFilterSearchTerms(prev => ({ ...prev, products: value }))}
                    />
                </div>
                <Button className="apply-filters-btn" onClick={applyFilters}>Apply Filters</Button>
            </div>
            <div className={`main-content ${showSidebar ? 'sidebar-open' : ''} ${selectedRule ? 'rule-selected' : ''}`}>
                <div className="content-header">
                    <Button 
                        variant="light" 
                        className="toggle-sidebar-btn"
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        <FaFilter />
                    </Button>
                    <h1>Breach Catalog</h1>
                    <div className="search-container">
                        <FaSearch />
                        <Form.Control
                            type="text"
                            placeholder="Search rules..."
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
                {filtersApplied && (
                    <div className="selected-filters">
                        <h4>Selected Filters:</h4>
                        {Object.entries(selectedFilters).map(([key, values]) => (
                            values.length > 0 && (
                                <div key={key} className="filter-group">
                                    <span className="filter-type">{key}:</span>
                                    {values.map(value => (
                                        <Badge 
                                            key={value} 
                                            bg="primary" 
                                            className="filter-badge"
                                            onClick={() => handleFilterChange(key, value)}
                                        >
                                            {value} <FaTimes />
                                        </Badge>
                                    ))}
                                </div>
                            )
                        ))}
                    </div>
                )}
                {loading ? (
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : !filtersApplied ? (
                    <div className="welcome-message">
                        <h2>Welcome to the Breach Catalog</h2>
                        <p>Use the filters on the left to explore Sigma rules. Apply filters to see results.</p>
                    </div>
                ) : filteredRules.length === 0 ? (
                    <div className="no-results">
                        <h2>No Results Found</h2>
                        <p>Try adjusting your filters or search terms.</p>
                    </div>
                ) : (
                    <div className="rules-container">
                        <p>Showing {filteredRules.length} of {rulesData.total} results</p>
                        {filteredRules.map(rule => (
                            <RuleCard 
                                key={rule._id} 
                                rule={rule} 
                                onClick={() => setSelectedRule(rule)}
                                isSelected={selectedRule && selectedRule._id === rule._id}
                            />
                        ))}
                        <Pagination
                            currentPage={rulesData.page}
                            totalPages={rulesData.pages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                )}
            </div>
            {selectedRule && (
                <RuleSidebar
                    rule={selectedRule}
                    onClose={() => setSelectedRule(null)}
                    clientTables={clientTables}
                    selectedTable={selectedTable}
                    onTableChange={handleTableChange}
                    tableSchema={tableSchema}
                    updateRuleState={updateRuleState}
                />
            )}
        </div>
    );
};

const FilterSection = ({ title, items, selectedItems, onChange, searchTerm, onSearchChange }) => {
    const [expanded, setExpanded] = useState(true);

    const filteredItems = items.filter(item => 
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="filter-section">
            <h4 onClick={() => setExpanded(!expanded)}>
                {title} {expanded ? <FaChevronUp /> : <FaChevronDown />}
            </h4>
            {expanded && (
                <>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <FaSearch />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder={`Search ${title}`}
                            value={searchTerm}
                            onChange={(e) => onSearchChange(e.target.value)}
                        />
                    </InputGroup>
                    <div className="filter-items">
                    {filteredItems.map((item, index) => (
                            <Form.Check
                                key={index}
                                type="checkbox"
                                id={`${title}-${index}`}
                                label={item}
                                checked={selectedItems.includes(item)}
                                onChange={() => onChange(item)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

const RuleCard = ({ rule, onClick, isSelected }) => {
    return (
        <Card className={`mb-3 rule-card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            <Card.Body>
                <div className="rule-header">
                    <FaShieldAlt className="rule-icon" />
                    <div className="rule-title-wrapper">
                        <Card.Title>{rule.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">ID: {rule.id}</Card.Subtitle>
                    </div>
                </div>
                <Card.Text>{rule.description}</Card.Text>
                <div className="rule-meta">
                    <Badge bg="primary" className="me-2">Level: {rule.level}</Badge>
                    <Badge bg="secondary" className="me-2">Status: {rule.status}</Badge>
                </div>
                <div className="tags mt-2">
                    {rule.tags && rule.tags.map((tag, index) => (
                        <Badge key={index} bg="info" className="me-1">{tag}</Badge>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
};

const RuleSidebar = ({ rule, onClose, clientTables, selectedTable, onTableChange, tableSchema }) => {
    const { ruleStates, updateRuleState, refreshSimulation } = useRuleContext();
    const [generatedLogs, setGeneratedLogs] = useState(null);
    const [simulations, setSimulations] = useState([]);
    const [isGeneratingLogs, setIsGeneratingLogs] = useState(false);
    const [isPushingLogs, setIsPushingLogs] = useState(false);
    const [logPushError, setLogPushError] = useState(null);
    const [logPushSuccess, setLogPushSuccess] = useState(null);
    const [ingestionMethod, setIngestionMethod] = useState('agent');
    const [dceUri, setDceUri] = useState('');
    const [dcrId, setDcrId] = useState('');
    const [dcrImmutableId, setDcrImmutableId] = useState('');
    const [streams, setStreams] = useState([]);
    const [selectedStream, setSelectedStream] = useState('');
    const [dcrs, setDcrs] = useState([]);
    const [sharedKey, setSharedKey] = useState('');
    const [activeTab, setActiveTab] = useState('overview');
    const [simulation, setSimulation] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [simulationTime, setSimulationTime] = useState(60); // Default to 60 minutes

    useEffect(() => {
        fetchDataCollectionRules();
    }, []);

    useEffect(() => {
        if (rule) {
            const savedState = ruleStates[rule.id];
            if (savedState) {
                setGeneratedLogs(savedState.generatedLogs);
                setSimulations(savedState.simulations || []);
            } else {
                fetchSimulations();
            }
        }
    }, [rule, ruleStates]);

    useEffect(() => {
        if (dcrId) {
            const selectedDcr = dcrs.find(dcr => dcr.name === dcrId);
            if (selectedDcr && selectedDcr.properties && selectedDcr.properties.immutableId) {
                setDcrImmutableId(selectedDcr.properties.immutableId);
                fetchStreams();
            }
        }
    }, [dcrId]);

    const fetchDataCollectionRules = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/get_dcrs', {
                ...JSON.parse(localStorage.getItem('integrationSettings'))
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDcrs(response.data.dcrs || []);
        } catch (error) {
            console.error('Error fetching DCRs:', error);
            setLogPushError('Failed to fetch DCRs. Please try again.');
        }
    };

    const fetchStreams = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/get_streams', { dcrName: dcrId }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setStreams(response.data.streams || []);
        } catch (error) {
            console.error('Error fetching streams:', error);
            setLogPushError(`Failed to fetch streams: ${error.message}`);
        }
    };

    const fetchSimulations = useCallback(async () => {
        if (rule && rule.id) {
            try {
                setIsRefreshing(true);
                const token = localStorage.getItem('token');
                const response = await axios.get('/api/simulations', {
                    params: { ruleId: rule.id },
                    headers: { Authorization: `Bearer ${token}` }
                });
                const fetchedSimulations = response.data;
                setSimulations(fetchedSimulations);
                updateRuleState(rule.id, { simulations: fetchedSimulations });
            } catch (error) {
                console.error('Error fetching simulations:', error);
                setLogPushError('Failed to fetch simulations. Please try again.');
            } finally {
                setIsRefreshing(false);
            }
        }
    }, [rule, updateRuleState]);

    const refreshSimulations = useCallback(async () => {
        if (rule && rule.id) {
            try {
                setIsRefreshing(true);
                const fetchedSimulations = await getSimulationsForRule(rule.id);
                if (fetchedSimulations.length === 0) {
                    console.log('No simulations fetched. This might be due to an authentication issue.');
                    // Optionally, you can set some state here to inform the user
                    // setLogPushError('Unable to fetch simulations. Please try logging in again.');
                } else {
                    setSimulations(fetchedSimulations);
                    updateRuleState(rule.id, { simulations: fetchedSimulations });
                }
            } catch (error) {
                console.error('Error refreshing simulations:', error);
                setLogPushError('Failed to refresh simulations. Please try again.');
            } finally {
                setIsRefreshing(false);
            }
        }
    }, [rule, updateRuleState]);

    const handleSimulationTimeChange = (e) => {
        setSimulationTime(parseInt(e.target.value, 10));
    };

    const handleGenerateLogs = async () => {
        setIsGeneratingLogs(true);
        setGeneratedLogs(null);
        setLogPushError(null);
        setLogPushSuccess(null);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/api/generate_sigma_logs', {
                ruleName: rule.title,
                ruleDescription: rule.description,
                platform: rule.logsource?.product,
                detection: rule.detection,
                tableSchema: tableSchema
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data.success) {
                setGeneratedLogs(response.data.generatedLogs);
                updateRuleState(rule.id, { generatedLogs: response.data.generatedLogs });
            } else {
                throw new Error(response.data.message || 'Failed to generate logs');
            }
        } catch (error) {
            console.error('Error generating logs:', error);
            setLogPushError(error.response?.data?.message || error.message || 'Failed to generate logs. Please try again.');
        } finally {
            setIsGeneratingLogs(false);
        }
    };

    const handlePushLogs = async () => {
        setIsPushingLogs(true);
        setLogPushError(null);
        setLogPushSuccess(null);

        const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));
        if (!integrationSettings) {
            setLogPushError('Integration settings not found. Please configure your integration in the Integrations page.');
            setIsPushingLogs(false);
            return;
        }

        const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

        if (!tenantId || !clientId || !clientSecret || !workspaceId) {
            setLogPushError('Incomplete integration settings. Please reconfigure your integration.');
            setIsPushingLogs(false);
            return;
        }

        if (ingestionMethod === 'agent' && (!selectedStream || !dceUri || !dcrImmutableId)) {
            setLogPushError('Please fill in all required fields for Agent Log ingestion.');
            setIsPushingLogs(false);
            return;
        }

        if (ingestionMethod === 'http' && !sharedKey) {
            setLogPushError('Please enter the shared key for HTTP Data Collector API.');
            setIsPushingLogs(false);
            return;
        }

        try {
            const token = localStorage.getItem('token');
            let response;

            if (ingestionMethod === 'agent') {
                response = await axios.post('/api/push_log', {
                    logs: generatedLogs[selectedTable],
                    tableName: selectedTable,
                    dceUri,
                    dcrImmutableId,
                    tenantId,
                    clientId,
                    clientSecret,
                    selectedStream
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } else {
                response = await axios.post('/api/push_log_http', {
                    logs: { [selectedTable]: generatedLogs[selectedTable] },
                    customerId: workspaceId,
                    sharedKey
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }

            const newSimulation = await createSimulation(rule.id, simulationTime); // Use the simulation time from state
            setSimulations(prevSimulations => [...prevSimulations, newSimulation]);
            updateRuleState(rule.id, { 
                simulations: [...simulations, newSimulation],
                generatedLogs: generatedLogs
            });

            pollSimulationStatus(newSimulation.id);
            
            setLogPushSuccess(`Successfully pushed logs and started simulation. Simulation ID: ${newSimulation.id}`);
        } catch (error) {
            console.error('Error pushing logs or creating simulation:', error);
            setLogPushError(`Failed to push logs or start simulation: ${error.message}`);
        } finally {
            setIsPushingLogs(false);
        }
    };

    const pollSimulationStatus = async (simulationId) => {
        const interval = setInterval(async () => {
            try {
                const status = await refreshSimulation(rule.id, simulationId);
                if (status && (status.status === 'Completed' || status.status === 'Failed')) {
                    clearInterval(interval);
                    await fetchSimulations(rule.id);
                }
            } catch (error) {
                console.error('Error polling simulation status:', error);
                // Don't clear the interval here, just log the error
            }
        }, 5000);
      
        return () => clearInterval(interval);
    };

    const handleCancelSimulation = async (simulationId) => {
        try {
            await cancelSimulation(simulationId);
            await refreshSimulations();
        } catch (error) {
            // Ignore the error if it's a 401
            if (!error.response || error.response.status !== 401) {
                console.error('Error cancelling simulation:', error);
                setLogPushError('Failed to cancel simulation. Please try again.');
            }
        }
    };

    if (!rule) return null;

    return (
        <div className="rule-sidebar">
            <div className="rule-sidebar-header">
                <h2>{rule.title}</h2>
                <Button variant="link" className="close-btn" onClick={onClose}>
                    <FaTimes />
                </Button>
            </div>
            <div className="rule-sidebar-tabs">
                <Button
                    variant={activeTab === 'overview' ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveTab('overview')}
                >
                    Overview
                </Button>
                <Button
                    variant={activeTab === 'simulation' ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveTab('simulation')}
                >
                    Simulation
                </Button>
                <Button
                    variant={activeTab === 'feedback' ? 'primary' : 'outline-primary'}
                    onClick={() => setActiveTab('feedback')}
                >
                    Feedback
                </Button>
            </div>
            <div className="rule-sidebar-content">
                {activeTab === 'overview' && (
                    <>
                        <section className="rule-section">
                            <h3>Description</h3>
                            <p>{rule.description}</p>
                        </section>

                        <section className="rule-section">
                            <h3>Rule Details</h3>
                            <ul className="rule-details-list">
                                <li><strong>ID:</strong> {rule.id}</li>
                                <li><strong>Status:</strong> {rule.status}</li>
                                <li><strong>Level:</strong> {rule.level}</li>
                            </ul>
                        </section>

                        <section className="rule-section">
                            <h3>Tags</h3>
                            <div className="tags-container">
                                {rule.tags && rule.tags.map((tag, index) => (
                                    <Badge key={index} bg="info" className="me-1 mb-1">{tag}</Badge>
                                ))}
                            </div>
                        </section>

                        <section className="rule-section">
                            <h3>Log Source</h3>
                            <ul className="rule-details-list">
                                <li><strong>Category:</strong> {rule.logsource?.category || 'N/A'}</li>
                                <li><strong>Product:</strong> {rule.logsource?.product || 'N/A'}</li>
                                <li><strong>Service:</strong> {rule.logsource?.service || 'N/A'}</li>
                            </ul>
                        </section>

                        <section className="rule-section">
                            <h3>Detection</h3>
                            <div className="detection-code-container">
                                <pre className="detection-code">
                                    {JSON.stringify(rule.detection, null, 2)}
                                </pre>
                            </div>
                        </section>

                        {rule.falsepositives && rule.falsepositives.length > 0 && (
                            <section className="rule-section">
                                <h3>False Positives</h3>
                                <ul className="false-positives-list">
                                    {rule.falsepositives.map((fp, index) => (
                                        <li key={index}>{fp}</li>
                                    ))}
                                </ul>
                            </section>
                        )}

                        {rule.references && rule.references.length > 0 && (
                            <section className="rule-section">
                                <h3>References</h3>
                                <ul className="references-list">
                                    {rule.references.map((ref, index) => (
                                        <li key={index}>
                                            <a href={ref} target="_blank" rel="noopener noreferrer">
                                                {ref} <FaExternalLinkAlt size="0.8em" />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </section>
                        )}
                    </>
                )}

                {activeTab === 'simulation' && (
                    <>
                        <section className="rule-section">
                            <h3>Log Simulation</h3>
                            <Form.Group>
                                <Form.Label>Select Table</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    value={selectedTable} 
                                    onChange={onTableChange}
                                >
                                    <option value="">Select a table</option>
                                    {clientTables.map((table, index) => (
                                        <option key={index} value={table}>{table}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {tableSchema && (
                                <Accordion defaultActiveKey="0" className="mt-3">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Table Schema: {selectedTable}</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="schema-list">
                                                {tableSchema.columns.map((column, index) => (
                                                    <li key={index}>{column.name} ({column.type})</li>
                                                ))}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}
                            <Form.Group className="mb-3">
                                <Form.Label>Simulation Time (minutes)</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="30"
                                    max="1440"
                                    value={simulationTime}
                                    onChange={handleSimulationTimeChange}
                                />
                            </Form.Group>
                            <Button 
                                variant="primary" 
                                className="mt-3 generate-logs-btn"
                                onClick={handleGenerateLogs}
                                disabled={!selectedTable || !tableSchema || isGeneratingLogs}
                            >
                                {isGeneratingLogs ? <Spinner animation="border" size="sm" /> : <FaPlay />} Generate Logs
                            </Button>
                            {generatedLogs && (
                                <>
                                    <div className="generated-logs mt-3">
                                        <h4>Generated Logs:</h4>
                                        <pre>{JSON.stringify(generatedLogs, null, 2)}</pre>
                                    </div>
                                    <Form.Group className="mt-3">
                                        <Form.Label>Ingestion Method</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={ingestionMethod}
                                            onChange={(e) => setIngestionMethod(e.target.value)}
                                        >
                                            <option value="agent">Agent Logs</option>
                                            <option value="http">HTTP Data Collector API</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {ingestionMethod === 'agent' ? (
                                        <>
                                            <Form.Group>
                                                <Form.Label>Data Collection Endpoint URI</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={dceUri}
                                                    onChange={(e) => setDceUri(e.target.value)}
                                                    placeholder="https://example.ingest.monitor.azure.com"
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Select Data Collection Rule</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={dcrId}
                                                    onChange={(e) => setDcrId(e.target.value)}
                                                >
                                                    <option value="">Select a DCR</option>
                                                    {dcrs.map((dcr, index) => (
                                                        <option key={index} value={dcr.name}>{dcr.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Select Stream</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={selectedStream}
                                                    onChange={(e) => setSelectedStream(e.target.value)}
                                                >
                                                    <option value="">Select a stream</option>
                                                    {streams.map((stream, index) => (
                                                        <option key={index} value={stream}>{stream}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <Form.Group>
                                            <Form.Label>Shared Key</Form.Label>
                                            <Form.Control
                                                type="password"
                                                value={sharedKey}
                                                onChange={(e) => setSharedKey(e.target.value)}
                                                placeholder="Enter shared key"
                                            />
                                        </Form.Group>
                                    )}
                                    <Button 
                                        variant="success" 
                                        className="mt-3 push-logs-btn"
                                        onClick={handlePushLogs}
                                        disabled={isPushingLogs}
                                    >
                                        {isPushingLogs ? <Spinner animation="border" size="sm" /> : <FaUpload />} 
                                        Push Logs and Start {simulationTime}-minute Simulation
                                    </Button>
                                </>
                            )}
                            {logPushSuccess && <Alert variant="success" className="mt-3">{logPushSuccess}</Alert>}
                            {logPushError && <Alert variant="danger" className="mt-3">{logPushError}</Alert>}
                        </section>
                        <SimulationFeedback simulation={simulation} />
                    </>
                )}

                {activeTab === 'feedback' && (
                    <div className="feedback-section">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3>Simulation History</h3>
                            <Button 
                                variant="outline-primary" 
                                onClick={refreshSimulations}
                                disabled={isRefreshing}
                            >
                                {isRefreshing ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <><FaSync /> Refresh</>
                                )}
                            </Button>
                        </div>
                        {simulations.length > 0 ? (
                            simulations.map((sim) => (
                                <SimulationFeedback 
                                    key={sim.id} 
                                    simulation={sim} 
                                    onRefresh={() => refreshSimulations()}
                                    onCancel={() => handleCancelSimulation(sim.id)}
                                />
                            ))
                        ) : (
                            <p>No simulations have been run for this rule yet.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];
    let startPage, endPage;
    if (totalPages <= 10) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className='pagination'>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => onPageChange(currentPage - 1)} href="#!">
                        <FaChevronLeft />
                    </a>
                </li>
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <a className="page-link" onClick={() => onPageChange(1)} href="#!">1</a>
                        </li>
                        {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <a onClick={() => onPageChange(number)} href="#!" className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <a className="page-link" onClick={() => onPageChange(totalPages)} href="#!">{totalPages}</a>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={() => onPageChange(currentPage + 1)} href="#!">
                        <FaChevronRight />
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default BreachCatalog;
