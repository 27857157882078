import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Corrected import without curly braces

const PrivateRoute = () => {
    const token = localStorage.getItem('token');
    let isAuthenticated = false;

    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            isAuthenticated = decodedToken.exp > currentTime;
        } catch (error) {
            console.error('Invalid token:', error);
            isAuthenticated = false;
        }
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
