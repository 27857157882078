import React, { useState, useEffect } from 'react';
import { Card, Badge, ListGroup, Button, Accordion, ProgressBar } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaSpinner, FaExclamationTriangle, FaSync, FaClock, FaStop, FaUser } from 'react-icons/fa';
import { cancelSimulation } from '../services/simulationService';

const SimulationFeedback = ({ simulation, onRefresh, onCancel }) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (simulation && simulation.status === 'In Progress') {
            const interval = setInterval(() => {
                const now = new Date();
                const startTime = new Date(simulation.startTime);
                const endTime = new Date(startTime.getTime() + simulation.timeWindow * 60000);
                const totalDuration = endTime - startTime;
                const elapsed = now - startTime;
                const timeLeft = endTime - now;
                
                if (timeLeft > 0) {
                    const minutes = Math.floor(timeLeft / 60000);
                    const seconds = Math.floor((timeLeft % 60000) / 1000);
                    setRemainingTime(`${minutes}m ${seconds}s`);
                    setProgress((elapsed / totalDuration) * 100);
                } else {
                    setRemainingTime('Completed');
                    setProgress(100);
                    clearInterval(interval);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [simulation]);

    if (!simulation) return null;

    const getStatusIcon = () => {
        switch (simulation.status) {
            case 'Completed':
                return <FaCheckCircle className="text-success" />;
            case 'Failed':
                return <FaTimesCircle className="text-danger" />;
            case 'In Progress':
                return <FaSpinner className="text-warning fa-spin" />;
            case 'Cancelled':
                return <FaStop className="text-secondary" />;
            default:
                return <FaExclamationTriangle className="text-warning" />;
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date) 
            ? date.toLocaleString() 
            : 'Invalid Date';
    };

    const handleCancel = async () => {
        try {
            await cancelSimulation(simulation.id);
            onRefresh();
        } catch (error) {
            console.error('Error cancelling simulation:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <Card className="mb-3">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        Simulation {simulation.id.slice(0, 8)}...
                        {simulation.userId && (
                            <span className="ms-2">
                                <FaUser /> {simulation.userId}
                            </span>
                        )}
                    </span>
                    {getStatusIcon()}
                    <Button variant="outline-secondary" size="sm" onClick={onRefresh}>
                        <FaSync /> Refresh
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Status:</strong> {simulation.status}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <strong>Start Time:</strong> {formatDate(simulation.startTime)}
                    </ListGroup.Item>
                    {simulation.endTime && (
                        <ListGroup.Item>
                            <strong>End Time:</strong> {formatDate(simulation.endTime)}
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item>
                        <strong>Time Window:</strong> {simulation.timeWindow} minute(s) of simulated activity
                    </ListGroup.Item>
                    {simulation.status === 'In Progress' && (
                        <>
                            <ListGroup.Item>
                                <strong>Remaining Time:</strong> <FaClock /> {remainingTime}
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <ProgressBar now={progress} label={`${Math.round(progress)}%`} />
                            </ListGroup.Item>
                        </>
                    )}
                    {simulation.lastChecked && (
                        <ListGroup.Item>
                            <strong>Last Checked:</strong> {formatDate(simulation.lastChecked)}
                        </ListGroup.Item>
                    )}
                </ListGroup>

                {simulation.triggeredRules && simulation.triggeredRules.length > 0 ? (
                    <>
                        <h5 className="mt-3">Triggered Rules:</h5>
                        <Accordion>
                            {simulation.triggeredRules.map((rule, index) => (
                                <Accordion.Item key={index} eventKey={index.toString()}>
                                    <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <span>{rule.ruleName}</span>
                                            <Badge bg={rule.severity === 'High' ? 'danger' : rule.severity === 'Medium' ? 'warning' : 'info'}>
                                                {rule.severity}
                                            </Badge>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Trigger Time:</strong> {formatDate(rule.triggerTime)}</p>
                                        <p><strong>Description:</strong> {rule.description}</p>
                                        {rule.entities && (
                                            <div>
                                                <strong>Entities:</strong>
                                                <pre>{JSON.stringify(rule.entities, null, 2)}</pre>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </>
                ) : (
                    <p className="mt-3">No rules were triggered during this simulation.</p>
                )}

                {simulation.error && <p className="text-danger mt-3">Error: {simulation.error}</p>}

                {simulation.status === 'In Progress' && (
                    <Button variant="danger" onClick={handleCancel} className="mt-2">
                        <FaStop /> Cancel Simulation
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};

export default SimulationFeedback;
