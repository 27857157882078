import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Spinner, Table, Badge, Alert, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FaInfoCircle, FaSearch, FaExclamationTriangle, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip as RechartsTooltip, Legend, PieChart, Pie, Cell, BarChart, Bar, CartesianGrid, XAxis, YAxis, LineChart, Line } from 'recharts';
import CustomTooltip from './CustomTooltip';
import './Reports.scss';
import attackMatrix from '../data/attackMatrix';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658'];

const Reports = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mitreData, setMitreData] = useState(null);
  const [logSourceData, setLogSourceData] = useState(null);
  const [ruleData, setRuleData] = useState([]);
  const [ingestionData, setIngestionData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showTechniqueModal, setShowTechniqueModal] = useState(false);
  const [selectedTechnique, setSelectedTechnique] = useState(null);
  const [coverage, setCoverage] = useState({});
  const [severityDistribution, setSeverityDistribution] = useState(null);
  const [agentHealthData, setAgentHealthData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');
        const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

        if (!integrationSettings) {
          throw new Error('Integration settings not found.');
        }

        const { tenantId, clientId, clientSecret, subscriptionId, resourceGroup, workspaceName, workspaceId } = integrationSettings;

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const [logSourceResponse, ruleResponse, ingestionResponse, agentHealthResponse] = await Promise.all([
          axios.post('/api/log_source_health', { tenantId, clientId, clientSecret, workspaceId }, { headers }),
          axios.post('/api/detection_rules', { tenantId, clientId, clientSecret, subscriptionId, resourceGroup, workspaceName }, { headers }),
          axios.post('/api/data_ingestion_metrics', { tenantId, clientId, clientSecret, workspaceId }, { headers }),
          axios.post('/api/agent_health', { tenantId, clientId, clientSecret, workspaceId }, { headers })
        ]);

        setLogSourceData(logSourceResponse.data);
        setRuleData(ruleResponse.data);
        setIngestionData(ingestionResponse.data);
        setAgentHealthData(agentHealthResponse.data);

        calculateCoverage(ruleResponse.data);
        calculateSeverityDistribution(ruleResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateCoverage = (rules) => {
    const coverageMap = {};
    if (rules) {
      rules.forEach(rule => {
        if (rule.techniques) {
          rule.techniques.forEach(technique => {
            coverageMap[technique] = true;
          });
        }
      });
    }
    setCoverage(coverageMap);
    const mitreCoverage = calculateMitreCoverage(coverageMap);
    setMitreData(mitreCoverage);
  };

  const calculateSeverityDistribution = (rules) => {
    const distribution = { High: 0, Medium: 0, Low: 0 };
    rules.forEach(rule => {
      if (rule.severity) {
        distribution[rule.severity] = (distribution[rule.severity] || 0) + 1;
      }
    });
    setSeverityDistribution(distribution);
  };

  const calculateMitreCoverage = (coverageMap) => {
    const totalTechniques = attackMatrix.reduce((acc, category) => acc + category.techniques.length, 0);
    let coveredTechniques = 0;
    const coverage = {};
    const categoryDetails = {};

    attackMatrix.forEach(category => {
      const coveredInCategory = category.techniques.filter(technique => coverageMap[technique.id]).length;
      coverage[category.category] = coveredInCategory;
      categoryDetails[category.category] = {
        covered: category.techniques.filter(technique => coverageMap[technique.id]),
        total: category.techniques.length
      };
      coveredTechniques += coveredInCategory;
    });

    Object.keys(categoryDetails).forEach(category => {
      categoryDetails[category].coveragePercentage = (categoryDetails[category].covered.length / categoryDetails[category].total * 100).toFixed(2);
    });

    const overallCoverage = (coveredTechniques / totalTechniques * 100).toFixed(2);

    return {
      overallCoverage,
      coveredTechniques,
      totalTechniques,
      coverage,
      categoryDetails
    };
  };

  const getCoverageColor = (percentage) => {
    if (percentage >= 70) return '#28a745';
    if (percentage >= 40) return '#ffc107';
    return '#dc3545';
  };
  const MitreRadarChart = useMemo(() => {
    if (!mitreData || !mitreData.categoryDetails) return null;

    const data = Object.keys(mitreData.categoryDetails).map(category => ({
      category,
      coverage: parseFloat(mitreData.categoryDetails[category].coveragePercentage)
    }));

    return (
      <ResponsiveContainer width="100%" height={500}>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid stroke="#ccc" />
          <PolarAngleAxis dataKey="category" stroke="#333" />
          <PolarRadiusAxis angle={30} domain={[0, 100]} stroke="#333" />
          <Radar name="Coverage" dataKey="coverage" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} onClick={(data) => handleCategoryClick(data.category)} />
          <RechartsTooltip content={({ payload }) => {
            if (payload && payload.length) {
              const technique = payload[0].payload;
              return (
                <div className="custom-tooltip">
                  <p style={{ color: 'black' }}>{technique.category}</p>
                  <p style={{ color: 'black' }}>Coverage: {technique.coverage}%</p>
                </div>
              );
            }
            return null;
          }} />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    );
  }, [mitreData]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShowCategoryModal(true);
  };

  const handleTechniqueClick = (technique) => {
    setSelectedTechnique(technique);
    setShowTechniqueModal(true);
  };

  const renderCategoryModal = () => {
    if (!selectedCategory || !mitreData) return null;

    const categoryData = mitreData.categoryDetails[selectedCategory];
    const categoryTechniques = attackMatrix.find(cat => cat.category === selectedCategory)?.techniques || [];
    const uncoveredTechniques = categoryTechniques.filter(tech => !categoryData.covered.some(coveredTech => coveredTech.id === tech.id));

    return (
      <Modal show={showCategoryModal} onHide={() => setShowCategoryModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedCategory} - Technique Coverage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Coverage: {categoryData.coveragePercentage}% ({categoryData.covered.length} / {categoryData.total})</p>
          <h5>Covered Techniques:</h5>
          <ul>
            {categoryData.covered.map(technique => (
              <li key={technique.id} onClick={() => handleTechniqueClick(technique)} style={{ cursor: 'pointer', color: '#007bff' }}>
                {technique.name} ({technique.id})
              </li>
            ))}
          </ul>
          <h5>Uncovered Techniques:</h5>
          <ul>
            {uncoveredTechniques.map(tech => (
              <li key={tech.id}>{tech.name} ({tech.id})</li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    );
  };

  const renderTechniqueModal = () => {
    if (!selectedTechnique) return null;

    return (
      <Modal show={showTechniqueModal} onHide={() => setShowTechniqueModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedTechnique.name} - Technique Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ID: {selectedTechnique.id}</p>
          <p>Description: {selectedTechnique.description}</p>
          <h5>Matching Rules:</h5>
          <ul>
            {ruleData
              .filter(rule => rule.techniques.includes(selectedTechnique.id))
              .map(rule => (
                <li key={rule.id}>
                  {rule.ruleName} - Severity: {rule.severity}
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    );
  };

  const LogSourceHealthChart = useMemo(() => {
    if (!logSourceData || !logSourceData.allTables) return null;

    const data = [
      { name: 'Healthy', value: logSourceData.healthySources },
      { name: 'Problematic', value: logSourceData.problematicSources }
    ];

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <RechartsTooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }, [logSourceData]);

  const RuleSeverityBarChart = useMemo(() => {
    if (!severityDistribution) return null;

    const data = Object.entries(severityDistribution).map(([name, value]) => ({ name, value }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <RechartsTooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" name="Rules" />
        </BarChart>
      </ResponsiveContainer>
    );
  }, [severityDistribution]);

  const DataIngestionChart = useMemo(() => {
    if (!ingestionData || !ingestionData.trend) return null;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={ingestionData.trend}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(tick) => new Date(tick).toLocaleDateString()} />
          <YAxis />
          <RechartsTooltip content={<CustomTooltip />} labelFormatter={(label) => new Date(label).toLocaleDateString()} />
          <Legend />
          <Line type="monotone" dataKey="volume" name="Volume (GB)" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }, [ingestionData]);

  const AgentHealthChart = useMemo(() => {
    if (!agentHealthData) return null;

    const statusCount = agentHealthData.reduce((acc, agent) => {
      acc[agent.HealthStatus] = (acc[agent.HealthStatus] || 0) + 1;
      return acc;
    }, {});

    const data = Object.entries(statusCount).map(([name, value]) => ({ name, value }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.name === 'Healthy' ? '#28a745' : entry.name === 'Warning' ? '#ffc107' : '#dc3545'} />
            ))}
          </Pie>
          <RechartsTooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }, [agentHealthData]);

  const renderTooltip = (content) => (
    <Tooltip id="button-tooltip">
      {content}
    </Tooltip>
  );

  if (loading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">Error: {error}</Alert>;
  }

  return (
    <Container fluid className="reports-dashboard">
      <h1 className="dashboard-title">Security Operations Dashboard</h1>
      
      <Row>
        <Col lg={6}>
          <Card className="dashboard-card">
            <Card.Header>
              MITRE ATT&CK Coverage
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This chart shows the distribution of MITRE ATT&CK techniques across different tactics that are covered by your SIEM rules. Click on a tactic for more details.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              {mitreData ? (
                <>
                  {MitreRadarChart}
                  <div className="coverage-stats">
                    <p>Overall Coverage: <span style={{color: getCoverageColor(mitreData.overallCoverage)}}>{mitreData.overallCoverage}%</span></p>
                    <p>Covered Techniques: {mitreData.coveredTechniques}</p>
                    <p>Total Techniques: {mitreData.totalTechniques}</p>
                  </div>
                </>
              ) : (
                <Alert variant="info">No MITRE ATT&CK data available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="dashboard-card">
            <Card.Header>
              Log Source Health
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This chart shows the distribution of healthy and problematic log sources.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              {logSourceData ? (
                <>
                  {LogSourceHealthChart}
                  <div className="log-source-stats">
                    <p>Healthy Sources: {logSourceData.healthySources}</p>
                    <p>Problematic Sources: {logSourceData.problematicSources}</p>
                  </div>
                </>
              ) : (
                <Alert variant="info">No log source health data available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row>
        <Col lg={6}>
          <Card className="dashboard-card">
            <Card.Header>
              Detection Rule Statistics
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This chart shows the distribution of detection rules by severity.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              {severityDistribution ? (
                <>
                  {RuleSeverityBarChart}
                  <div className="rule-stats">
                    <p>Total Active Rules: {ruleData.length}</p>
                  </div>
                </>
              ) : (
                <Alert variant="info">No detection rule statistics available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="dashboard-card">
            <Card.Header>
              Data Ingestion Metrics
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This chart shows the trend of data ingestion over time.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              {ingestionData ? (
                <>
                  {DataIngestionChart}
                  <div className="ingestion-stats">
                    <p>Last 24h Volume: {parseFloat(ingestionData.last24hVolume).toFixed(2)} GB</p>
                    <p>Average Daily Volume: {parseFloat(ingestionData.averageDailyVolume).toFixed(2)} GB</p>
                  </div>
                </>
              ) : (
                <Alert variant="info">No data ingestion metrics available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="dashboard-card">
            <Card.Header>
              Agent Health Status
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This chart shows the distribution of agent health statuses.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              {agentHealthData ? (
                <>
                  {AgentHealthChart}
                  <div className="agent-health-stats">
                    <p>Total Agents: {agentHealthData.length}</p>
                  </div>
                  <Table striped bordered hover responsive className="mt-4">
                    <thead>
                      <tr>
                        <th>Computer</th>
                        <th>OS</th>
                        <th>Last Heartbeat</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentHealthData.slice(0, 10).map((agent, index) => (
                        <tr key={index}>
                          <td>{agent.Computer}</td>
                          <td>{agent.OSName}</td>
                          <td>{new Date(agent.LastHeartbeat).toLocaleString()}</td>
                          <td>
                            <Badge bg={agent.HealthStatus === 'Healthy' ? 'success' : agent.HealthStatus === 'Warning' ? 'warning' : 'danger'}>
                              {agent.HealthStatus === 'Healthy' ? <FaCheckCircle /> : agent.HealthStatus === 'Warning' ? <FaExclamationCircle /> : <FaExclamationTriangle />}
                              {' '}
                              {agent.HealthStatus}
                            </Badge></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {agentHealthData.length > 10 && (
                    <div className="text-center mt-3">
                      <Button variant="primary">View All Agents</Button>
                    </div>
                  )}
                </>
              ) : (
                <Alert variant="info">No agent health data available</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="dashboard-card">
            <Card.Header>
              Log Sources
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip("This table shows all log sources and their current status.")}
              >
                <Button variant="link" className="info-button"><FaInfoCircle /></Button>
              </OverlayTrigger>
            </Card.Header>
            <Card.Body>
              <div className="search-bar">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search log sources..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              {logSourceData && logSourceData.allTables ? (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Table Name</th>
                      <th>Status</th>
                      <th>Last Log Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logSourceData.allTables
                      .filter(table => table.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((table, index) => (
                        <tr key={index}>
                          <td>{table.name}</td>
                          <td>
                            <Badge bg={table.status === 'Healthy' ? 'success' : 'danger'}>
                              {table.status === 'Healthy' ? <FaCheckCircle /> : <FaExclamationTriangle />}
                              {' '}
                              {table.status}
                            </Badge>
                          </td>
                          <td>{new Date(table.lastLogReceived).toLocaleString()}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <Alert variant="info">No log sources found</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {renderCategoryModal()}
      {renderTechniqueModal()}
    </Container>
  );
};

export default Reports;