import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { getSimulationsForRule, getSimulationStatus } from './services/simulationService';

const RuleContext = createContext();

export const RuleProvider = ({ children }) => {
  const [ruleStates, setRuleStates] = useState({});

  useEffect(() => {
    // Load saved states from local storage on initial render
    const savedStates = localStorage.getItem('ruleStates');
    if (savedStates) {
      try {
        setRuleStates(JSON.parse(savedStates));
      } catch (error) {
        console.error('Error parsing saved rule states:', error);
      }
    }
  }, []);

  useEffect(() => {
    // Save states to local storage whenever they change
    try {
      localStorage.setItem('ruleStates', JSON.stringify(ruleStates));
    } catch (error) {
      console.error('Error saving rule states to local storage:', error);
    }
  }, [ruleStates]);

  const updateRuleState = useCallback((ruleId, newState) => {
    setRuleStates(prevStates => ({
      ...prevStates,
      [ruleId]: { ...prevStates[ruleId], ...newState }
    }));
  }, []);

  const fetchSimulations = useCallback(async (ruleId) => {
    try {
      const fetchedSimulations = await getSimulationsForRule(ruleId);
      updateRuleState(ruleId, { simulations: fetchedSimulations });
      return fetchedSimulations;
    } catch (error) {
      console.error('Error fetching simulations:', error);
      return [];
    }
  }, [updateRuleState]);

  const refreshSimulation = useCallback(async (ruleId, simulationId) => {
    try {
      const status = await getSimulationStatus(simulationId);
      if (status) {
        setRuleStates(prevStates => ({
          ...prevStates,
          [ruleId]: {
            ...prevStates[ruleId],
            simulations: prevStates[ruleId]?.simulations?.map(sim =>
              sim.id === simulationId ? { ...sim, ...status } : sim
            ) || []
          }
        }));
      }
      return status;
    } catch (error) {
      console.error('Error refreshing simulation:', error);
      return null;
    }
  }, []);

  const addSimulation = useCallback((ruleId, simulation) => {
    setRuleStates(prevStates => ({
      ...prevStates,
      [ruleId]: {
        ...prevStates[ruleId],
        simulations: [...(prevStates[ruleId]?.simulations || []), simulation]
      }
    }));
  }, []);

  const value = {
    ruleStates,
    updateRuleState,
    fetchSimulations,
    refreshSimulation,
    addSimulation
  };

  return (
    <RuleContext.Provider value={value}>
      {children}
    </RuleContext.Provider>
  );
};

export const useRuleContext = () => {
  const context = useContext(RuleContext);
  if (!context) {
    throw new Error('useRuleContext must be used within a RuleProvider');
  }
  return context;
};