import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Alert, Spinner, Button, Modal, Form } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaChartBar, FaCalendarAlt } from 'react-icons/fa';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import './LogVision.scss';

const LogVision = () => {
    const [logSources, setLogSources] = useState([]);
    const [detectionRules, setDetectionRules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [costPerGBPerMonth] = useState(2.30); // Set the cost per GB per month
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const [logSourcesResponse, rulesResponse] = await Promise.all([
                axios.get('/api/log-vision/log-sources', {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
                }),
                axios.get('/api/log-vision/detection-rules', { headers: { Authorization: `Bearer ${token}` } })
            ]);

            setLogSources(logSourcesResponse.data);
            setDetectionRules(rulesResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const getUsedTables = () => {
        const usedTables = new Set();
        detectionRules.forEach(rule => {
            rule.tables.forEach(table => usedTables.add(table));
        });
        return usedTables;
    };

    const unusedLogSources = logSources.filter(source => !getUsedTables().has(source.name));

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const calculateCostSavings = (bytes) => {
        const gbSize = bytes / (1024 * 1024 * 1024);
        return gbSize * costPerGBPerMonth;
    };

    const totalPotentialSavings = unusedLogSources.reduce((acc, source) => acc + calculateCostSavings(source.size), 0);

    const pieChartData = [
        { name: 'Used Sources', value: logSources.length - unusedLogSources.length },
        { name: 'Unused Sources', value: unusedLogSources.length }
    ];

    const COLORS = ['#0088FE', '#FF8042'];

    const barChartData = unusedLogSources
        .sort((a, b) => b.size - a.size)
        .slice(0, 10)
        .map(source => ({
            name: source.name,
            size: source.size / (1024 * 1024 * 1024) // Convert to GB
        }));

    const handleSourceClick = (source) => {
        setSelectedSource(source);
        setShowDetailModal(true);
    };

    const renderRecommendations = () => {
        const totalUnused = unusedLogSources.reduce((acc, source) => acc + source.size, 0);
        const percentUnused = (totalUnused / logSources.reduce((acc, source) => acc + source.size, 0)) * 100;

        if (percentUnused > 30) {
            return (
                <Alert variant="warning">
                    <FaInfoCircle /> Over 30% of your log data is unused. Consider reviewing and optimizing your log collection strategy.
                </Alert>
            );
        } else if (unusedLogSources.length > 0) {
            return (
                <Alert variant="info">
                    <FaInfoCircle /> You have some unused log sources. Review them to potentially reduce costs.
                </Alert>
            );
        } else {
            return (
                <Alert variant="success">
                    <FaCheckCircle /> Great job! All your log sources are being utilized.
                </Alert>
            );
        }
    };

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner animation="border" variant="primary" />
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-4">
                <Alert variant="danger">{error}</Alert>
            </Container>
        );
    }

    return (
        <Container fluid className="log-vision">
            <h1 className="page-title">Log Vision</h1>
            
            <Row className="mb-4 date-range-selector">
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                    <h5><FaCalendarAlt className="me-2" /> Select Date Range</h5>
                </Col>
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                    <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                    <Form.Group>
                        <Form.Label>End Date</Form.Label>
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            className="form-control"
                        />
                    </Form.Group>
                </Col>
            </Row>
    
            <Card className="mb-4 summary-card">
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <h3>Total Log Sources: {logSources.length}</h3>
                        </Col>
                        <Col md={6}>
                            <h3>Unused Log Sources: {unusedLogSources.length}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
    
            <Row>
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Header>
                            <h2>Log Source Usage Overview</h2>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={pieChartData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {pieChartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Header>
                            <h2>Top 10 Unused Log Sources by Size</h2>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={barChartData}>
                                    <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} />
                                    <YAxis label={{ value: 'Size (GB)', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <Bar dataKey="size" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Header>
                            <h2>Unused Log Sources Dashboard</h2>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Log Source</th>
                                        <th>Status</th>
                                        <th>Data Volume</th>
                                        <th>Potential Monthly Savings</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unusedLogSources.map((source, index) => (
                                        <tr key={index}>
                                            <td>{source.name}</td>
                                            <td>
                                                <span className="text-danger">
                                                    <FaTimesCircle /> Unused
                                                </span>
                                            </td>
                                            <td>{formatBytes(source.size)}</td>
                                            <td>${calculateCostSavings(source.size).toFixed(2)}/month</td>
                                            <td>
                                                <Button variant="info" size="sm" onClick={() => handleSourceClick(source)}>
                                                    <FaInfoCircle /> Details
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {unusedLogSources.length === 0 && (
                                <Alert variant="success">
                                    All log sources are currently being used in detection rules.
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Header>
                            <h2>Cost Saving Summary</h2>
                        </Card.Header>
                        <Card.Body>
                            <p>Total Potential Monthly Savings: ${totalPotentialSavings.toFixed(2)}</p>
                            <p>Total Potential Annual Savings: ${(totalPotentialSavings * 12).toFixed(2)}</p>
                            <p>Note: These calculations are based on:</p>
                            <ul>
                                <li>An estimated cost of ${costPerGBPerMonth.toFixed(2)} per GB per month.</li>
                                <li>Data volume from {startDate.toLocaleDateString()} to {endDate.toLocaleDateString()}.</li>
                            </ul>
                            <p>Actual savings may vary based on long-term usage patterns and your specific Azure pricing.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    
            <Row>
                <Col>
                    <Card className="mb-4">
                        <Card.Header>
                            <h2>Recommendations</h2>
                        </Card.Header>
                        <Card.Body>
                            {renderRecommendations()}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
    
            <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Log Source Details: {selectedSource?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedSource && (
                        <>
                            <p><strong>Size:</strong> {formatBytes(selectedSource.size)}</p>
                            <p><strong>Potential Monthly Savings:</strong> ${calculateCostSavings(selectedSource.size).toFixed(2)}</p>
                            <h4>Recommendations:</h4>
                            <ul>
                                <li>Review the need for this log source in your security operations.</li>
                                <li>If not needed, consider disabling data collection for this source.</li>
                                <li>If needed, create detection rules that utilize this data to improve your security posture.</li>
                            </ul>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default LogVision;