// src/components/Settings.js

import React from 'react';
import './Settings.scss';

const Settings = ({ onClose }) => {
    return (
        <div className="settings-menu">
            <div className="settings-header">
                <h2>Settings</h2>
                <button onClick={onClose} className="close-button">X</button>
            </div>
            <div className="settings-content">
                <p>This is a placeholder for the settings menu.</p>
                {/* Add your settings options here */}
            </div>
        </div>
    );
};

export default Settings;
