import React, { useEffect, useState, useMemo, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import { Card, Container, Row, Col, Alert, Spinner, Modal, Button } from 'react-bootstrap';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { FaCheckCircle, FaExclamationCircle, FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import './Overview.scss';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LazyChart = lazy(() => import('./ChartComponent'));

const TableCard = React.memo(({ table, onClick }) => (
  <Card className="table-card" onClick={() => onClick(table[0])}>
    <Card.Body>
      <Card.Title>{table[0]}</Card.Title>
      <Card.Text>Log Source</Card.Text>
    </Card.Body>
  </Card>
));

const Overview = () => {
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedTable, setSelectedTable] = useState(null);
    const [timechartData, setTimechartData] = useState([]);
    const [timechartLoading, setTimechartLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTables, setFilteredTables] = useState([]);

    const fetchClientTables = useCallback(async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));
    
            if (!integrationSettings) {
                setError('Integration settings not found. Please configure your integration first.');
                setLoading(false);
                return;
            }
    
            const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;
    
            const response = await axios.post('/api/client_tables', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                setTables(response.data);
                setFilteredTables(response.data);
            } else {
                setError('Failed to fetch client tables');
            }
        } catch (error) {
            console.error('Error fetching client tables:', error);
            setError('An error occurred while fetching client tables. Please check your integration settings.');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchClientTables();
    }, [fetchClientTables]);

    const handleTableClick = async (table) => {
        setSelectedTable(table);
        setShowModal(true);
        setTimechartLoading(true);

        try {
            const token = localStorage.getItem('token');
            const integrationSettings = JSON.parse(localStorage.getItem('integrationSettings'));

            if (!integrationSettings) {
                throw new Error('Integration settings not found.');
            }

            const { tenantId, clientId, clientSecret, workspaceId } = integrationSettings;

            const timechartResponse = await axios.post('/api/table_timechart', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId,
                tableName: table
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (timechartResponse.status === 200) {
                setTimechartData(timechartResponse.data);
            } else {
                setError('Failed to fetch time chart data');
            }

            const statusResponse = await axios.post('/api/table_status', {
                tenantId,
                clientId,
                clientSecret,
                workspaceId,
                tableName: table
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (statusResponse.status === 200) {
                setStatus(statusResponse.data.status);
            } else {
                setError('Failed to fetch table status');
            }

        } catch (error) {
            console.error('Error fetching time chart or status data:', error);
            setError('An error occurred while fetching time chart or status data');
        } finally {
            setTimechartLoading(false);
        }
    };

    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm) => {
                const filtered = tables.filter(table => 
                    table[0].toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredTables(filtered);
            }, 300),
        [tables]
    );

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        debouncedSearch(e.target.value);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTable(null);
        setTimechartData([]);
        setStatus('');
    };

    const timechartOptions = useMemo(() => ({
        responsive: true,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'MMM d, HH:mm'
                    }
                },
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Log Count',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Log Count Over Time',
            },
        },
    }), []);

    const timechartDataset = useMemo(() => ({
        datasets: [
            {
                label: 'Log Count',
                data: timechartData.map(row => ({ x: new Date(row[0]), y: row[1] })),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    }), [timechartData]);

    return (
        <Container fluid className="overview-page">
            <header className="inventory-header">
                <div className="header-content">
                    <h1>Overview</h1>
                    <p>View and manage your log sources</p>
                    <div className="search-bar">
                        <FaSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search log sources..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <p>Total Log Sources: {filteredTables.length}</p>
                </div>
            </header>
            <div className="inventory-content">
                <Row className="inventory-grid">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : error ? (
                        <Alert variant="danger">{error}</Alert>
                    ) : (
                        filteredTables.map((table, index) => (
                            <Col key={index} md={4} className="mb-4">
                                <TableCard table={table} onClick={handleTableClick} />
                            </Col>
                        ))
                    )}
                </Row>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedTable}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {timechartLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <>
                            <div className="status-indicator mb-4">
                                {status === 'good' ? (
                                    <div className="d-flex align-items-center text-success">
                                        <FaCheckCircle size={24} className="me-2" />
                                        Logs received in the past 2 hours
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center text-danger">
                                        <FaExclamationCircle size={24} className="me-2" />
                                        No logs received in the past 2 hours
                                    </div>
                                )}
                            </div>
                            <Suspense fallback={<div>Loading chart...</div>}>
                                <LazyChart key={selectedTable} data={timechartDataset} options={timechartOptions} />
                            </Suspense>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Overview;
