// src/data/attackMatrix.js

const attackMatrix = [
    {
        category: 'Reconnaissance',
        techniques: [
            { 
                id: 'T1595', 
                name: 'Active Scanning', 
                description: "Adversaries may execute active reconnaissance scans to gather information that can be used during targeting. Active scans are those where the adversary probes victim infrastructure via network traffic, as opposed to other forms of reconnaissance that do not involve direct interaction.",
                subTechniques: ['Scanning IP Blocks', 'Vulnerability Scanning', 'Wordlist Scanning'] 
            },
            { 
                id: 'T1592', 
                name: 'Gather Victim Host Information', 
                description: "Adversaries may gather detailed information about a victim’s hosts to aid in targeting. This information can include administrative data such as host names, assigned IP addresses, and functionalities, as well as configuration specifics like the operating system and language settings.",
                subTechniques: ['Hardware', 'Software', 'Firmware', 'Client Configurations'] 
            },
            { 
                id: 'T1589', 
                name: 'Gather Victim Identity Information', 
                description: "Adversaries may collect identity information about a victim, which includes personal details like names, email addresses, usernames, and passwords. This information can be used for further targeting, social engineering, and gaining unauthorized access to systems and networks.",
                subTechniques: ['Credentials', 'Email Addresses', 'Employee Names'] 
            },
            { 
                id: 'T1590',
                name: 'Gather Victim Network Information', 
                description: "Adversaries may collect detailed information about a victim’s network, including IP addresses, subnet configurations, network architecture, and active services. This information can be used to map the network, identify potential entry points, and plan subsequent stages of an attack.",
                subTechniques: ['Domain Properties', 'DNS', 'Network Trust Dependencies', 'Network Topology', 'IP Addresses', 'Network Security Appliances'] 
            },
            { 
                id: 'T1591', 
                name: 'Gather Victim Org Information', 
                description: "Adversaries may gather information about the victim's organization that can be used during targeting. Information about an organization may include a variety of details, including the names of divisions/departments, specifics of business operations, as well as the roles and responsibilities of key employees.",
                subTechniques: ['Determine Physical Locations', 'Business Relationships', 'Identify Business Tempo', 'Identify Roles'] 
            },
            { 
                id: 'T1598', 
                name: 'Phishing for Information', 
                description: "Adversaries may use phishing techniques to deceive victims into disclosing sensitive information. This can involve sending fraudulent emails or messages that appear to be from trusted sources, tricking recipients into revealing personal data, credentials, or other confidential information.",
                subTechniques: ['Spearphishing Service', 'Spearphishing Attachment', 'Spearphishing Link', 'Spearphishing Voice'] 
            },
            { 
                id: 'T1597', 
                name: 'Search Closed Sources', 
                description: "Adversaries may search closed sources, such as private databases, restricted forums, or internal systems, to gather information about the victim. This can provide access to confidential data, proprietary information, and other sensitive details that are not publicly available.",
                subTechniques: ['Threat Intel Vendors', 'Purchase Technical Data']
            },
            { 
                id: 'T1596', 
                name: 'Search Open Technical Databases', 
                description: "Adversaries may search open technical databases, such as public code repositories, vulnerability databases, and technical forums, to gather information about the victim’s technology stack, potential vulnerabilities, and other technical details. This information can be used to plan and execute targeted attacks.",
                subTechniques: ['DNS/Passive DNS', 'WHOIS', 'Digital Certificates', 'CDNs', 'Scan Databases'] 
            },
            { 
                id: 'T1593', 
                name: 'Search Open Websites/Domains', 
                description: "Adversaries may search open websites and domains, including corporate websites, blogs, and forums, to gather information about the victim. This can include organizational details, technology usage, contact information, and other data that can aid in targeting and planning attacks.",
                subTechniques: ['Social Media', 'Search Engines', 'Code Repositories'] 
            },
            { 
                id: 'T1594', 
                name: 'Search Victim-Owned Websites', 
                description: "Adversaries may search victim-owned websites to collect information about the target organization. This can include gathering details about the organization’s structure, key personnel, technology stack, and other publicly available data that can be used to tailor and execute targeted attacks.",
                subTechniques: [] 
            }
        ]
    },
    {
        category: 'Resource Development',
        techniques: [
            { 
                id: 'T1650', 
                name: 'Acquire Access', 
                description: "Adversaries may obtain access to a target system, network, or account through various means, including purchasing credentials, exploiting vulnerabilities, or leveraging insider threats. This initial access allows adversaries to establish a foothold within the victim’s environment, enabling further malicious activities.",
                subTechniques: [] 
            },
            { 
                id: 'T1583', 
                name: 'Acquire Infrastructure', 
                description: "Adversaries may obtain infrastructure such as servers, domains, or hosting services to support their operations. This infrastructure can be used for command and control, hosting malicious content, or staging attacks, allowing adversaries to operate with greater anonymity and persistence.",
                subTechniques: ['Domains', 'DNS Server', 'Virtual Private Server', 'Server', 'Botnet', 'Web Services', 'Serverless', 'Malvertising'] 
            },
            { 
                id: 'T1586', 
                name: 'Compromise Accounts', 
                description: "Adversaries may gain unauthorized access to user accounts through various methods, such as credential theft, brute-force attacks, or exploiting security vulnerabilities. Compromised accounts can be used to escalate privileges, exfiltrate data, and further infiltrate the victim’s network.",
                subTechniques: ['Social Media Accounts', 'Email Accounts', 'Cloud Accounts'] 
            },
            { 
                id: 'T1584', 
                name: 'Compromise Infrastructure', 
                description: "Adversaries may compromise existing infrastructure, such as servers, routers, or other network components, by exploiting vulnerabilities or misconfigurations. Gaining control over these elements allows adversaries to intercept communications, deploy malicious payloads, and maintain persistence within the victim’s environment.",
                subTechniques: ['Domains', 'DNS Server', 'Virtual Private Server', 'Server', 'Botnet', 'Web Services', 'Serverless', 'Network Devices'] 
            },
            { 
                id: 'T1587', 
                name: 'Develop Capabilities', 
                description: "Adversaries may create or enhance tools, techniques, and procedures to support their operations. This can include developing custom malware, exploit scripts, or other resources that enable them to carry out attacks more effectively and evade detection.",
                subTechniques: ['Malware', 'Code Signing Certificates', 'Digital Certificates', 'Exploits'] 
            },
            { 
                id: 'T1585', 
                name: 'Establish Accounts', 
                description: "Adversaries may create new user accounts within the victim’s environment to facilitate ongoing access and operations. These accounts can be used to perform malicious activities while blending in with legitimate user activity, making detection more challenging.",
                subTechniques: ['Social Media Accounts', 'Email Accounts', 'Cloud Accounts'] 
            },
            { 
                id: 'T1588', 
                name: 'Obtain Capabilities', 
                description: "Adversaries may acquire the tools, knowledge, and resources needed to execute attacks. This can involve purchasing malware, exploits, or other tools from underground markets, or obtaining information and techniques from publicly available sources to enhance their attack capabilities.",
                subTechniques: ['Malware', 'Tool', 'Code Signing Certificates', 'Digital Certificates', 'Exploits', 'Vulnerabilities', 'Artificial Intelligence'] 
            },
            { 
                id: 'T1608', 
                name: 'Stage Capabilities', 
                description: "Adversaries may prepare and deploy the necessary tools and resources in strategic locations to support their operations. This staging process involves placing malware, exploits, and other tools on compromised systems or within controlled infrastructure, ensuring they are ready for use during an attack.",
                subTechniques: ['Upload Malware', 'Upload Tool', 'Install Digital Certificate', 'Drive-by Target', 'Link Target', 'SEO Poisoning'] 
            },
        ]
    },
    {
        category: 'Initial Access',
        techniques: [
            { 
                id: 'T1659', 
                name: 'Content Injection', 
                description: "Adversaries may inject malicious content into legitimate websites or applications to deliver harmful payloads to unsuspecting users. This technique can be used to exploit vulnerabilities, steal information, or further compromise the victim’s systems by tricking users into interacting with the injected content.",
                subTechniques: [] 
            },
            { 
                id: 'T1189', 
                name: 'Drive-by Compromise', 
                description: "Adversaries may exploit vulnerabilities in web browsers or plugins to deliver malicious payloads when a user visits a compromised or malicious website. This technique, known as a drive-by compromise, allows adversaries to gain access to the victim’s system without requiring user interaction beyond visiting the site.",
                subTechniques: [] },
            { 
                id: 'T1190', 
                name: 'Exploit Public-Facing Application', 
                description: "Adversaries may target vulnerabilities in public-facing applications to gain unauthorized access to a victim’s network. By exploiting these vulnerabilities, attackers can execute arbitrary code, gain control of the application, and move laterally within the network to further their objectives.",
                subTechniques: [] 
            },
            { 
                id: 'T1133', 
                name: 'External Remote Services', 
                description: "Adversaries may exploit external remote services, such as VPNs, RDP, or other remote access tools, to gain unauthorized access to a victim’s network. By compromising these services, attackers can establish a foothold within the network, allowing them to conduct further malicious activities and maintain persistent access.",
                subTechniques: [] 
            },
            { 
                id: 'T1200', 
                name: 'Hardware Additions', 
                description: "Adversaries may introduce unauthorized hardware devices into a victim’s environment to facilitate malicious activities. These devices can include rogue access points, USB drives, or other hardware implants designed to capture data, exfiltrate information, or provide remote access to the attacker.",
                subTechniques: [] 
            },
            { 
                id: 'T1566', 
                name: 'Phishing', 
                description: "Adversaries may use phishing attacks to trick victims into revealing sensitive information, such as credentials or personal data. This is typically done through deceptive emails, messages, or websites that appear to be from trusted sources, leading victims to unknowingly provide valuable information or download malicious content.",
                subTechniques: ['Spearphishing Attachment', 'Spearphishing Link', 'Spearphishing via Service', 'Spearphishing Voice'] 
            },
            { 
                id: 'T1072', 
                name: 'Software Deployment Tools', 
                description: "Adversaries may abuse software deployment tools to distribute and execute malicious software across a network. These tools, designed for legitimate software updates and installations, can be exploited to deploy malware, execute scripts, or install backdoors, facilitating widespread compromise and persistence within the target environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1195', 
                name: 'Supply Chain Compromise', 
                description: "Adversaries may infiltrate or manipulate a trusted third-party vendor or service provider to compromise a victim’s network. By targeting the supply chain, attackers can introduce malicious software or hardware, or exploit existing access to gain entry into the victim’s environment, bypassing traditional security measures.",
                subTechniques: ['Compromise Software Dependencies and Development Tools', 'Compromise Software Supply Chain', 'Compromise Hardware Supply Chain'] 
            },
            { 
                id: 'T1199', 
                name: 'Trusted Relationship', 
                description: "Adversaries may exploit trusted relationships between organizations to gain access to a target’s network. By compromising a partner, vendor, or other third party with privileged access, attackers can bypass security controls and leverage the existing trust to infiltrate the victim’s environment and conduct malicious activities.",
                subTechniques: [] 
            },
            { 
                id: 'T1078', 
                name: 'Valid Accounts', 
                description: "Adversaries may use stolen or otherwise obtained valid accounts to access a victim’s network. These accounts allow attackers to bypass security measures and appear as legitimate users, facilitating further infiltration, data exfiltration, and other malicious activities while evading detection.",
                subTechniques: ['Default Accounts', 'Domain Accounts', 'Local Accounts', 'Cloud Accounts'] 
            }
        ]
    },
    {
        category: 'Execution',
        techniques: [
            { 
                id: 'T1651', 
                name: 'Cloud Administration Command', 
                description: "Adversaries may exploit cloud administration commands to control cloud resources within a victim’s environment. By using legitimate cloud management tools and APIs, attackers can manipulate cloud infrastructure, create or modify services, and exfiltrate data while blending in with normal administrative activities.",
                subTechniques: [] 
            },
            { 
                id: 'T1059', 
                name: 'Command and Scripting Interpreter', 
                description: "Adversaries may abuse command and scripting interpreters, such as PowerShell, Python, or Bash, to execute malicious code on victim systems. These interpreters allow attackers to automate tasks, manipulate system settings, and execute arbitrary commands, facilitating further compromise and persistence within the network.",
                subTechniques: ['PowerShell', 'AppleScript', 'Windows Command Shell', 'Unix Shell', 'Visual Basic', 'Python', 'JavaScript', 'Network Device CLI', 'Cloud API', 'AutoHotKey & AutoIT'] 
            },
            { 
                id: 'T1609', 
                name: 'Container Administration Command', 
                description: "Adversaries may exploit container administration commands to control containerized environments within a victim’s infrastructure. By leveraging tools and commands used for container management, attackers can manipulate containers, deploy malicious images, and gain access to sensitive data or services running within the container ecosystem.",
                subTechniques: [] },
            { 
                id: 'T1610', 
                name: 'Deploy Container', 
                description: "Adversaries may deploy malicious containers within a victim’s environment to establish a foothold, persist, or execute malicious operations. By leveraging containerization platforms, attackers can create, manage, and deploy containers that include backdoors, exploits, or other malicious payloads, allowing them to infiltrate and manipulate the target’s infrastructure.",
                subTechniques: [] 
            },
            { 
                id: 'T1203', 
                name: 'Exploitation for Client Execution', 
                description: "Adversaries may exploit vulnerabilities in client applications to execute arbitrary code on a victim’s system. This can occur through malicious files, documents, or web content that triggers vulnerabilities within software like browsers, email clients, or office suites, allowing attackers to run malicious code and gain control over the victim’s device.",
                subTechniques: [] },
            { 
                id: 'T1559', 
                name: 'Inter-Process Communication', 
                description: "Adversaries may exploit inter-process communication (IPC) mechanisms to interact with and control other processes on a victim’s system. By leveraging IPC, attackers can manipulate process behavior, escalate privileges, and facilitate the execution of malicious code across different system components, enhancing their control over the compromised environment.",
                subTechniques: ['Component Object Model', 'Dynamic Data Exchange', 'XPC Services'] 
            },
            { 
                id: 'T1106', 
                name: 'Native API', 
                description: "Adversaries may abuse native APIs to interact with the operating system and execute malicious actions. Using native API calls, attackers can manipulate system resources, access sensitive information, and perform tasks such as creating processes, modifying files, and interacting with hardware, often bypassing security controls and detection mechanisms.",
                subTechniques: [] 
            },
            { 
                id: 'T1053', 
                name: 'Scheduled Task/Job', 
                description: "Adversaries may abuse scheduled tasks or jobs to execute malicious code at specified times or intervals. By creating or modifying scheduled tasks, attackers can achieve persistence, automate the execution of malware, and perform actions without immediate user interaction, allowing them to maintain control over the compromised system.",
                subTechniques: ['At', 'Cron', 'Scheduled Task', 'Systemd Timers', 'Container Orchestration Job'] 
            },
            { 
                id: 'T1648', 
                name: 'Serverless Execution', 
                description: "Adversaries may exploit serverless computing services, such as AWS Lambda or Azure Functions, to execute malicious code. By leveraging these cloud-native execution environments, attackers can run their code without managing underlying infrastructure, potentially evading traditional detection mechanisms and achieving scalable, on-demand execution of their payloads.",
                subTechniques: [] 
            },
            { 
                id: 'T1129', 
                name: 'Shared Modules', 
                description: "Adversaries may utilize shared modules, such as dynamic link libraries (DLLs) or shared objects, to execute malicious code within the context of another process. By injecting or loading malicious shared modules into legitimate processes, attackers can evade detection, escalate privileges, and maintain persistence on the victim’s system.",
                subTechniques: [] 
            },
            { 
                id: 'T1072', 
                name: 'Software Deployment Tools', 
                description: "Adversaries may abuse software deployment tools to distribute and execute malicious software across a network. These tools, designed for legitimate software updates and installations, can be exploited to deploy malware, execute scripts, or install backdoors, facilitating widespread compromise and persistence within the target environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1569', 
                name: 'System Services',
                description: "Adversaries may abuse system services to execute malicious code, maintain persistence, or perform other unauthorized activities. By manipulating system services, such as creating, modifying, or exploiting existing services, attackers can run their payloads with elevated privileges, ensuring continued access and control over the victim’s system.", 
                subTechniques: ['Launchctl', 'Service Execution'] 
            },
            { 
                id: 'T1204', 
                name: 'User Execution',
                description: "Adversaries may rely on user interaction to execute malicious code. This technique involves tricking users into launching infected files, clicking on malicious links, or performing actions that inadvertently execute the attacker’s payload. Successful user execution can lead to the initial compromise and further infiltration of the victim’s system.", 
                subTechniques: ['Malicious Link', 'Malicious File', 'Malicious Image'] 
            },
            { 
                id: 'T1047', 
                name: 'Windows Management Instrumentation', 
                description: "teAdversaries may abuse Windows Management Instrumentation (WMI) to execute malicious code, gather information, or automate tasks on Windows systems. WMI provides powerful capabilities for managing and monitoring Windows environments, allowing attackers to stealthily execute commands, launch scripts, and manipulate system configurations to achieve their objectives.xt",
                subTechniques: [] 
            },
        ]
    },
    {
        category: 'Persistence',
        techniques: [
            { 
                id: 'T1098', 
                name: 'Account Manipulation', 
                description: "Adversaries may manipulate user accounts to maintain access and evade detection. This can involve actions such as creating new accounts, modifying existing account permissions, or resetting passwords. By altering account settings, attackers can ensure continued access to the compromised environment and perform malicious activities under the guise of legitimate users.", 
                subTechniques: ['Additional Cloud Credentials', 'Additional Email Delegate Permissions', 'Additional Cloud Roles', 'SSH Authorized Keys', 'Device Registration', 'Additional Container Cluster Roles'] 
            },
            { 
                id: 'T1197', 
                name: 'BITS Jobs', 
                description: "Adversaries may abuse Background Intelligent Transfer Service (BITS) jobs to maintain persistence on a victim’s system. BITS is a Windows service used for asynchronous file transfers between machines. By creating or manipulating BITS jobs, attackers can schedule tasks to download or upload files, execute payloads, and maintain a presence on the system without triggering typical detection mechanisms.",
                subTechniques: [] 
            },
            { 
                id: 'T1547', 
                name: 'Boot or Logon Autostart Execution', 
                description: "Adversaries may configure executables to run automatically upon system boot or user logon to maintain persistence. This ensures that malicious code is executed each time the system starts or the user logs in. Techniques include modifying registry keys, adding programs to startup folders, or configuring scheduled tasks, allowing attackers to maintain a foothold on the system across reboots and logons.",
                subTechniques: ['Registry Run Keys / Startup Folder', 'Authentication Package', 'Time Providers', 'Winlogon Helper DLL', 'Security Support Provider', 'Kernel Modules and Extensions', 'Re-opened Applications', 'LSASS Driver', 'Shortcut Modification', 'Port Monitors', 'Print Processors', 'XDG Autostart Entries', 'Active Setup', 'Login Items'] 
            },
            { 
                id: 'T1037', 
                name: 'Boot or Logon Initialization Scripts', 
                description: "Adversaries may use scripts that run during the boot or logon process to maintain persistence on a victim’s system. These scripts can be placed in various locations, such as startup folders, logon scripts, or registry keys, ensuring they execute automatically when the system boots or a user logs in. This allows attackers to repeatedly run malicious code and maintain their foothold within the environment.",
                subTechniques: ['Logon Script (Windows)', 'Login Hook', 'Network Logon Script', 'RC Scripts', 'Startup Items'] 
            },
            { 
                id: 'T1176', 
                name: 'Browser Extensions', 
                description: "Adversaries may create or manipulate browser extensions to maintain persistence within a victim’s environment. These extensions can be used to execute malicious code, steal information, or manipulate browser behavior. By leveraging browser extensions, attackers can ensure their malicious activities persist across browser sessions and potentially gain access to sensitive data within the victim’s web applications.",
                subTechniques: [] 
            },
            { 
                id: 'T1554', 
                name: 'Compromise Host Software Binary', 
                description: "Adversaries may compromise host software binaries to maintain persistence and execute malicious code. By modifying legitimate software binaries on a victim’s system, attackers can insert malicious payloads that run whenever the compromised software is executed. This technique allows adversaries to blend in with normal system operations and evade detection while maintaining control over the compromised environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1136', 
                name: 'Create Account', 
                description: "Adversaries may create new user accounts to establish persistence within a victim’s environment. These accounts can be used to access systems or networks at a later time, often with elevated privileges. By creating accounts, attackers can ensure long-term access without relying on external exploits, making it easier to blend in with legitimate user activity and evade detection.",
                subTechniques: ['Local Account', 'Domain Account', 'Cloud Account'] 
            },
            {
                id: 'T1543', 
                name: 'Create or Modify System Process', 
                description: "Adversaries may create or modify system processes to maintain persistence and execute malicious activities. This can involve creating new services, modifying existing ones, or changing how system processes operate to ensure that malicious code is executed with elevated privileges. By manipulating system processes, attackers can maintain control over the compromised environment and evade detection.",
                subTechniques: ['Launch Agent', 'Systemd Service', 'Windows Service', 'Launch Daemon', 'Container Service'] 
            },
            { 
                id: 'T1546', 
                name: 'Event Triggered Execution', 
                description: "Adversaries may use event triggers to execute malicious code in response to specific system or user events. This can include creating or modifying scheduled tasks, using system-level event triggers, or exploiting application-specific events to run malicious payloads automatically when certain conditions are met. By leveraging event-triggered execution, attackers can maintain persistence and perform actions stealthily, aligning their activities with legitimate system operations.",
                subTechniques: ['Change Default File Association', 'Screensaver', 'Windows Management Instrumentation Event Subscription', 'Unix Shell Configuration Modification', 'Trap', 'LC_LOAD_DYLIB Addition', 'Netsh Helper DLL', 'Accessibility Features', 'AppCert DLLs', 'AppInit DLLs', 'Application Shimming', 'Image File Execution Options Injection', 'PowerShell Profile', 'Emond', 'Component Object Model Hijacking', 'Installer Packages'] 
            },
            { 
                id: 'T1133', 
                name: 'External Remote Services',
                description: "Adversaries may exploit external remote services, such as VPNs, RDP, or other remote access tools, to gain unauthorized access to a victim’s network. By compromising these services, attackers can establish a foothold within the network, enabling them to conduct further malicious activities and maintain persistent access, often blending in with legitimate remote connections.",
                subTechniques: [] 
            },
            { 
                id: 'T1574', 
                name: 'Hijack Execution Flow', 
                description: "Adversaries may hijack the execution flow of a system or application to execute malicious code. This can involve redirecting the execution of code to malicious payloads through methods such as DLL injection, function hooking, or modifying pointers in memory. By hijacking execution flow, attackers can gain control over the compromised system, execute arbitrary code, and maintain persistence while evading detection.",
                subTechniques: ['DLL Search Order Hijacking', 'DLL Side-Loading', 'Dylib Hijacking', 'Executable Installer File Permissions Weakness', 'Dynamic Linker Hijacking', 'Path Interception by PATH Environment Variable', 'Path Interception by Search Order Hijacking', 'Path Interception by Unquoted Path', 'Services File Permissions Weakness', 'Services Registry Permissions Weakness', 'COR_PROFILER', 'KernelCallbackTable', 'AppDomainManager'] 
            },
            { 
                id: 'T1525', 
                name: 'Implant Internal Image', 
                description: "Adversaries may implant malicious images within a victim’s environment to establish persistence. This can involve modifying system images, such as operating system images or firmware, to include malicious code. When these images are deployed or updated, the embedded malicious code is executed, allowing attackers to maintain control over the system and evade detection through standard updates or installations.",
                subTechniques: [] 
            },
            { 
                id: 'T1556', 
                name: 'Modify Authentication Process', 
                description: "Adversaries may modify the authentication process to maintain persistence and control over a victim’s system or network. This can involve altering authentication mechanisms, such as modifying login scripts, manipulating single sign-on (SSO) configurations, or exploiting authentication tokens. By tampering with the authentication process, attackers can create backdoors, bypass security measures, and ensure continued access to the compromised environment.",
                subTechniques: ['Domain Controller Authentication', 'Password Filter DLL', 'Pluggable Authentication Modules', 'Network Device Authentication', 'Reversible Encryption', 'Multi-Factor Authentication', 'Hybrid Identity', 'Network Provider DLL', 'Conditional Access Policies'] 
            },
            { 
                id: 'T1137', 
                name: 'Office Application Startup', 
                description: "Adversaries may abuse the startup features of office applications, such as Microsoft Office, to maintain persistence. This can involve adding malicious code to templates, macros, or add-ins that execute when the office application starts. By leveraging office application startup mechanisms, attackers can ensure their code runs automatically, providing persistent access to the system and enabling further malicious activities.",
                subTechniques: ['Office Template Macros', 'Office Test', 'Outlook Forms', 'Outlook Home Page', 'Outlook Rules', 'Add-ins'] 
            },
            { 
                id: 'T1653', 
                name: 'Power Settings', 
                description: "Adversaries may modify power settings to maintain persistence on a victim’s system. This can include changing settings related to sleep, hibernation, or startup behavior to ensure that malicious code executes automatically upon power state changes. By manipulating power settings, attackers can create conditions that allow their malicious payloads to run consistently and maintain control over the compromised environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1542', 
                name: 'Pre-OS Boot',
                description: "Adversaries may establish persistence by modifying components that execute before the operating system (OS) boots. This can include manipulating the BIOS, UEFI, or bootloader to ensure malicious code runs during the initial stages of the boot process. By leveraging pre-OS boot techniques, attackers can maintain a highly persistent presence, often surviving OS reinstalls and hardware changes, making detection and removal challenging.",
                subTechniques: ['System Firmware', 'Component Firmware', 'Bootkit', 'ROMMONkit', 'TFTP Boot'] 
            },
            { 
                id: 'T1053', 
                name: 'Scheduled Task/Job', 
                description: "Adversaries may create or modify scheduled tasks or jobs to execute malicious code at specified times or intervals. By leveraging this technique, attackers can achieve persistence, automate the execution of their payloads, and perform actions without immediate user interaction. This allows malicious activities to blend in with legitimate scheduled operations, making detection more difficult.",
                subTechniques: ['At', 'Cron', 'Scheduled Task', 'Systemd Timers', 'Container Orchestration Job'] 
            },
            { 
                id: 'T1505', 
                name: 'Server Software Component', 
                description: "Adversaries may exploit server software components to maintain persistence within a victim’s environment. This can involve manipulating web server modules, database extensions, or other server software to execute malicious code. By compromising these components, attackers can ensure their code runs whenever the server software is used, providing long-term access and control over the compromised systems.",
                subTechniques: ['SQL Stored Procedures', 'Transport Agent', 'Web Shell', 'IIS Components', 'Terminal Services DLL'] 
            },
            { 
                id: 'T1205', 
                name: 'Traffic Signaling', 
                description: "Adversaries may use traffic signaling to maintain persistence and communicate with compromised systems. This technique involves embedding signals within normal network traffic to instruct malware to perform specific actions. By using traffic signaling, attackers can covertly manage their malware and ensure continued operation without raising suspicion.",
                subTechniques: ['Port Knocking', 'Socket Filters'] 
            },
            { 
                id: 'T1078', 
                name: 'Valid Accounts', 
                description: "Adversaries may use stolen or otherwise obtained valid accounts to access a victim’s network. These accounts allow attackers to bypass security measures and appear as legitimate users, facilitating further infiltration, data exfiltration, and other malicious activities while evading detection. By leveraging valid accounts, adversaries can maintain persistent access to the compromised environment.",
                subTechniques: ['Default Accounts', 'Domain Accounts', 'Local Accounts', 'Cloud Accounts'] 
            }
        ]
    },
    {
        category: 'Privilege Escalation',
        techniques: [
            { 
                id: 'T1548', 
                name: 'Abuse Elevation Control Mechanism', 
                description: "Adversaries may exploit elevation control mechanisms to gain higher privileges within a system. This can involve bypassing User Account Control (UAC) on Windows, exploiting sudo permissions on Linux, or other methods that allow for privilege escalation. By abusing these mechanisms, attackers can perform actions requiring elevated privileges, enabling them to manipulate system settings, install software, and maintain persistent control over the compromised environment.",
                subTechniques: ['Setuid and Setgid', 'Bypass User Account Control', 'Sudo and Sudo Caching', 'Elevated Execution with Prompt', 'Temporary Elevated Cloud Access', 'TCC Manipulation'] 
            },
            { 
                id: 'T1134', 
                name: 'Access Token Manipulation', 
                description: "Adversaries may manipulate access tokens to escalate privileges and maintain persistence. By impersonating or stealing access tokens, attackers can assume the identity and permissions of legitimate users or processes. This allows them to bypass security controls, access restricted resources, and perform actions under the guise of an authorized account, making detection and remediation more challenging.",
                subTechniques: ['Token Impersonation/Theft', 'Create Process with Token', 'Make and Impersonate Token', 'Parent PID Spoofing', 'SID-History Injection'] 
            },
            { 
                id: 'T1098', 
                name: 'Account Manipulation', 
                description: "Adversaries may manipulate user accounts to maintain access and evade detection. This can involve actions such as creating new accounts, modifying existing account permissions, or resetting passwords. By altering account settings, attackers can ensure continued access to the compromised environment and perform malicious activities under the guise of legitimate users.",
                subTechniques: ['Additional Cloud Credentials', 'Additional Email Delegate Permissions', 'Additional Cloud Roles', 'SSH Authorized Keys', 'Device Registration', 'Additional Container Cluster Roles'] 
            },
            { 
                id: 'T1574', 
                name: 'Boot or Logon Autostart Execution', 
                description: "Adversaries may configure executables to run automatically upon system boot or user logon to maintain persistence. This ensures that malicious code is executed each time the system starts or the user logs in. Techniques include modifying registry keys, adding programs to startup folders, or configuring scheduled tasks, allowing attackers to maintain a foothold on the system across reboots and logons.",
                subTechniques: ['Registry Run Keys / Startup Folder', 'Authentication Package', 'Time Providers', 'Winlogon Helper DLL', 'Security Support Provider', 'Kernel Modules and Extensions', 'Re-opened Applications', 'LSASS Driver', 'Shortcut Modification', 'Port Monitors', 'Print Processors', 'XDG Autostart Entries', 'Active Setup', 'Login Items'] 
            },
            { 
                id: 'T1037', 
                name: 'Boot or Logon Initialization Scripts', 
                description: "Adversaries may use scripts that run during the boot or logon process to maintain persistence on a victim’s system. These scripts can be placed in various locations, such as startup folders, logon scripts, or registry keys, ensuring they execute automatically when the system boots or a user logs in. This allows attackers to repeatedly run malicious code and maintain their foothold within the environment.",
                subTechniques: ['Logon Script (Windows)', 'Login Hook', 'Network Logon Script', 'RC Scripts', 'Startup Items'] 
            },
            { 
                id: 'T1543', 
                name: 'Create or Modify System Process', 
                description: "Adversaries may create or modify system processes to maintain persistence and execute malicious activities. This can involve creating new services, modifying existing ones, or changing how system processes operate to ensure that malicious code is executed with elevated privileges. By manipulating system processes, attackers can maintain control over the compromised environment and evade detection.",
                subTechniques: ['Launch Agent', 'Systemd Service', 'Windows Service', 'Launch Daemon', 'Container Service'] 
            },
            { 
                id: 'T1484', 
                name: 'Domain or Tenant Policy Modification', 
                description: "Adversaries may modify domain or tenant policies to maintain persistence and further their objectives. This can involve changing security settings, altering authentication policies, or adjusting permissions within a domain or cloud tenant. By manipulating these policies, attackers can weaken security controls, create backdoors, and ensure continued access to the compromised environment.",
                subTechniques: ['Group Policy Modification', 'Trust Modification'] 
            },
            { 
                id: 'T1611', 
                name: 'Escape to Host', 
                description: "Adversaries may attempt to escape from a virtual environment to gain control of the underlying host. This technique involves exploiting vulnerabilities or misconfigurations in the virtualization software to break out of the guest environment and execute code on the host system. By escaping to the host, attackers can gain higher-level access, persist on the physical machine, and potentially compromise other virtual machines running on the same host.",
                subTechniques: [] 
            },
            { 
                id: 'T1546', 
                name: 'Event Triggered Execution', 
                description: "Adversaries may use event triggers to execute malicious code in response to specific system or user events. This can include creating or modifying scheduled tasks, using system-level event triggers, or exploiting application-specific events to run malicious payloads automatically when certain conditions are met. By leveraging event-triggered execution, attackers can maintain persistence and perform actions stealthily, aligning their activities with legitimate system operations.",
                subTechniques: ['Change Default File Association', 'Screensaver', 'Windows Management Instrumentation Event Subscription', 'Unix Shell Configuration Modification', 'Trap', 'LC_LOAD_DYLIB Addition', 'Netsh Helper DLL', 'Accessibility Features', 'AppCert DLLs', 'AppInit DLLs', 'Application Shimming', 'Image File Execution Options Injection', 'PowerShell Profile', 'Emond', 'Component Object Model Hijacking', 'Installer Packages'] 
            },
            { 
                id: 'T1068', 
                name: 'Exploitation for Privilege Escalation', 
                description: "Adversaries may exploit vulnerabilities in operating systems, software, or applications to gain higher privileges on a compromised system. By leveraging these exploits, attackers can escalate their access rights from a lower-privileged user to an administrator or root level, allowing them to perform more significant actions, maintain persistence, and bypass security controls within the environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1574', 
                name: 'Hijack Execution Flow', 
                description: "Adversaries may hijack the execution flow of a system or application to execute malicious code. This technique involves redirecting the execution of legitimate code to malicious payloads through methods such as DLL injection, function hooking, or modifying pointers in memory. By hijacking the execution flow, attackers can gain control over the compromised system, execute arbitrary code, and maintain persistence while evading detection.",
                subTechniques: ['DLL Search Order Hijacking', 'DLL Side-Loading', 'Dylib Hijacking', 'Executable Installer File Permissions Weakness', 'Dynamic Linker Hijacking', 'Path Interception by PATH Environment Variable', 'Path Interception by Search Order Hijacking', 'Path Interception by Unquoted Path', 'Services File Permissions Weakness', 'Services Registry Permissions Weakness', 'COR_PROFILER', 'KernelCallbackTable', 'AppDomainManager'] 
            },
            { 
                id: 'T1055', 
                name: 'Process Injection', 
                description: "Adversaries may inject malicious code into the address space of another process to execute it within the context of that process. This technique can be used to evade defenses and escalate privileges by running the injected code with the permissions of the target process. Common methods of process injection include DLL injection, portable executable injection, and thread execution hijacking.",
                subTechniques: ['Dynamic-link Library Injection', 'Portable Executable Injection', 'Thread Execution Hijacking', 'Asynchronous Procedure Call', 'Thread Local Storage', 'Ptrace System Calls', 'Proc Memory', 'Extra Window Memory Injection', 'Process Hollowing', 'Process Doppelgänging', 'VDSO Hijacking', 'ListPlanting'] 
            },
            { 
                id: 'T1053', 
                name: 'Scheduled Task/Job', 
                description: "Adversaries may create or modify scheduled tasks or jobs to execute malicious code at specified times or intervals. By leveraging this technique, attackers can achieve persistence, automate the execution of their payloads, and perform actions without immediate user interaction. This allows malicious activities to blend in with legitimate scheduled operations, making detection more difficult.",
                subTechniques: ['At', 'Cron', 'Scheduled Task', 'Systemd Timers', 'Container Orchestration Job'] 
            },
            { 
                id: 'T1078', 
                name: 'Valid Accounts', 
                description: "Adversaries may use stolen or otherwise obtained valid accounts to access a victim’s network. These accounts allow attackers to bypass security measures and appear as legitimate users, facilitating further infiltration, data exfiltration, and other malicious activities while evading detection. By leveraging valid accounts, adversaries can maintain persistent access to the compromised environment.",
                subTechniques: ['Default Accounts', 'Domain Accounts', 'Local Accounts', 'Cloud Accounts']
            }
        ]
    },
    {
        category: 'Defense Evasion',
        techniques: [
            { 
                id: 'T1548', 
                name: 'Abuse Elevation Control Mechanism', 
                description: "Adversaries may exploit elevation control mechanisms to gain higher privileges within a system. This can involve bypassing User Account Control (UAC) on Windows, exploiting sudo permissions on Linux, or other methods that allow for privilege escalation. By abusing these mechanisms, attackers can perform actions requiring elevated privileges, enabling them to manipulate system settings, install software, and maintain persistent control over the compromised environment.",
                subTechniques: ['Setuid and Setgid', 'Bypass User Account Control', 'Sudo and Sudo Caching', 'Elevated Execution with Prompt', 'Temporary Elevated Cloud Access', 'TCC Manipulation'] 
            },
            { 
                id: 'T1134', 
                name: 'Access Token Manipulation', 
                description: "teAdversaries may manipulate access tokens to escalate privileges and maintain persistence. By impersonating or stealing access tokens, attackers can assume the identity and permissions of legitimate users or processes. This allows them to bypass security controls, access restricted resources, and perform actions under the guise of an authorized account, making detection and remediation more challenging.xt",
                subTechniques: ['Token Impersonation/Theft', 'Create Process with Token', 'Make and Impersonate Token', 'Parent PID Spoofing', 'SID-History Injection'] 
            },
            { 
                id: 'T1197', 
                name: 'BITS Jobs', 
                description: "Adversaries may abuse Background Intelligent Transfer Service (BITS) jobs to maintain persistence on a victim’s system. BITS is a Windows service used for asynchronous file transfers between machines. By creating or manipulating BITS jobs, attackers can schedule tasks to download or upload files, execute payloads, and maintain a presence on the system without triggering typical detection mechanisms.",
                subTechniques: [] 
            },
            { 
                id: 'TT1612', 
                name: 'Build Image on Host', 
                description: "Adversaries may build a malicious container image directly on a host to maintain persistence and execute malicious activities. By leveraging container management tools and commands, attackers can create or modify container images to include backdoors, malware, or other malicious components. These containers can then be deployed and run on the host, providing a covert means to execute malicious code and maintain access.",
                subTechniques: [] 
            },
            { 
                id: 'T1622', 
                name: 'Debugger Evasion', 
                description: "Adversaries may attempt to evade detection and analysis by interfering with or disabling debugging tools. This can involve using anti-debugging techniques, such as detecting the presence of a debugger, altering the execution flow, or employing timing checks to disrupt the debugging process. By evading debuggers, attackers can make it more difficult for security analysts to analyze and understand their malicious code.",
                subTechniques: [] 
            },
            {
                id: 'T1140', 
                name: 'Deobfuscate/Decode Files or Information', 
                description: "Adversaries may deobfuscate or decode files or information to reveal hidden data. This technique can involve reversing obfuscation methods or decoding encoded data to access its original form. By deobfuscating or decoding information, attackers can bypass security mechanisms, extract valuable data, or understand the functionality of protected code.",
                subTechniques: [] 
            },
            {
                id: 'T1610', 
                name: 'Deploy Container', 
                description: "Adversaries may deploy malicious containers within a victim’s environment to establish a foothold, persist, or execute malicious operations. By leveraging containerization platforms, attackers can create, manage, and deploy containers that include backdoors, exploits, or other malicious payloads, allowing them to infiltrate and manipulate the target’s infrastructure.",
                subTechniques: [] 
            },
            { 
                id: 'T1006', 
                name: 'Direct Volume Access', 
                description: "Adversaries may gain direct access to disk volumes to bypass operating system security controls and directly manipulate data on disk. This technique allows attackers to read, write, or modify data at the volume level, enabling them to exfiltrate sensitive information, alter critical files, or install persistent malware outside of typical file system protections.",
                subTechniques: [] 
            },
            { 
                id: 'T1484', 
                name: 'Domain or Tenant Policy Modification', 
                description: "Adversaries may modify domain or tenant policies to maintain persistence and further their objectives. This can involve changing security settings, altering authentication policies, or adjusting permissions within a domain or cloud tenant. By manipulating these policies, attackers can weaken security controls, create backdoors, and ensure continued access to the compromised environment.",
                subTechniques: ['Group Policy Modification', 'Trust Modification'] 
            },
            { 
                id: 'T1480', 
                name: 'Execution Guardrails', 
                description: "Adversaries may use execution guardrails to ensure that their malicious payloads only execute under specific conditions, such as within a certain environment or on particular systems. This technique helps avoid detection and analysis by security tools by restricting execution to targeted environments, reducing the risk of exposure on unintended systems.",
                subTechniques: ['Environmental Keying'] 
            },
            { 
                id: 'T1211', 
                name: 'Exploitation for Defense Evasion', 
                description: "Adversaries may exploit vulnerabilities in security software, configurations, or hardware to evade detection and defenses. By taking advantage of weaknesses, attackers can disable security features, bypass protections, and avoid detection, allowing them to operate within the target environment without being discovered.",
                subTechniques: [] 
            },
            { 
                id: 'T1222', 
                name: 'File and Directory Permissions Modification', 
                description: "Adversaries may modify file and directory permissions to evade defenses and maintain persistence. By changing access controls, attackers can prevent legitimate users and security tools from detecting or removing their malicious files, ensuring their presence on the system remains undisturbed.",
                subTechniques: ['Windows File and Directory Permissions Modification', 'Linux and Mac File and Directory Permissions Modification'] 
            },
            { 
                id: 'T1564', 
                name: 'Hide Artifacts', 
                description: "Adversaries may hide artifacts, such as files, processes, or network connections, to avoid detection. This can involve using techniques like timestomping, encryption, or altering file attributes to conceal the presence of malicious activities. By hiding artifacts, attackers can evade security measures and maintain stealth within the compromised environment.",
                subTechniques: ['Hidden Files and Directories', 'Hidden Users', 'Hidden Window', 'NTFS File Attributes', 'Hidden File System', 'Run Virtual Instance', 'VBA Stomping', 'Email Hiding Rules', 'Resource Forking', 'Process Argument Spoofing', 'Ignore Process Interrupts', 'File/Path Exclusions'] 
            },
            { 
                id: 'T1574', 
                name: 'Hijack Execution Flow', 
                description: "Adversaries may hijack the execution flow of a system or application to execute malicious code. This technique involves redirecting the execution of legitimate code to malicious payloads through methods such as DLL injection, function hooking, or modifying pointers in memory. By hijacking the execution flow, attackers can gain control over the compromised system, execute arbitrary code, and maintain persistence while evading detection.",
                subTechniques: ['DLL Search Order Hijacking', 'DLL Side-Loading', 'Dylib Hijacking', 'Executable Installer File Permissions Weakness', 'Dynamic Linker Hijacking', 'Path Interception by PATH Environment Variable', 'Path Interception by Search Order Hijacking', 'Path Interception by Unquoted Path', 'Services File Permissions Weakness', 'Services Registry Permissions Weakness', 'COR_PROFILER', 'KernelCallbackTable', 'AppDomainManager'] 
            },
            { 
                id: 'T1562', 
                name: 'Impair Defenses', 
                description: "Adversaries may impair security defenses to facilitate further malicious activities. This can involve disabling security software, modifying configurations, or disrupting security operations. By impairing defenses, attackers can reduce the effectiveness of security measures, making it easier to maintain access and execute their objectives.",
                subTechniques: ['Disable or Modify Tools', 'Disable Windows Event Logging', 'Impair Command History Logging', 'Disable or Modify System Firewall', 'Indicator Blocking', 'Disable or Modify Cloud Firewall', 'Disable or Modify Cloud Logs', 'Safe Mode Boot', 'Downgrade Attack', 'Spoof Security Alerting', 'Disable or Modify Linux Audit System'] 
            },
            { 
                id: 'T1656', 
                name: 'Impersonation', 
                description: "Adversaries may impersonate legitimate users, systems, or services to bypass security controls and gain access to restricted resources. This can involve using stolen credentials, forging authentication tokens, or mimicking network traffic. By impersonating trusted entities, attackers can blend in with normal operations and evade detection.",
                subTechniques: [] 
            },
            { 
                id: 'T1070', 
                name: 'Indicator Removal', 
                description: "Adversaries may remove indicators of their presence or activities to evade detection and hinder incident response efforts. This can involve deleting logs, modifying timestamps, or clearing evidence of their actions. By removing indicators, attackers can make it more difficult for security teams to detect, analyze, and respond to their activities.",
                subTechniques: ['Clear Windows Event Logs', 'Clear Linux or Mac System Logs', 'Clear Command History', 'File Deletion', 'Network Share Connection Removal', 'Timestomp', 'Clear Network Connection History and Configurations', 'Clear Mailbox Data', 'Clear Persistence'] 
            },
            { 
                id: 'T1202', 
                name: 'Indirect Command Execution', 
                description: "Adversaries may execute commands indirectly by using trusted systems or applications to perform malicious activities. This can involve leveraging system utilities, scripts, or services that execute commands on behalf of the attacker, helping to evade detection by security tools monitoring for direct command execution.",
                subTechniques: [] 
            },
            { 
                id: 'T1036', 
                name: 'Masquerading', 
                description: "Adversaries may disguise their malicious activities by masquerading as legitimate files, processes, or services. This can involve renaming files, using similar-looking names, or placing malware in locations where it appears to be a trusted system component. By masquerading, attackers can evade detection and blend in with normal system operations.",
                subTechniques: ['Invalid Code Signature', 'Right-to-Left Override', 'Rename System Utilities', 'Masquerade Task or Service', 'Match Legitimate Name or Location', 'Space after Filename', 'Double File Extension', 'Masquerade File Type', 'Break Process Trees'] 
            },
            { 
                id: 'T1556', 
                name: 'Modify Authentication Process', 
                description: "Adversaries may modify the authentication process to maintain persistence and control over a victim’s system or network. This can involve altering authentication mechanisms, such as modifying login scripts, manipulating single sign-on (SSO) configurations, or exploiting authentication tokens. By tampering with the authentication process, attackers can create backdoors, bypass security measures, and ensure continued access to the compromised environment.",
                subTechniques: ['Domain Controller Authentication', 'Password Filter DLL', 'Pluggable Authentication Modules', 'Network Device Authentication', 'Reversible Encryption', 'Multi-Factor Authentication', 'Hybrid Identity', 'Network Provider DLL', 'Conditional Access Policies'] 
            },
            { 
                id: 'T1202', 
                name: 'Modify Cloud Compute Infrastructure', 
                description: "Adversaries may modify cloud compute infrastructure to maintain persistence and evade detection. This can involve creating or modifying virtual machines, containers, or other cloud resources to execute malicious code, store data, or establish command and control channels. By manipulating cloud infrastructure, attackers can leverage the scalability and flexibility of cloud environments to further their objectives.",
                subTechniques: ['Create Snapshot', 'Create Cloud Instance', 'Delete Cloud Instance', 'Revert Cloud Instance', 'Modify Cloud Compute Configurations'] 
            },
            { 
                id: 'T1112', 
                name: 'Modify Registry', 
                description: "Adversaries may modify the Windows Registry to maintain persistence, escalate privileges, or hide their activities. The registry can be altered to execute malicious code during system startup, change system configurations, or store data for later use. By modifying the registry, attackers can achieve a wide range of malicious goals while evading detection.",
                subTechniques: [] 
            },
            { 
                id: 'T1112', 
                name: 'Modify System Image', 
                description: "Adversaries may modify system images to include malicious code, ensuring their payloads are deployed whenever the system is installed or updated. This can involve altering operating system images, firmware, or other critical system components. By modifying system images, attackers can maintain persistence and control over the environment across reboots and system reinstalls.",
                subTechniques: ['Patch System Image', 'Downgrade System Image'] 
            },
            { 
                id: 'T1599', 
                name: 'Network Boundary Bridging', 
                description: "Adversaries may bridge network boundaries to facilitate communication between compromised systems in different network segments. This can involve creating network tunnels, proxies, or VPNs that bypass network segmentation controls. By bridging network boundaries, attackers can move laterally within an environment, exfiltrate data, and maintain command and control over distributed systems.",
                subTechniques: ['Network Address Translation Traversal'] 
            },
            { 
                id: 'T1027', 
                name: 'Obfuscated Files or Information', 
                description: "Adversaries may use obfuscation techniques to conceal malicious files or information. This can include encoding, encrypting, or otherwise transforming data to evade detection by security tools. Obfuscated files or information make it more difficult for analysts to identify and understand the malicious content, aiding in stealth and persistence.",
                subTechniques: ['Binary Padding', 'Software Packing', 'Steganography', 'Compile After Delivery', 'Indicator Removal from Tools', 'HTML Smuggling', 'Dynamic API Resolution', 'Stripped Payloads', 'Embedded Payloads', 'Command Obfuscation', 'Fileless Storage', 'LNK Icon Smuggling', 'Encrypted/Encoded File'] 
            },
            { 
                id: 'T1647', 
                name: 'Plist File Modification', 
                description: "Adversaries may modify plist (property list) files on macOS to maintain persistence or alter system behavior. Plist files are used to store configuration settings, and modifying them can enable attackers to execute malicious code during system startup or application launch, ensuring their payloads run continuously.",
                subTechniques: [] 
            },
            {
                id: 'T1542', 
                name: 'Pre-OS Boot', 
                description: "Adversaries may establish persistence by modifying components that execute before the operating system (OS) boots. This can include manipulating the BIOS, UEFI, or bootloader to ensure malicious code runs during the initial stages of the boot process. By leveraging pre-OS boot techniques, attackers can maintain a highly persistent presence, often surviving OS reinstalls and hardware changes, making detection and removal challenging.",
                subTechniques: ['System Firmware', 'Component Firmware', 'Bootkit', 'ROMMONkit', 'TFTP Boot'] 
            },
            {
                id: 'T1055', 
                name: 'Process Injection', 
                description: "Adversaries may inject malicious code into the address space of another process to execute it within the context of that process. This technique can be used to evade defenses and escalate privileges by running the injected code with the permissions of the target process. Common methods of process injection include DLL injection, portable executable injection, and thread execution hijacking.",
                subTechniques: ['Dynamic-link Library Injection', 'Portable Executable Injection', 'Thread Execution Hijacking', 'Asynchronous Procedure Call', 'Thread Local Storage', 'Ptrace System Calls', 'Proc Memory', 'Extra Window Memory Injection', 'Process Hollowing', 'Process Doppelgänging', 'VDSO Hijacking', 'ListPlanting'] 
            },
            {
                id: 'T1565', 
                name: 'Reflective Code Loading', 
                description: "Adversaries may use reflective code loading to execute code within the memory space of a running process without writing the code to disk. This technique helps avoid detection by file-based security tools, as the code resides only in memory. Reflective code loading can be used to inject malicious payloads into processes and execute them covertly.",
                subTechniques: [] 
            },
            {
                id: 'T1207', 
                name: 'Rogue Domain Controller', 
                description: "Adversaries may set up a rogue domain controller within an Active Directory environment to manipulate directory services, capture authentication traffic, or distribute malicious policies. By operating a rogue domain controller, attackers can intercept and alter communications, escalate privileges, and propagate malware within the domain.",
                subTechniques: [] 
            },
            { 
                id: 'T1014', 
                name: 'Rootkit', 
                description: "Adversaries may install rootkits to maintain persistent access and hide their activities on a compromised system. Rootkits operate at a low level, often within the kernel, to intercept and modify system operations. This allows attackers to conceal files, processes, and network connections, making detection and removal extremely difficult.",
                subTechniques: [] 
            },
            { 
                id: 'T1553', 
                name: 'Subvert Trust Controls', 
                description: "Adversaries may subvert trust controls to bypass security mechanisms and execute malicious activities. This can involve manipulating digital certificates, forging signatures, or exploiting vulnerabilities in trust-based systems. By subverting trust controls, attackers can gain unauthorized access, execute code, and maintain persistence while evading detection.",
                subTechniques: ['Gatekeeper Bypass', 'Code Signing', 'SIP and Trust Provider Hijacking', 'Install Root Certificate', 'Mark-of-the-Web Bypass', 'Code Signing Policy Modification'] 
            },
            {
                id: 'T1218', 
                name: 'System Binary Proxy Execution', 
                description: "Adversaries may use system binaries to execute malicious payloads, leveraging trusted system applications as proxies. This technique involves using legitimate binaries to launch malicious code, helping attackers evade detection by security tools that monitor for suspicious or unauthorized executables.",
                subTechniques: ['Compiled HTML File', 'Control Panel', 'CMSTP', 'InstallUtil', 'Mshta', 'Msiexec', 'Odbcconf', 'Regsvcs/Regasm', 'Regsvr32', 'Rundll32', 'Verclsid', 'Mavinject', 'MMC', 'Electron Applications'] 
            },
            {
                id: 'T1216', 
                name: 'System Script Proxy Execution', 
                description: "Adversaries may use system scripts to execute malicious payloads, leveraging trusted scripting environments as proxies. This technique involves using legitimate scripting languages, such as PowerShell or Bash, to run malicious code. By utilizing system scripts, attackers can blend in with normal administrative activities and evade detection.",
                subTechniques: ['PubPrn', 'SyncAppvPublishingServer'] 
            },
            {
                id: 'T1221', 
                name: 'Template Injection', 
                description: "Adversaries may inject malicious code into templates used by applications to automatically generate documents or content. This technique can be used to execute code when the template is loaded by the application, providing a means for attackers to maintain persistence and execute arbitrary payloads within the context of the targeted application.",
                subTechniques: [] 
            },
            {
                id: 'T1205', 
                name: 'Traffic Signaling', 
                description: "Adversaries may use traffic signaling to maintain persistence and communicate with compromised systems. This technique involves embedding signals within normal network traffic to instruct malware to perform specific actions. By using traffic signaling, attackers can covertly manage their malware and ensure continued operation without raising suspicion.",
                subTechniques: ['Port Knocking', 'Socket Filters'] 
            },
            {
                id: 'T1127', 
                name: 'Trusted Developer Utilities Proxy Execution', 
                description: "Adversaries may use trusted developer utilities to proxy the execution of malicious code. This technique involves leveraging legitimate development tools and environments to run malicious payloads, helping to evade detection by security tools that monitor for suspicious or unauthorized executables. By using trusted utilities, attackers can blend in with normal development activities.",
                subTechniques: ['MSBuild'] 
            },
            {
                id: 'T1535', 
                name: 'Unused/Unsupported Cloud Regions', 
                description: "Adversaries may leverage unused or unsupported cloud regions to deploy malicious resources and evade detection. These regions are less likely to be monitored or have security controls applied, providing a stealthy environment for attackers to operate within the victim’s cloud infrastructure, deploy backdoors, and exfiltrate data.",
                subTechniques: [] 
            },
            {
                id: 'T1550', 
                name: 'Use Alternate Authentication Material', 
                description: "Adversaries may use alternate authentication material, such as keys, tokens, or certificates, to access systems and maintain persistence. By obtaining or forging alternate authentication material, attackers can bypass traditional credential-based authentication mechanisms and gain unauthorized access to resources, ensuring continued access without relying on stolen passwords.",
                subTechniques: ['Application Access Token', 'Pass the Hash', 'Pass the Ticket', 'Web Session Cookie'] 
            },
            { 
                id: 'T1078', 
                name: 'Valid Accounts', 
                description: "Adversaries may use stolen or otherwise obtained valid accounts to access a victim’s network. These accounts allow attackers to bypass security measures and appear as legitimate users, facilitating further infiltration, data exfiltration, and other malicious activities while evading detection. By leveraging valid accounts, adversaries can maintain persistent access to the compromised environment.",
                subTechniques: ['Default Accounts', 'Domain Accounts', 'Local Accounts', 'Cloud Accounts'] 
            },
            {
                id: 'T1497', 
                name: 'Virtualization/Sandbox Evasion', 
                description: "Adversaries may employ techniques to detect and evade virtualization or sandbox environments used for malware analysis. This can involve checking for the presence of virtual machine artifacts, timing checks, or altering behavior when running in a sandbox. By evading these environments, attackers can ensure their malware executes properly on target systems and avoids detection during analysis.",
                subTechniques: ['System Checks', 'User Activity Based Checks', '	Time Based Evasion'] 
            },
            {
                id: 'T1600', 
                name: 'Weaken Encryption', 
                description: "Adversaries may weaken encryption used by the victim to facilitate easier access to encrypted data. This can involve exploiting vulnerabilities in encryption algorithms, misconfiguring encryption settings, or using downgraded encryption protocols. By weakening encryption, attackers can decrypt sensitive information and compromise the confidentiality and integrity of the victim’s data.",
                subTechniques: ['Reduce Key Space', 'Disable Crypto Hardware'] 
            },
            {
                id: 'T1220', 
                name: 'XSL Script Processing', 
                description: "Adversaries may abuse Extensible Stylesheet Language (XSL) scripts to execute malicious code. XSL scripts can be used to transform XML data and can include embedded scripting languages like JavaScript. By injecting malicious code into XSL scripts, attackers can execute arbitrary payloads when the scripts are processed by an application, facilitating further compromise and persistence.",
                subTechniques: [] 
            }

        ]
    },
    {
        category: 'Credential Access',
        techniques: [
            { 
                id: 'T1557', 
                name: 'Adversary-in-the-Middle', 
                description: "Adversaries may position themselves between two or more networked devices to intercept and potentially alter communications. This can involve man-in-the-middle attacks, where attackers eavesdrop on, manipulate, or inject data into communications. By exploiting this position, adversaries can steal credentials, alter data in transit, and impersonate legitimate parties.",
                subTechniques: ['LLMNR/NBT-NS Poisoning and SMB Relay', 'ARP Cache Poisoning', 'DHCP Spoofing'] 
            },
            { 
                id: 'T1110', 
                name: 'Brute Force', 
                description: "Adversaries may use brute force attacks to obtain valid credentials by systematically attempting all possible combinations of passwords or keys until the correct one is found. This method can be used to gain unauthorized access to systems, services, or accounts, and can be automated to increase efficiency and effectiveness.",
                subTechniques: ['Password Guessing', 'Password Cracking', 'Password Spraying', 'Credential Stuffing'] 
            },
            { 
                id: 'T1555', 
                name: 'Credentials from Password Stores', 
                description: "Adversaries may target password stores, such as credential managers or browser password storage, to obtain user credentials. These stores often contain saved usernames and passwords that can be extracted and used to gain unauthorized access to systems, applications, or services.",
                subTechniques: ['Keychain', 'Securityd Memory', 'Credentials from Web Browsers', 'Windows Credential Manager', 'Password Managers', 'Cloud Secrets Management Stores'] 
            },
            { 
                id: 'T1212', 
                name: 'Exploitation for Credential Access', 
                description: "Adversaries may exploit vulnerabilities to obtain credentials from a system or application. This can include exploiting software bugs, security misconfigurations, or unpatched vulnerabilities to extract credentials stored in memory, databases, or files. By leveraging these exploits, attackers can gain access to sensitive accounts and resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1187', 
                name: 'Forced Authentication', 
                description: "Adversaries may force a user or system to authenticate against a malicious server, capturing credentials in the process. This can involve techniques such as credential relaying, SMB/NTLM relay attacks, or exploiting authentication protocols to trick users into providing their credentials to a fake server controlled by the attacker.",
                subTechniques: [] 
            },
            { 
                id: 'T1606', 
                name: 'Forge Web Credentials', 
                description: "Adversaries may forge web credentials to gain unauthorized access to web applications and services. This can involve creating fake authentication tokens, cookies, or other credentials that are accepted by the target application, allowing attackers to impersonate legitimate users and access sensitive data or functionality.",
                subTechniques: ['Web Cookies', 'SAML Tokens'] 
            },
            { 
                id: 'T1056', 
                name: 'Input Capture', 
                description: "Adversaries may capture user input to obtain credentials or other sensitive information. This can involve keylogging, screen capturing, or intercepting input from hardware devices. By capturing input, attackers can steal credentials, monitor user activities, and collect information for further attacks.",
                subTechniques: ['Keylogging', 'GUI Input Capture', 'Web Portal Capture', 'Credential API Hooking'] 
            },
            { 
                id: 'T1556', 
                name: 'Modify Authentication Process', 
                description: "Adversaries may modify the authentication process to maintain persistence and control over a victim’s system or network. This can involve altering authentication mechanisms, such as modifying login scripts, manipulating single sign-on (SSO) configurations, or exploiting authentication tokens. By tampering with the authentication process, attackers can create backdoors, bypass security measures, and ensure continued access to the compromised environment.",
                subTechniques: ['Domain Controller Authentication', 'Password Filter DLL', 'Pluggable Authentication Modules', 'Network Device Authentication', 'Reversible Encryption', 'Multi-Factor Authentication', 'Hybrid Identity', 'Network Provider DLL', 'Conditional Access Policies'] 
            },
            { 
                id: 'T1111',
                name: 'Multi-Factor Authentication Interception', 
                description: "Adversaries may intercept multi-factor authentication (MFA) mechanisms to gain unauthorized access. This can involve intercepting SMS codes, one-time passwords, or push notifications, allowing attackers to bypass MFA and authenticate as legitimate users.",
                subTechniques: [] 
            },
            { 
                id: 'T1621', 
                name: 'Multi-Factor Authentication Request Generation', 
                description: "Adversaries may generate MFA requests to trick users into approving authentication attempts. This can involve sending repeated MFA requests to users until they approve one out of frustration or confusion, allowing attackers to gain unauthorized access.",
                subTechniques: [] 
            },
            { 
                id: 'T1040', 
                name: 'Network Sniffing', 
                description: "Adversaries may use network sniffing to capture credentials and other sensitive information in transit. This technique involves monitoring network traffic, often using tools like packet analyzers, to intercept data being transmitted over the network. By capturing network traffic, attackers can steal credentials and other sensitive information.",
                subTechniques: [] 
            },
            { 
                id: 'T1003', 
                name: 'OS Credential Dumping', 
                description: "Adversaries may dump credentials from operating systems to gain unauthorized access. This can involve extracting hashed or plaintext passwords, Kerberos tickets, or other credential data stored in the operating system. Tools like Mimikatz are often used to perform credential dumping on Windows systems.",
                subTechniques: ['LSASS Memory', 'Security Account Manager', 'NTDS', 'LSA Secrets', 'Cached Domain Credentials', 'DCSync', 'Proc Filesystem', '/etc/passwd and /etc/shadow'] 
            },
            { 
                id: 'T1528', 
                name: 'Steal Application Access Token', 
                description: "Adversaries may steal application access tokens to bypass authentication and gain access to web services and APIs. Access tokens are used for session management and authorization; by stealing these tokens, attackers can impersonate legitimate users and access their data and functionality.",
                subTechniques: [] 
            },
            { 
                id: 'T1649', 
                name: 'Steal or Forge Authentication Certificates', 
                description: "Adversaries may steal or forge authentication certificates to gain unauthorized access. Certificates are used for secure communication and authentication; by compromising these certificates, attackers can impersonate legitimate systems, decrypt communications, and access restricted resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1558', 
                name: 'Steal or Forge Kerberos Tickets', 
                description: "Adversaries may steal or forge Kerberos tickets to gain unauthorized access to network resources. This can involve techniques like pass-the-ticket attacks, where attackers use stolen Kerberos tickets to authenticate to services, or forging golden tickets to create valid tickets without needing the original credentials.",
                subTechniques: ['Golden Ticket', 'Silver Ticket', 'Kerberoasting', 'AS-REP Roasting'] 
            },
            { 
                id: 'T1539', 
                name: 'Steal Web Session Cookie', 
                description: "Adversaries may steal web session cookies to hijack web sessions and gain unauthorized access to web applications. Session cookies are used to maintain user sessions; by stealing these cookies, attackers can impersonate legitimate users and access their web sessions without needing their credentials.",
                subTechniques: [] 
            },
            { 
                id: 'T1552', 
                name: 'Unsecured Credentials', 
                description: "Adversaries may exploit unsecured credentials that are stored or transmitted insecurely. This can involve plaintext passwords in configuration files, scripts, or network traffic. By identifying and exploiting these unsecured credentials, attackers can gain unauthorized access to systems and services.",
                subTechniques: ['Credentials In Files', 'Credentials in Registry', 'Bash History', 'Private Keys', 'Cloud Instance Metadata API', 'Group Policy Preferences', 'Container API', 'Chat Messages'] 
            }
        ]
    },
    {
        category: 'Discovery',
        techniques: [
            { 
                id: 'T1087', 
                name: 'Account Discovery', 
                description: "Adversaries may attempt to discover user and administrator accounts on a system or network. This can involve querying the operating system, directory services, or other applications to gather information about existing accounts. By identifying user accounts, attackers can target specific individuals for further exploitation or privilege escalation.",
                subTechniques: ['Local Account', 'Domain Account', 'Email Account', 'Cloud Account'] 
            },
            { 
                id: 'T1010', 
                name: 'Application Window Discovery', 
                description: "Adversaries may enumerate application windows to gather information about running applications and their states. This can involve querying the system for open windows, identifying application titles, or capturing window content. By discovering application windows, attackers can gain insights into the victim’s activities and potentially target specific applications for further exploitation.",
                subTechniques: [] },
            { 
                id: 'T1217', 
                name: 'Browser Information Discovery', 
                description: "Adversaries may gather information about the victim’s web browser, such as version, plugins, and configuration settings. This can involve querying the browser or inspecting browser-related files and directories. By discovering browser information, attackers can identify potential vulnerabilities, tailor social engineering attacks, or exploit browser-specific features.",
                subTechniques: [] },
            { 
                id: 'T1580', 
                name: 'Cloud Infrastructure Discovery', 
                description: "Adversaries may enumerate cloud infrastructure components within a victim’s environment to gather information about the cloud resources and services in use. This can involve querying cloud APIs, inspecting configuration files, or using cloud management tools. By discovering cloud infrastructure, attackers can identify potential targets and plan further attacks on cloud resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1538', 
                name: 'Cloud Service Dashboard', 
                description: "Adversaries may access and enumerate cloud service dashboards to gather information about the victim’s cloud environment. This can involve logging into cloud management interfaces, inspecting dashboard settings, and identifying configured services. By accessing cloud service dashboards, attackers can gain insights into the victim’s cloud infrastructure and potentially manipulate cloud resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1526', 
                name: 'Cloud Service Discovery', 
                description: "Adversaries may enumerate cloud services to gather information about the services available within the victim’s cloud environment. This can involve querying cloud APIs, inspecting configuration files, or using cloud management tools. By discovering cloud services, attackers can identify potential targets and plan further attacks on cloud resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1619', 
                name: 'Cloud Storage Object Discovery', 
                description: "Adversaries may enumerate cloud storage objects to gather information about the files and data stored in the victim’s cloud environment. This can involve querying cloud storage APIs, inspecting configuration files, or using cloud management tools. By discovering cloud storage objects, attackers can identify valuable data and plan further attacks on cloud storage resources.",
                subTechniques: [] 
            },
            { 
                id: 'T1613', 
                name: 'Container and Resource Discovery', 
                description: "Adversaries may enumerate containers and associated resources within a victim’s environment to gather information about the containerized applications and their configurations. This can involve querying container orchestration platforms, inspecting configuration files, or using container management tools. By discovering containers and resources, attackers can identify potential targets and plan further attacks on containerized environments.",
                subTechniques: [] 
            },
            { 
                id: 'T1622', 
                name: 'Debugger Evasion', 
                description: "Adversaries may attempt to evade detection and analysis by interfering with or disabling debugging tools. This can involve using anti-debugging techniques, such as detecting the presence of a debugger, altering the execution flow, or employing timing checks to disrupt the debugging process. By evading debuggers, attackers can make it more difficult for security analysts to analyze and understand their malicious code.",
                subTechniques: [] 
            },
            { 
                id: 'T1652', 
                name: 'Device Driver Discovery', 
                description: "Adversaries may enumerate device drivers on a system to gather information about the hardware and software components in use. This can involve querying the operating system, inspecting configuration files, or using system utilities. By discovering device drivers, attackers can identify potential vulnerabilities and plan further attacks on specific hardware or software components.",
                subTechniques: [] 
            },
            { 
                id: 'T1482', 
                name: 'Domain Trust Discovery', 
                description: "Adversaries may enumerate domain trusts within a victim’s environment to gather information about the relationships between different domains. This can involve querying directory services, inspecting configuration files, or using network management tools. By discovering domain trusts, attackers can identify potential targets and plan further attacks on interconnected domains.",
                subTechniques: [] 
            },
            { 
                id: 'T1083', 
                name: 'File and Directory Discovery', 
                description: "Adversaries may enumerate files and directories on a system to gather information about the file system structure and identify valuable data. This can involve using system utilities, inspecting configuration files, or querying the operating system. By discovering files and directories, attackers can identify potential targets and plan further attacks on specific files or directories.",
                subTechniques: [] 
            },
            { 
                id: 'T1615', 
                name: 'Group Policy Discovery', 
                description: "Adversaries may enumerate group policies within a victim’s environment to gather information about the configuration and management of systems and users. This can involve querying directory services, inspecting configuration files, or using management tools. By discovering group policies, attackers can identify potential targets and plan further attacks on systems or users governed by specific policies.",
                subTechniques: [] 
            },
            { 
                id: 'T1654', 
                name: 'Log Enumeration', 
                description: "Adversaries may enumerate logs on a system to gather information about system activities, user actions, and potential security events. This can involve using system utilities, inspecting log files, or querying log management systems. By discovering logs, attackers can gain insights into the victim’s environment, identify potential weaknesses, and plan further attacks.",
                subTechniques: [] 
            },
            { 
                id: 'T1046', 
                name: 'Network Service Discovery', 
                description: "Adversaries may enumerate network services on a target system or network to identify open ports, running services, and potential vulnerabilities. This can involve using network scanning tools, querying service records, or inspecting configuration files. By discovering network services, attackers can identify potential targets and plan further attacks.",
                subTechniques: [] 
            },
            { 
                id: 'T1135', 
                name: 'Network Share Discovery', 
                description: "Adversaries may enumerate network shares on a target system or network to gather information about shared resources and data. This can involve using system utilities, querying directory services, or inspecting configuration files. By discovering network shares, attackers can identify valuable data and plan further attacks on shared resources.",
                subTechniques: [] 
            },
            {
                id: 'T1040', 
                name: 'Network Sniffing', 
                description: "Adversaries may use network sniffing to capture credentials and other sensitive information in transit. This technique involves monitoring network traffic, often using tools like packet analyzers, to intercept data being transmitted over the network. By capturing network traffic, attackers can steal credentials and other sensitive information.",
                subTechniques: [] 
            },
            { 
                id: 'T1201', 
                name: 'Password Policy Discovery', 
                description: "Adversaries may enumerate password policies within a victim’s environment to gather information about password requirements and configurations. This can involve querying directory services, inspecting configuration files, or using management tools. By discovering password policies, attackers can identify potential weaknesses and plan further attacks on password-based authentication mechanisms.",
                subTechniques: [] 
            },
            { 
                id: 'T1120', 
                name: 'Peripheral Device Discovery', 
                description: "Adversaries may enumerate peripheral devices connected to a target system to gather information about hardware components and their configurations. This can involve querying the operating system, inspecting configuration files, or using system utilities. By discovering peripheral devices, attackers can identify potential vulnerabilities and plan further attacks on specific hardware components.",
                subTechniques: [] 
            },
            { 
                id: 'T1069', 
                name: 'Permission Groups Discovery', 
                description: "Adversaries may enumerate permission groups within a victim’s environment to gather information about group memberships and access controls. This can involve querying directory services, inspecting configuration files, or using management tools. By discovering permission groups, attackers can identify potential targets and plan further attacks on systems or users with specific group memberships.",
                subTechniques: ['Local Groups', 'Domain Groups', 'Cloud Groups'] 
            },
            { 
                id: 'T1057', 
                name: 'Process Discovery', 
                description: "Adversaries may enumerate running processes on a target system to gather information about active applications and services. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering running processes, attackers can identify potential targets and plan further attacks on specific applications or services.",
                subTechniques: [] 
            },
            { 
                id: 'T1012', 
                name: 'Query Registry', 
                description: "Adversaries may query the Windows Registry to gather information about the system, user configurations, and installed software. This can involve using system utilities, scripting, or custom tools to query registry keys and values. By querying the registry, attackers can identify potential targets and plan further attacks based on the collected information.",
                subTechniques: [] 
            },
            { 
                id: 'T1018', 
                name: 'Remote System Discovery', 
                description: "Adversaries may enumerate remote systems within a victim’s network to gather information about connected devices and their configurations. This can involve using network scanning tools, querying directory services, or inspecting configuration files. By discovering remote systems, attackers can identify potential targets and plan further attacks on interconnected devices.",
                subTechniques: [] 
            },
            { 
                id: 'T1518', 
                name: 'Software Discovery', 
                description: "Adversaries may enumerate installed software on a target system to gather information about applications and their configurations. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering installed software, attackers can identify potential vulnerabilities and plan further attacks on specific applications.",
                subTechniques: ['Security Software Discovery'] 
            },
            { 
                id: 'T1082', 
                name: 'System Information Discovery', 
                description: "Adversaries may gather information about the target system, such as operating system version, hardware details, and configuration settings. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering system information, attackers can identify potential targets and plan further attacks based on the collected details.",
                subTechniques: [] 
            },
            { 
                id: 'T1614', 
                name: 'System Location Discovery', 
                description: "Adversaries may gather information about the physical or network location of the target system. This can involve querying system settings, inspecting configuration files, or using geolocation services. By discovering system location, attackers can plan further attacks based on the physical or network context of the target.",
                subTechniques: ['System Language Discovery'] 
            },
            { 
                id: 'T1016', 
                name: 'System Network Configuration Discovery', 
                description: "Adversaries may enumerate network configuration settings on a target system to gather information about network interfaces, routing tables, and other configurations. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering network configurations, attackers can identify potential targets and plan further attacks on network components.",
                subTechniques: ['Internet Connection Discovery', 'Wi-Fi Discovery'] 
            },
            { 
                id: 'T1049', 
                name: 'System Network Connections Discovery', 
                description: "Adversaries may enumerate active network connections on a target system to gather information about connected devices and communication channels. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering network connections, attackers can identify potential targets and plan further attacks on interconnected systems.",
                subTechniques: [] 
            },
            { 
                id: 'T1033', 
                name: 'System Owner/User Discovery', 
                description: "Adversaries may gather information about the owner or user of a target system to identify potential targets and their roles. This can involve querying directory services, inspecting configuration files, or using system utilities. By discovering system owners or users, attackers can tailor their attacks based on the identified individuals and their access levels.",
                subTechniques: [] 
            },
            { 
                id: 'T1007', 
                name: 'System Service Discovery', 
                description: "Adversaries may enumerate system services on a target system to gather information about running services and their configurations. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering system services, attackers can identify potential targets and plan further attacks on specific services.",
                subTechniques: [] 
            },
            { 
                id: 'T1124', 
                name: 'System Time Discovery', 
                description: "Adversaries may gather information about the system time on a target system to coordinate their activities or evade detection. This can involve using system utilities, querying the operating system, or inspecting configuration files. By discovering system time, attackers can synchronize their actions with legitimate activities or avoid time-based security controls.",
                subTechniques: [] 
            },
            { 
                id: 'T1497', 
                name: 'Virtualization/Sandbox Evasion', 
                description: "Adversaries may employ techniques to detect and evade virtualization or sandbox environments used for malware analysis. This can involve checking for the presence of virtual machine artifacts, timing checks, or altering behavior when running in a sandbox. By evading these environments, attackers can ensure their malware executes properly on target systems and avoids detection during analysis.",
                subTechniques: ['System Checks', 'User Activity Based Checks', '	Time Based Evasion'] 
            }
        ]
    },
    {
        category: 'Lateral Movement',
        techniques: [
            { 
                id: 'T1210', 
                name: 'Exploitation of Remote Services', 
                description: "Adversaries may exploit vulnerabilities in remote services to gain unauthorized access to a victim’s system or network. This can involve targeting web servers, remote desktop services, or other externally accessible services. By exploiting these services, attackers can gain a foothold in the network and potentially escalate their privileges or move laterally.",
                subTechniques: [] 
            },
            { 
                id: 'T1534', 
                name: 'Internal Spearphishing', 
                description: "Adversaries may conduct spearphishing attacks within an organization to target specific individuals. This involves sending deceptive messages that appear to come from trusted sources within the organization, tricking recipients into revealing sensitive information or downloading malicious payloads. By targeting internal users, attackers can bypass some external defenses and gain access to valuable information or systems.",
                subTechniques: [] 
            },
            { 
                id: 'T1570', 
                name: 'Lateral Tool Transfer', 
                description: "Adversaries may transfer tools laterally within a network to expand their foothold and facilitate further malicious activities. This can involve using legitimate file transfer mechanisms, such as SMB or RDP, to move tools between systems. By transferring tools, attackers can maintain their operations and adapt to different environments within the network.",
                subTechniques: [] 
            },
            { 
                id: 'T1563', 
                name: 'Remote Service Session Hijacking', 
                description: "Adversaries may hijack sessions of remote services, such as VPNs, RDP, or other remote access tools, to gain unauthorized access to a victim’s system or network. By stealing session tokens or credentials, attackers can take over existing sessions and impersonate legitimate users, bypassing authentication mechanisms and gaining access to restricted resources.",
                subTechniques: ['SSH Hijacking', 'RDP Hijacking'] 
            },
            { 
                id: 'T1021', 
                name: 'Remote Services', 
                description: "Adversaries may exploit remote services, such as VPNs, RDP, or other remote access tools, to gain unauthorized access to a victim’s network. By compromising these services, attackers can establish a foothold within the network, enabling them to conduct further malicious activities and maintain persistent access, often blending in with legitimate remote connections.",
                subTechniques: ['Remote Desktop Protocol', 'SMB/Windows Admin Shares', 'Distributed Component Object Model', 'SSH', 'VNC', 'Windows Remote Management', 'Cloud Services', 'Direct Cloud VM Connections'] 
            },
            { 
                id: 'T1091', 
                name: 'Replication Through Removable Media', 
                description: "Adversaries may use removable media, such as USB drives, to spread malicious software or gain access to a victim’s systems. By physically inserting compromised media into a device, attackers can execute malware, steal data, or establish a foothold within the network, often bypassing traditional security controls.",
                subTechniques: [] 
            },
            { 
                id: 'T1072', 
                name: 'Software Deployment Tools', 
                description: "Adversaries may abuse software deployment tools to distribute and execute malicious software across a network. These tools, designed for legitimate software updates and installations, can be exploited to deploy malware, execute scripts, or install backdoors, facilitating widespread compromise and persistence within the target environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1080', 
                name: 'Taint Shared Content', 
                description: "Adversaries may taint shared content, such as documents, software updates, or other files, to distribute malware or execute malicious code. This can involve embedding malicious payloads in files that are commonly shared within an organization or externally. By tainting shared content, attackers can spread their malware to multiple systems and users, increasing their chances of compromise.",
                subTechniques: [] 
            },
            { 
                id: 'T1550', 
                name: 'Use Alternate Authentication Material', 
                description: "Adversaries may use alternate authentication material, such as keys, tokens, or certificates, to access systems and maintain persistence. By obtaining or forging alternate authentication material, attackers can bypass traditional credential-based authentication mechanisms and gain unauthorized access to resources, ensuring continued access without relying on stolen passwords.",
                subTechniques: ['Application Access Token', 'Pass the Hash', 'Pass the Ticket', 'Web Session Cookie'] 
            }
        ]
    },
    {
        category: 'Collection',
        techniques: [
            { 
                id: 'T1557', 
                name: 'Adversary-in-the-Middle', 
                description: "Adversaries may position themselves between two or more networked devices to intercept and potentially alter communications. This can involve man-in-the-middle attacks, where attackers eavesdrop on, manipulate, or inject data into communications. By exploiting this position, adversaries can steal credentials, alter data in transit, and impersonate legitimate parties.",
                subTechniques: ['LLMNR/NBT-NS Poisoning and SMB Relay', 'ARP Cache Poisoning', 'DHCP Spoofing'] 
            },
            { 
                id: 'T1560', 
                name: 'Archive Collected Data', 
                description: "Adversaries may create archives to compress and package collected data before exfiltration. This can involve using tools like zip, tar, or custom scripts to bundle sensitive information, making it easier to transfer large amounts of data and potentially evading detection by reducing the number of files transferred.",
                subTechniques: ['Archive via Utility', 'Archive via Library', 'Archive via Custom Method'] 
            },
            { 
                id: 'T1123', 
                name: 'Audio Capture', 
                description: "Adversaries may capture audio recordings from a victim’s environment, using the built-in microphone or connected audio devices. This can involve leveraging legitimate audio recording software or creating custom tools to record and exfiltrate conversations, meetings, or other sensitive discussions.",
                subTechniques: [] 
            },
            { 
                id: 'T1119', 
                name: 'Automated Collection', 
                description: "Adversaries may set up automated processes to collect data from a victim’s system or network. This can involve using scripts or tools to regularly gather information, such as system logs, file contents, or screenshots, without requiring continuous manual effort from the attacker.",
                subTechniques: [] 
            },
            { 
                id: 'T1185', 
                name: 'Browser Session Hijacking', 
                description: "Adversaries may hijack active browser sessions to gain unauthorized access to web applications and services. This can involve stealing session cookies, exploiting browser vulnerabilities, or using man-in-the-browser techniques to intercept and manipulate browser traffic, allowing attackers to impersonate legitimate users and access sensitive information.",
                subTechniques: [] 
            },
            { 
                id: 'T1115', 
                name: 'Clipboard Data', 
                description: "Adversaries may capture clipboard data to obtain sensitive information copied by the user. This can involve monitoring clipboard contents using system APIs or malware designed to intercept and exfiltrate clipboard data, which may include passwords, personal information, or other valuable data.",
                subTechniques: [] 
            },
            { 
                id: 'T1530', 
                name: 'Data from Cloud Storage', 
                description: "Adversaries may target cloud storage services to collect data stored within a victim’s cloud environment. This can involve accessing cloud storage APIs, exploiting misconfigured permissions, or using stolen credentials to retrieve sensitive information, such as documents, backups, or database snapshots.",
                subTechniques: [] 
            },
            { 
                id: 'T1602', 
                name: 'Data from Configuration Repository', 
                description: "Adversaries may collect data from configuration repositories used to manage infrastructure and application settings. This can involve accessing configuration files, databases, or version control systems to gather sensitive information, such as API keys, credentials, or network configurations, which can be used to further compromise the environment.",
                subTechniques: ['SNMP (MIB Dump)', 'Network Device Configuration Dump'] 
            },
            { 
                id: 'T1213', 
                name: 'Data from Information Repositories', 
                description: "Adversaries may gather data from information repositories, such as document management systems, knowledge bases, or content management systems. This can involve querying databases, accessing file shares, or exploiting web interfaces to retrieve sensitive information stored within these repositories.",
                subTechniques: ['Confluence', 'Sharepoint', 'Code Repositories'] 
            },
            { 
                id: 'T1005', 
                name: 'Data from Local System', 
                description: "Adversaries may collect data from the local system to gather information about the victim’s activities, configurations, or stored files. This can involve accessing user directories, system logs, application data, or other locally stored information to identify valuable data for exfiltration.",
                subTechniques: [] 
            },
            { 
                id: 'T1039', 
                name: 'Data from Network Shared Drive', 
                description: "Adversaries may target network shared drives to collect data accessible to multiple users within an organization. This can involve accessing shared folders, mounted network drives, or other networked storage locations to retrieve documents, backups, or other shared data.",
                subTechniques: [] 
            },
            { 
                id: 'T1025', 
                name: 'Data from Removable Media', 
                description: "Adversaries may target removable media, such as USB drives or external hard drives, to collect data stored on these devices. This can involve physically accessing the media, exploiting autorun features, or using malware to automatically transfer data from the removable media to the attacker’s control.",
                subTechniques: [] 
            },
            { 
                id: 'T1074', 
                name: 'Data Staged', 
                description: "Adversaries may stage collected data in preparation for exfiltration. This involves aggregating, compressing, or encrypting data in a specific location, such as a staging server or temporary directory, making it easier to transfer the data in bulk and potentially evading detection by security tools.",
                subTechniques: ['Local Data Staging', 'Remote Data Staging'] 
            },
            { 
                id: 'T1114', 
                name: 'Email Collection', 
                description: "Adversaries may collect email communications from a victim’s email accounts to gather sensitive information, such as correspondence, attachments, and contact lists. This can involve accessing email servers, using phishing techniques to compromise accounts, or deploying malware to intercept and exfiltrate email data.",
                subTechniques: ['Local Email Collection', 'Remote Email Collection', 'Email Forwarding Rule'] 
            },
            { 
                id: 'T1056', 
                name: 'Input Capture', 
                description: "Adversaries may capture user input to obtain credentials or other sensitive information. This can involve keylogging, screen capturing, or intercepting input from hardware devices. By capturing input, attackers can steal credentials, monitor user activities, and collect information for further attacks.",
                subTechniques: ['Keylogging', 'GUI Input Capture', 'Web Portal Capture', 'Credential API Hooking'] 
            },
            { 
                id: 'T1113', 
                name: 'Screen Capture', 
                description: "Adversaries may capture screenshots of the victim’s system to gather information displayed on the screen. This can involve using legitimate screenshot tools, malware, or remote access software to periodically take snapshots, capturing sensitive information such as open documents, email contents, or credentials.",
                subTechniques: [] 
            },
            { 
                id: 'T1125', 
                name: 'Video Capture', 
                description: "Adversaries may capture video recordings of a victim’s environment using the built-in webcam or connected video devices. This can involve leveraging legitimate video recording software or creating custom tools to record and exfiltrate video feeds, potentially capturing sensitive activities or discussions.",
                subTechniques: [] 
            }
        ]
    },
    {
        category: 'Command and Control',
        techniques: [
            { 
                id: 'T1071', 
                name: 'Application Layer Protocol', 
                description: "Adversaries may use application layer protocols, such as HTTP, HTTPS, SMTP, or DNS, to communicate with compromised systems and control servers. These protocols can be used to blend malicious traffic with normal network traffic, making it harder for defenders to detect and block the communication.",
                subTechniques: ['Web Protocols', 'File Transfer Protocols', 'Mail Protocols', 'DNS'] 
            },
            { 
                id: 'T1092', 
                name: 'Communication Through Removable Media', 
                description: "Adversaries may use removable media, such as USB drives, to establish communication channels with compromised systems. This can involve physically transferring data or commands to and from the compromised system using the removable media, enabling communication even in isolated environments.",
                subTechniques: [] 
            },
            { 
                id: 'T1659', 
                name: 'Content Injection', 
                description: "Adversaries may inject malicious content into legitimate websites or applications to deliver harmful payloads to unsuspecting users. This technique can be used to exploit vulnerabilities, steal information, or further compromise the victim’s systems by tricking users into interacting with the injected content.",
                subTechniques: [] 
            },
            { 
                id: 'T1132', 
                name: 'Data Encoding', 
                description: "Adversaries may encode data to make it difficult for defenders to detect or analyze. This can involve using techniques such as base64 encoding, hexadecimal encoding, or custom encoding schemes to transform data into a format that is less likely to be flagged by security tools.",
                subTechniques: ['Standard Encoding', 'Non-Standard Encoding'] 
            },
            { 
                id: 'T1001', 
                name: 'Data Obfuscation', 
                description: "Adversaries may obfuscate data to hide its true content and evade detection. This can involve techniques such as encryption, compression, or altering the structure of the data to make it difficult for security tools to recognize and analyze.",
                subTechniques: ['Junk Data', 'Steganography', 'Protocol Impersonation'] 
            },
            { 
                id: 'T1568', 
                name: 'Dynamic Resolution', 
                description: "Adversaries may use dynamic resolution techniques to obscure the location of their command and control (C2) infrastructure. This can involve using domain generation algorithms (DGAs), fast flux DNS, or other methods to frequently change the IP addresses associated with their domains, making it harder for defenders to block or track the C2 infrastructure.",
                subTechniques: ['Fast Flux DNS', 'Domain Generation Algorithms', 'DNS Calculation'] 
            },
            { 
                id: 'T1573', 
                name: 'Encrypted Channel', 
                description: "Adversaries may use encrypted channels to protect the confidentiality and integrity of their communications. This can involve using SSL/TLS, VPNs, or other encryption protocols to encrypt data in transit, making it difficult for defenders to intercept and analyze the communication.",
                subTechniques: ['Symmetric Cryptography', 'Asymmetric Cryptography'] 
            },
            { 
                id: 'T1008', 
                name: 'Fallback Channels', 
                description: "Adversaries may establish fallback communication channels to maintain contact with compromised systems if the primary channel is disrupted. This can involve using multiple protocols, domains, or IP addresses as backup communication methods to ensure continued control over the compromised environment.",
                subTechniques: [] 
            },
            { 
                id: 'T1665', 
                name: 'Hide Infrastructure', 
                description: "Adversaries may take steps to hide their command and control (C2) infrastructure to evade detection and analysis. This can involve using techniques such as domain fronting, hosting C2 servers on legitimate cloud services, or using compromised systems as proxies to mask the true location of the C2 infrastructure.",
                subTechniques: [] 
            },
            { 
                id: 'T1105', 
                name: 'Ingress Tool Transfer', 
                description: "Adversaries may transfer tools into a victim’s environment to facilitate further malicious activities. This can involve using protocols such as FTP, HTTP, or SMB to upload malware, scripts, or other tools to compromised systems, enabling attackers to expand their foothold and execute additional payloads.",
                subTechniques: [] 
            },
            { 
                id: 'T1104', 
                name: 'Multi-Stage Channels', 
                description: "Adversaries may use multi-stage channels to communicate with compromised systems, employing multiple layers or stages to relay commands and data. This technique involves breaking down the communication process into several stages, often using different protocols, servers, or encryption methods at each stage. By doing so, attackers can obscure the full path of communication, making it more challenging for defenders to trace, analyze, and disrupt their command and control (C2) infrastructure.",
                subTechniques: [] 
            },
            { 
                id: 'T1095', 
                name: 'Non-Application Layer Protocol', 
                description: "Adversaries may use non-application layer protocols, such as ICMP, TCP, or UDP, to communicate with compromised systems and control servers. These protocols can be used to create covert channels that blend with normal network traffic, making it harder for defenders to detect and block the communication.",
                subTechniques: [] 
            },
            { 
                id: 'T1571', 
                name: 'Non-Standard Port', 
                description: "Adversaries may use non-standard ports to communicate with compromised systems and control servers. This can involve using uncommon port numbers for protocols such as HTTP, HTTPS, or SSH, making it more difficult for defenders to identify and block the malicious traffic based on port numbers alone.",
                subTechniques: [] 
            },
            { 
                id: 'T1572', 
                name: 'Protocol Tunneling', 
                description: "Adversaries may use protocol tunneling to encapsulate malicious traffic within legitimate network protocols. This can involve using techniques such as SSH tunneling, DNS tunneling, or HTTP tunneling to hide malicious communication within normal traffic, making it harder for defenders to detect and analyze the malicious activity.",
                subTechniques: [] 
            },
            { 
                id: 'T1090', 
                name: 'Proxy', 
                description: "Adversaries may use proxy servers to route their communication through intermediate systems, masking the true origin and destination of the traffic. This can involve using compromised systems, commercial VPN services, or other proxy infrastructure to hide their activities and evade detection.",
                subTechniques: ['Internal Proxy', 'External Proxy', 'Multi-hop Proxy', 'Domain Fronting'] 
            },
            { 
                id: 'T1219', 
                name: 'Remote Access Software', 
                description: "Adversaries may use remote access software to maintain control over compromised systems. This can involve leveraging legitimate remote desktop tools, custom malware, or commercial remote access solutions to remotely manage and execute commands on the victim’s systems.",
                subTechniques: [] 
            },
            { 
                id: 'T1205', 
                name: 'Traffic Signaling', 
                description: "Adversaries may use traffic signaling to maintain persistence and communicate with compromised systems. This technique involves embedding signals within normal network traffic to instruct malware to perform specific actions. By using traffic signaling, attackers can covertly manage their malware and ensure continued operation without raising suspicion.",
                subTechniques: ['Port Knocking', 'Socket Filters'] 
            },
            { 
                id: 'T1102', 
                name: 'Web Service', 
                description: "Adversaries may use web services, such as cloud storage, social media platforms, or legitimate web applications, to communicate with compromised systems. This can involve using the APIs or interfaces of these services to send and receive data, blending malicious traffic with legitimate web traffic and making it harder for defenders to detect.",
                subTechniques: ['Dead Drop Resolver', 'Bidirectional Communication', 'One-Way Communication'] 
            }
        ]
    },
    {
        category: 'Exfiltration',
        techniques: [
            { 
                id: 'T1020', 
                name: 'Automated Exfiltration', 
                description: "Adversaries may set up automated processes to exfiltrate data from a victim’s system or network. This can involve using scripts or tools to regularly transfer collected data to remote servers, reducing the need for manual intervention and enabling continuous data exfiltration without raising immediate suspicion.",
                subTechniques: ['Traffic Duplication'] 
            },
            { 
                id: 'T1030', 
                name: 'Data Transfer Size Limits', 
                description: "Adversaries may limit the size of data transfers to avoid detection. This can involve breaking down large files into smaller chunks or throttling the data transfer rate to blend in with normal network traffic. By keeping data transfers within certain size limits, attackers can evade network monitoring tools that flag unusually large data movements.",
                subTechniques: [] 
            },
            { 
                id: 'T1048', 
                name: 'Exfiltration Over Alternative Protocol', 
                description: "Adversaries may use alternative protocols to exfiltrate data, bypassing security controls that monitor standard protocols. This can involve using protocols like FTP, SCP, or ICMP to transfer data, making it more difficult for defenders to detect and block the exfiltration.",
                subTechniques: ['Exfiltration Over Symmetric Encrypted Non-C2 Protocol', 'Exfiltration Over Asymmetric Encrypted Non-C2 Protocol', 'Exfiltration Over Unencrypted Non-C2 Protocol'] 
            },
            { 
                id: 'T1041', 
                name: 'Exfiltration Over C2 Channel', 
                description: "Adversaries may exfiltrate data over existing command and control (C2) channels. This can involve embedding exfiltrated data within the regular C2 traffic, making it harder for defenders to distinguish between normal C2 communication and data exfiltration activities.",
                subTechniques: [] 
            },
            { 
                id: 'T1011', 
                name: 'Exfiltration Over Other Network Medium', 
                description: "Adversaries may use non-standard network mediums to exfiltrate data, such as Bluetooth, NFC, or radio frequency (RF) communications. By using alternative network mediums, attackers can bypass traditional network monitoring tools and transfer data from air-gapped or isolated systems.",
                subTechniques: ['Exfiltration Over Bluetooth'] 
            },
            { 
                id: 'T1052', 
                name: 'Exfiltration Over Physical Medium', 
                description: "Adversaries may use physical mediums, such as USB drives or external hard drives, to exfiltrate data. This can involve physically transporting the data out of the victim’s environment, bypassing network security measures and reducing the risk of detection during the transfer process.",
                subTechniques: ['Exfiltration over USB'] 
            },
            { 
                id: 'T1567', 
                name: 'Exfiltration Over Web Service', 
                description: "Adversaries may use web services, such as cloud storage, social media platforms, or legitimate web applications, to exfiltrate data. This can involve uploading data to these services using their APIs or interfaces, blending the exfiltration traffic with legitimate web traffic and making it harder for defenders to detect.",
                subTechniques: ['Exfiltration to Code Repository', 'Exfiltration to Cloud Storage', 'Exfiltration to Text Storage Sites', 'Exfiltration Over Webhook'] 
            },
            { 
                id: 'T1029', 
                name: 'Scheduled Transfer', 
                description: "Adversaries may schedule data exfiltration at specific times to avoid detection. This can involve setting up automated transfers during off-peak hours or coordinating exfiltration with legitimate scheduled tasks. By timing the exfiltration carefully, attackers can reduce the likelihood of their activities being noticed by defenders.",
                subTechniques: [] 
            },
            { 
                id: 'T1537', 
                name: 'Transfer Data to Cloud Account', 
                description: "Adversaries may transfer exfiltrated data to a cloud account under their control. This can involve using cloud storage services, file-sharing platforms, or cloud-based applications to store and manage the stolen data. By transferring data to the cloud, attackers can leverage the scalability and accessibility of cloud infrastructure while evading traditional on-premises security controls.",
                subTechniques: [] 
            }
        ]
    },
    {
        category: 'Impact',
        techniques: [
            { 
                id: 'T1531', 
                name: 'Account Access Removal', 
                description: "Adversaries may remove access to accounts to disrupt operations and limit the victim’s ability to respond to an attack. This can involve deleting accounts, changing passwords, or altering permissions to lock out legitimate users and administrators from critical systems and services.",
                subTechniques: [] 
            },
            { 
                id: 'T1485', 
                name: 'Data Destruction', 
                description: "Adversaries may destroy data on a victim’s systems to cause damage and disrupt operations. This can involve deleting files, overwriting data, or corrupting storage devices, rendering the data irretrievable and causing significant operational impact.",
                subTechniques: [] 
            },
            { 
                id: 'T1486', 
                name: 'Data Encrypted for Impact', 
                description: "Adversaries may encrypt data on a victim’s systems to disrupt operations and demand a ransom for decryption. This involves using encryption algorithms to lock files and systems, making the data inaccessible until a ransom is paid. This tactic is commonly associated with ransomware attacks.",
                subTechniques: [] 
            },
            { 
                id: 'T1565', 
                name: 'Data Manipulation', 
                description: "Adversaries may manipulate data to cause operational disruptions, financial loss, or reputational damage. This can involve altering records, modifying configurations, or corrupting databases, leading to incorrect or misleading information being used by the victim.",
                subTechniques: ['Stored Data Manipulation', 'Transmitted Data Manipulation', 'Runtime Data Manipulation'] 
            },
            { 
                id: 'T1491', 
                name: 'Defacement', 
                description: "Adversaries may deface websites or other digital properties to cause reputational damage and disrupt operations. This involves altering the appearance or content of a website, often replacing legitimate content with messages, images, or videos created by the attacker.",
                subTechniques: ['Internal Defacement', 'External Defacement'] 
            },
            { 
                id: 'T1561', 
                name: 'Disk Wipe', 
                description: "Adversaries may wipe disks to destroy data and render systems inoperable. This can involve using disk wiping tools or commands to overwrite data on storage devices, making it irrecoverable and causing significant disruption to the victim’s operations.",
                subTechniques: ['Disk Content Wipe', 'Disk Structure Wipe'] 
            },
            { 
                id: 'T1499', 
                name: 'Endpoint Denial of Service', 
                description: "Adversaries may cause a denial of service (DoS) on endpoints to disrupt availability and functionality. This can involve overwhelming systems with traffic, exploiting vulnerabilities, or using malware to disable or degrade system performance, preventing legitimate users from accessing resources.",
                subTechniques: ['OS Exhaustion Flood', 'Service Exhaustion Flood', 'Application Exhaustion Flood', 'Application or System Exploitation'] 
            },
            { 
                id: 'T1657', 
                name: 'Financial Theft', 
                description: "Adversaries may steal funds from financial accounts to cause financial loss and gain illicit profits. This can involve compromising online banking systems, payment processors, or other financial services to transfer money, make unauthorized purchases, or alter financial records.",
                subTechniques: [] 
            },
            { 
                id: 'T1495', 
                name: 'Firmware Corruption', 
                description: "Adversaries may corrupt firmware to render hardware devices inoperable and disrupt operations. This involves tampering with the firmware code that controls hardware components, causing devices to malfunction or fail to boot, leading to significant operational impact.",
                subTechniques: [] 
            },
            { 
                id: 'T1490', 
                name: 'Inhibit System Recovery', 
                description: "Adversaries may inhibit system recovery to prevent victims from restoring systems and data after an attack. This can involve disabling backups, corrupting recovery tools, or deleting restore points, making it difficult or impossible for the victim to recover from the attack.",
                subTechniques: [] 
            },
            { 
                id: 'T1498', 
                name: 'Network Denial of Service', 
                description: "Adversaries may cause a denial of service (DoS) on network resources to disrupt availability and functionality. This can involve overwhelming network infrastructure with traffic, exploiting vulnerabilities, or using malware to disable or degrade network performance, preventing legitimate users from accessing resources.",
                subTechniques: ['Direct Network Flood', 'Reflection Amplification'] 
            },
            { 
                id: 'T1496', 
                name: 'Resource Hijacking', 
                description: "Adversaries may hijack system resources, such as CPU, GPU, or memory, for their own purposes, such as cryptocurrency mining or distributed computing. This can degrade system performance, increase operational costs, and cause hardware wear and tear, leading to financial and operational impacts.",
                subTechniques: [] 
            },
            { 
                id: 'T1489', 
                name: 'Service Stop', 
                description: "Adversaries may stop critical services to disrupt operations and cause damage. This can involve using administrative tools, scripts, or malware to terminate services, preventing legitimate users from accessing functionality and resources provided by the affected services.",
                subTechniques: [] 
            },
            { 
                id: 'T1529', 
                name: 'System Shutdown/Reboot', 
                description: "Adversaries may shut down or reboot systems to disrupt operations and cause damage. This can involve using administrative tools, scripts, or malware to initiate system shutdowns or reboots, preventing legitimate users from accessing resources and causing potential data loss or corruption.",
                subTechniques: [] 
            }
        ]
    }
];

export default attackMatrix;
