import React, { useState, useEffect, useCallback } from 'react';
import sentinelImage from '../static/Azure_Sentinel.png';
import './Integrations.scss';
import axios from 'axios';
import { FaSearch, FaCog, FaBook, FaCheck, FaTimes, FaEye, FaEyeSlash, FaEdit, FaTrash } from 'react-icons/fa';

const IntegrationCard = ({ image, title, isActive, isConfigured, onClick, onEdit, onRemove }) => (
  <div className={`integration-card ${isActive ? 'active' : ''} ${isConfigured ? 'configured' : ''}`} onClick={onClick}>
    <img src={image} alt={title} />
    <h3>{title}</h3>
    {isConfigured && (
      <div className="integration-actions">
        <FaCheck className="check-icon" />
        <FaEdit className="edit-icon" onClick={(e) => { e.stopPropagation(); onEdit(); }} />
        <FaTrash className="remove-icon" onClick={(e) => { e.stopPropagation(); onRemove(); }} />
      </div>
    )}
  </div>
);

const Integrations = () => {
    const [activeIntegration, setActiveIntegration] = useState(null);
    const [showDocumentation, setShowDocumentation] = useState(false);
    const [integrationSettings, setIntegrationSettings] = useState({
        workspaceId: '',
        clientId: '',
        clientSecret: '',
        tenantId: '',
        subscriptionId: '',
        resourceGroup: '',
        workspaceName: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isConfigured, setIsConfigured] = useState(false);
    const [showSecrets, setShowSecrets] = useState({
        clientSecret: false
    });
    const [isEditing, setIsEditing] = useState(false);

    const checkIntegrationStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/api/integration_status', {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data.isConfigured;
        } catch (error) {
            console.error('Error checking integration status:', error);
            return false;
        }
    };

    useEffect(() => {
        const checkStatus = async () => {
            const status = await checkIntegrationStatus();
            setIsConfigured(status);
            if (status) {
                const savedSettings = JSON.parse(localStorage.getItem('integrationSettings') || '{}');
                setIntegrationSettings(prevSettings => ({
                    ...prevSettings,
                    ...savedSettings
                }));
            }
        };
        checkStatus();
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setIntegrationSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    }, []);

    const toggleShowSecret = useCallback((field) => {
        setShowSecrets(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    }, []);

    const handleSave = useCallback(async () => {
        setError('');
        setSuccess('');
    
        if (Object.values(integrationSettings).some(val => !val)) {
            setError('All fields are required.');
            return;
        }
    
        try {
            const isConfigured = await checkIntegrationStatus();
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
            const endpoint = isConfigured ? '/api/update_integration' : '/api/configure_integration';
            
            const response = await axios.post(
                `${apiUrl}${endpoint}`, 
                integrationSettings, 
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.status === 200) {
                localStorage.setItem('integrationSettings', JSON.stringify(integrationSettings));
                setSuccess(`Integration with Microsoft Sentinel ${isConfigured ? 'updated' : 'configured'} successfully.`);
                setIsConfigured(true);
                setIsEditing(false);
            } else {
                setError(`Failed to ${isConfigured ? 'update' : 'configure'} integration with Microsoft Sentinel.`);
            }
        } catch (error) {
            console.error('Integration error:', error);
            setError(`Failed to ${isEditing ? 'update' : 'configure'} integration with Microsoft Sentinel: ${error.response?.data?.message || error.message}`);
        }
    }, [integrationSettings, isEditing]);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleRemove = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
            
            const response = await axios.delete(
                `${apiUrl}/api/remove_integration`,
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.status === 200) {
                localStorage.removeItem('integrationSettings');
                setIntegrationSettings({
                    workspaceId: '',
                    clientId: '',
                    clientSecret: '',
                    tenantId: '',
                    subscriptionId: '',
                    resourceGroup: '',
                    workspaceName: ''
                });
                setIsConfigured(false);
                setSuccess('Integration removed successfully.');
            } else {
                setError('Failed to remove integration.');
            }
        } catch (error) {
            console.error('Remove integration error:', error);
            setError(`Failed to remove integration: ${error.response?.data?.message || error.message}`);
        }
    }, []);

    const renderInput = useCallback((key, value) => {
        const isSecret = key.toLowerCase().includes('secret');
        return (
            <div className="form-group" key={key}>
                <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                <div className="input-group">
                    <input
                        type={isSecret && !showSecrets[key] ? 'password' : 'text'}
                        id={key}
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        disabled={isConfigured && !isEditing}
                    />
                    {isSecret && (
                        <button 
                            type="button" 
                            className="btn btn-outline-secondary" 
                            onClick={() => toggleShowSecret(key)}
                        >
                            {showSecrets[key] ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    )}
                </div>
            </div>
        );
    }, [handleInputChange, showSecrets, toggleShowSecret, isConfigured, isEditing]);

    return (
        <div className="integrations-page">
            <div className="integrations-sidebar">
                <h2>Integrations</h2>
                <IntegrationCard
                    image={sentinelImage}
                    title="Microsoft Sentinel"
                    isActive={activeIntegration === 'sentinel'}
                    isConfigured={isConfigured}
                    onClick={() => setActiveIntegration('sentinel')}
                    onEdit={handleEdit}
                    onRemove={handleRemove}
                />
                {/* Add more integration cards here */}
            </div>
            <div className="integrations-content">
                {activeIntegration === 'sentinel' && (
                    <>
                        <h2>Microsoft Sentinel Integration</h2>
                        <div className="integration-tabs">
                            <button 
                                className={!showDocumentation ? 'active' : ''} 
                                onClick={() => setShowDocumentation(false)}
                            >
                                <FaCog /> Settings
                            </button>
                            <button 
                                className={showDocumentation ? 'active' : ''} 
                                onClick={() => setShowDocumentation(true)}
                            >
                                <FaBook /> Documentation
                            </button>
                        </div>
                        {!showDocumentation ? (
                            <div className="integration-settings">
                                {error && <p className="error">{error}</p>}
                                {success && <p className="success">{success}</p>}
                                {isConfigured && !isEditing ? (
                                    <div className="configured-message">
                                        <p>This integration is configured. You can edit or remove it using the buttons below.</p>
                                        <button onClick={handleEdit} className="btn btn-primary">Edit Integration</button>
                                        <button onClick={handleRemove} className="btn btn-danger">Remove Integration</button>
                                    </div>
                                ) : (
                                    <>
                                        {Object.entries(integrationSettings).map(([key, value]) => renderInput(key, value))}
                                        <button onClick={handleSave} className="btn btn-primary">
                                            {isEditing ? 'Update Integration' : 'Save Integration'}
                                        </button>
                                        {isEditing && (
                                            <button onClick={() => setIsEditing(false)} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="documentation">
                                {/* Documentation content */}
                            </div>
                        )}
                    </>
                )}
                {!activeIntegration && (
                    <div className="no-integration-selected">
                        <h3>Select an integration from the sidebar to get started</h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Integrations;
